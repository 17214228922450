<template>
  <v-menu
    offset-x
    right
    :closeOnContentClick="false"
    min-width="360"
    contentClass="v-menu__content--input"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <div
          v-bind="attrs"
          v-on="on"
        >
          <div
            v-if="!isMini"
            class="px-6 pt-4 pb-2"
          >
            <AppDrawerMenu
              v-bind="attrs"
              v-on="on"
              :title="title"
              :subTitle="activeProjectName"
              :disableSubTitleTooltip="activeProjectNameTooltipDisabled"
            />
          </div>

          <div
            v-else
            class="pa-3 d-flex align-center justify-center"
          >
            <AppRectangleIcon
              :icon="$icons.SOLID.ROUTE.PROJECT"
              :tooltipText="title"
            />
          </div>
        </div>
        <AppMiniContentSelectionDivider />
        <AppNavigationList>
          <AppNavigationItem
            @click="onGoBack"
            :prependIcon="$icons.SOLID.ACTION.GO_PREV_LEVEL"
            :title="$t('drawer.route.goBack')"
            exact
          />
        </AppNavigationList>
        <v-divider class="primary-border" />
      </div>
    </template>
    <div style="height: 41px">
      <AppProjectAutocomplete
        ref="projectAutocomplete"
        v-model="localProjectId"
        hide-details
        :clearable="false"
        isReadyToInitialFetch
        autofocus
        activateOnFocus
        returnObject
        :queryParams="{
          alwaysIncludeIds: [activeProjectId],
        }"
        @change="activateMenu"
        @created="changeProject"
      />
    </div>
  </v-menu>
</template>

<script>
import { routerMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";

export default {
  mixins: [routerMixins],
  data() {
    return {
      tooltipActiveProjectNameLength: 25,
      tooltipProjectNameLength: 40,
      tooltipClientNameLength: 40,
    };
  },
  props: {
    activeProject: Object,
  },
  computed: {
    localProjectId: {
      get() {
        return this.activeProjectId;
      },
      set(project) {
        if (!project) return;
        this.changeProject({ project });
      },
    },
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    joinedProjects() {
      const active = this.activeProject ? [this.activeProject] : [];
      const formattedActive = formatItemWithSearchStr(active, "PROJECT");
      const mergedProjects = [...formattedActive, ...this.projects];
      const uniqueProjects = Array.from(
        new Set(mergedProjects.map((project) => project.id)),
      ).map((id) => mergedProjects.find((project) => project.id === id));

      return uniqueProjects;
    },
    activeProjectId() {
      return this.activeProject?.id;
    },
    activeProjectName() {
      return this.activeProject?.name || `${this.$t("common.loading")}...`;
    },
    filteredProjects() {
      const filter = this.filter?.toLowerCase() || "";
      const filteredProjects = this.projects.filter((project) => {
        const nameMatch = project.name.toLowerCase().includes(filter);
        return nameMatch;
      });
      return filteredProjects;
    },
    activeProjectNameTooltipDisabled() {
      if (!this.activeProject?.name) return true;
      return this.activeProject?.name?.length <= this.tooltipActiveProjectNameLength;
    },
    title() {
      return this.$t("drawer.header.projectView.title");
    },
  },
  methods: {
    activateMenu() {
      this.$nextTick(() => {
        this.$refs.projectAutocomplete.activateMenu();
      });
    },
    changeProject({ project }) {
      this.$emit("project:change", { project });
    },
    onGoBack() {
      this.$router.replace({ name: this.$routeNames.PROJECT.ROOT });
      // this.routerMixins_goBack({
      //   fallbackRoute: { name: this.$routeNames.PROJECT.ROOT },
      // });
    },
  },
};
</script>

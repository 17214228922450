<template>
  <div @click="$emit('click', $event)">
    <v-tooltip
      top
      content-class="pa-0"
      color="transparent"
      :disabled="!hasThumbnail"
      :top="top"
      :left="left"
      :right="right"
      :bottom="bottom"
      :open-delay="150"
    >
      <template #activator="{ on, attrs }">
        <div
          v-on="on"
          v-bind="attrs"
          class="d-flex align-center justify-center rounded background"
          :style="iconStyle"
          @mouseenter="handleMouseEnter"
        >
          <AppCircularLoader
            v-if="loading"
            :size="loaderSize"
          />
          <slot
            v-else-if="$scopedSlots.content"
            name="content"
          ></slot>
          <StorageIcon
            v-else-if="!hasThumbnail"
            :storage="storageRepresentation"
            :large="large"
            :small="small"
            :disabled="disabled"
            :loading="loading"
          />
          <v-img
            v-else-if="hasThumbnail"
            :src="isStorage ? storageThumbnailUrl : thumbnailSrc"
            :max-height="iconSize"
            :max-width="iconSize"
            contain
          />
          <v-icon v-else>{{ $icons.LIGHT.COMMON.QUESTION }}</v-icon>
        </div>
      </template>
      <div>
        <img
          v-if="fullImageLoaded"
          :src="imageSrc"
          class="border-a rounded-lg"
          :style="{
            maxWidth: '400px',
            maxHeight: '400px',
          }"
        />
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StorageIcon from "./StorageIcon.vue";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  components: {
    StorageIcon,
  },
  props: {
    item: {
      //Can be a File or a Storage Item
      type: [File, Object],
      default: null,
    },
    disabled: Boolean,
    loading: Boolean,
    large: Boolean,
    small: Boolean,
    left: Boolean,
    top: Boolean,
    right: Boolean,
    bottom: Boolean,
  },
  data() {
    return {
      authToken: null,
      thumbnailSrc: null,
      fullImageLoaded: false,
      isLoadingFullImage: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    isFile() {
      return this.item instanceof File;
    },
    isStorage() {
      return !this.isFile;
    },
    storageRepresentation() {
      return this.isFile ? { mime: this.item.type } : this.item;
    },
    isImage() {
      const mimeType = this.isFile ? this.item.type : this.item?.mime;
      return mimeType?.startsWith("image/") || false;
    },
    isVideo() {
      const mimeType = this.isFile ? this.item.type : this.item?.mime;
      return mimeType?.startsWith("video/") || false;
    },
    hasThumbnail() {
      return this.isImage || (this.isImage && this.isStorage);
    },
    imageSrc() {
      if (this.isFile) {
        return this.isImage ? URL.createObjectURL(this.item) : null;
      } else if (this.isStorage) {
        return this.storageThumbnailUrl;
      }
      return null;
    },
    iconSize() {
      if (this.small) return 30;
      if (this.large) return 60;
      return 40;
    },
    loaderSize() {
      if (this.small) return 18;
      if (this.large) return 32;
      return 24;
    },
    iconStyle() {
      return {
        width: `${this.iconSize}px`,
        height: `${this.iconSize}px`,
        minWidth: `${this.iconSize}px`,
      };
    },
    thumbnailSize() {
      return this.iconSize * 2;
    },
    storageThumbnailUrl() {
      if (!this.authToken || !this.activeDomainId || !this.isStorage) return "";
      return `${API_URL}storage/file/${this.item.id}/thumbnail?authToken=${this.authToken}&domain=${this.activeDomainId}`;
    },
  },
  methods: {
    async getTokenSilently() {
      if (!this.isStorage) return;
      try {
        const token = await this.$auth.getTokenSilently();
        this.authToken = token;
      } catch (error) {
        console.error("Error getting token:", error);
        this.errorMessage = "Error loading video: Unable to authenticate";
      }
    },
    async loadThumbnail() {
      if (this.isFile && this.isImage) {
        this.thumbnailSrc = await this.createThumbnail(this.item);
      } else if (this.isStorage) {
        this.getTokenSilently();
      }
    },
    async createThumbnail(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = this.thumbnailSize;
            canvas.height = this.thumbnailSize;

            // Calculate aspect ratio
            const aspectRatio = img.width / img.height;
            let drawWidth, drawHeight, offsetX, offsetY;

            if (aspectRatio > 1) {
              // Landscape image
              drawWidth = this.thumbnailSize;
              drawHeight = this.thumbnailSize / aspectRatio;
              offsetX = 0;
              offsetY = (this.thumbnailSize - drawHeight) / 2;
            } else {
              // Portrait or square image
              drawHeight = this.thumbnailSize;
              drawWidth = this.thumbnailSize * aspectRatio;
              offsetX = (this.thumbnailSize - drawWidth) / 2;
              offsetY = 0;
            }

            ctx.fillStyle = "#F4F4F2";
            ctx.fillRect(0, 0, this.thumbnailSize, this.thumbnailSize);

            // Draw the image maintaining aspect ratio
            ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
            resolve(canvas.toDataURL(file.type));
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    handleMouseEnter() {
      if (!this.fullImageLoaded && !this.isLoadingFullImage && this.hasThumbnail) {
        this.loadFullImage();
      }
    },
    loadFullImage() {
      if (this.fullImageLoaded || this.isLoadingFullImage) return;

      this.isLoadingFullImage = true;

      const img = new Image();
      img.onload = () => {
        this.fullImageLoaded = true;
        this.isLoadingFullImage = false;
      };
      img.onerror = () => {
        console.error("Failed to load full image");
        this.isLoadingFullImage = false;
      };
      img.src = this.imageSrc;
    },
  },
  mounted() {
    this.loadThumbnail();
  },
  beforeDestroy() {
    if (this.isFile && this.imageSrc) {
      URL.revokeObjectURL(this.imageSrc);
    }
  },
};
</script>

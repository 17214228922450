<template>
  <div v-if="task">
    <v-form
      v-model="name.isValid"
      @submit.prevent
    >
      <div class="label--small required-asterisk">
        {{ $t("taskManagement.list.taskName") | capitalize }}
      </div>
      <AppDefaultTextarea
        :disabled="!canEdit"
        :value="task.name"
        :rules="$rules.NAME_RULES"
        :label="$t('taskManagement.list.taskName')"
        rows="1"
        :counter="$rules.MAX_CHARACTERS"
        @blur="updateTaskField($event, 'name')"
      />
    </v-form>

    <v-form
      v-model="description.isValid"
      @submit.prevent
    >
      <div class="label--small">
        {{ $t("taskManagement.list.taskDescription") | capitalize }}
      </div>
      <AppDefaultTextarea
        :disabled="!canEdit"
        :value="task.description"
        @blur="updateTaskField($event, 'description')"
        class="item_menu__description"
      />
    </v-form>

    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("taskManagement.list.responsible") | capitalize }}
      </div>
      <AppClientGroupedUserAndContactAutocomplete
        :disabled="!canEdit"
        hide-details
        :value="task.member ? task.member.id : null"
        :items="assignableUsers"
        :loading="isAssignableUsersLoading"
        @change="onTaskMemberChange"
        :noDataText="$t('common.noUsers')"
        :labelPaths="['workTitleLabel', 'contact.relation.label']"
        user-avatar
      />
    </div>

    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("taskManagement.list.participants") | capitalize }}
      </div>
      <AppClientGroupedUserAndContactAutocomplete
        select-all
        user-avatar
        hide-details
        multiple
        clearable
        :disabled="!canEdit"
        :value="collaberatorIds"
        :items="assignableUsers"
        :loading="isAssignableUsersLoading"
        :noDataText="$t('common.noUsers')"
        @change="onTaskCollaboratorsChange"
      />
    </div>
    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("taskManagement.list.startDate") | capitalize }}
      </div>
      <AppFieldMenuDatePicker
        hide-details
        :disabled="!canEdit"
        :date="task.startDate"
        :max="task.dueDate"
        @date:change="onTaskStartDateChange"
      />
    </div>
    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("taskManagement.list.dueDate") | capitalize }}
      </div>
      <AppFieldMenuDatePickerRepeatable
        model="TASK"
        hide-details
        :disabled="!canEdit"
        :date="task.dueDate"
        :min="task.startDate"
        :defaultPickerDate="task.startDate"
        :repeatOption="task.repeatOption"
        :notRepeatable="!!task.formItemId"
        :isUpdating="isUpdating"
        @repeatOption:change="onTaskRepeatOptionsChange"
        @date:change="onTaskDueDateChange"
      />
    </div>
    <AppProjectAutocompleteWithLabel
      v-model="localProjectId"
      ref="projectAutocomplete"
      hide-details
      :disabled="!canEdit"
      :isReadyToInitialFetch="!!task"
      :queryParams="{
        userIds: [task.member?.id || currentUserId],
        alwaysIncludeIds: [task.projectId],
      }"
      type="project"
      @created="({ project }) => (localProjectId = project.id)"
    />
    <div class="d-flex align-end">
      <div class="label--small">
        {{ $t("common.status") | capitalize }}
      </div>
      <v-spacer />
      <AppSettingsBtn
        :tooltip="statusMixins_tooltip('task')"
        @click.stop="onEditStatusesClick"
      />
    </div>
    <AppChangeCustomStatusAutocomplete
      class="pb-5"
      model="task"
      hide-details
      :disabled="!canEdit"
      :value="task.statusId"
      @change="onTaskStatusChange"
    />
    <div
      class="pb-5 d-flex flex-column p-relative"
      v-if="formItem"
    >
      <div class="label--small">
        {{ $t("common.formItem") | capitalize }}
      </div>
      <router-link
        class="primary-link--text text-decoration-none text-truncate"
        :to="{
          name: $routeNames.FORM_VIEW.ROOT,
          params: { formId: formItem.formId },
        }"
      >
        {{ formItem.name }}
      </router-link>
    </div>

    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("common.creationDate") | capitalize }}
      </div>
      <div>{{ new Date(task.createdAt) | dateFormat("DD.MM.YYYY HH:mm") }}</div>
    </div>

    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("common.lastUpdated") | capitalize }}
      </div>
      <div>{{ new Date(task.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}</div>
    </div>

    <div class="pb-5 p-relative">
      <div class="label--small">
        {{ $t("common.id") | capitalize }}
      </div>
      <div>{{ task.number }}</div>
    </div>
    <AppEditCustomStatusDialog
      v-model="editStatusDialog"
      model="task"
    />
  </div>
</template>

<script>
import * as moment from "moment";
import axios from "axios";
import { getSearchUsers } from "@/services/search/users";
import { statusMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
const apiUrl = process.env.VUE_APP_API_URL;
export default {
  mixins: [statusMixins],
  props: {
    task: Object,
    isUpdating: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      dueDateMenu: false,
      editStatusDialog: false,
      name: {
        isValid: false,
      },
      description: {
        isValid: false,
      },
      formItem: null,
    };
  },
  watch: {
    task: {
      handler(newTask, oldTask) {
        this.handleFormItem(newTask);
        this.handleInitialFetch(newTask, oldTask);
        this.handleProjectChange(newTask, oldTask);
        this.handleMemberChange(newTask, oldTask);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    canEdit() {
      return this.task?.permissions?.canEdit;
    },
    collaberatorIds() {
      return this.task?.collaborators?.map((collaborator) => collaborator?.id) || [];
    },
    localProjectId: {
      get() {
        return this.task?.projectId;
      },
      set(projectId) {
        this.updateTask({ projectId });
      },
    },
  },
  methods: {
    onProjectCreated() {
      this.$refs.projectAutocomplete.getProjects();
    },
    onEditStatusesClick() {
      this.editStatusDialog = true;
    },
    handleFormItem(newTask) {
      if (newTask?.formItemId) {
        this.getFormItem(newTask.formItemId);
      } else {
        this.formItem = null;
      }
    },
    handleInitialFetch(newTask, oldTask) {
      if (!oldTask && newTask) {
        this.getAssignableUsers(newTask.projectId);
      }
    },
    handleProjectChange(newTask, oldTask) {
      if (oldTask?.projectId !== newTask?.projectId && oldTask) {
        this.getAssignableUsers(newTask.projectId);
      }
    },
    handleMemberChange(newTask, oldTask) {
      if (oldTask?.member?.id !== newTask?.member?.id && oldTask) {
        this.$refs.projectAutocomplete.getProjects();
      }
    },
    getAssignableUsers(projectId) {
      this.isAssignableUsersLoading = true;
      const params = {
        projectId,
        onlyWithProjectAccess: !!projectId,
        alwaysIncludeIds: [...this.collaberatorIds, this.task?.member?.id],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableUsers = users;
        })
        .finally(() => (this.isAssignableUsersLoading = false));
    },
    updateTaskField(e, field) {
      if (this[field].isValid) {
        const value = e.target.value;
        this.updateTask({ [field]: value });
      }
    },
    getFormItem(formItemId) {
      axios.get(`${apiUrl}form-item/${formItemId}`).then(({ data }) => {
        this.formItem = data.item;
      });
    },
    onTaskMemberChange(memberId) {
      this.updateTask({ memberId });
    },
    onTaskCollaboratorsChange(collaborators) {
      this.updateTask({ memberIds: collaborators });
    },
    onTaskStartDateChange(startDate) {
      this.updateTask({ startDate });
    },
    onTaskDueDateChange(dueDate) {
      this.updateTask({ dueDate });
    },
    onTaskStatusChange(statusId) {
      this.updateTask({ statusId });
    },
    onTaskRepeatOptionsChange({ repeatOption }) {
      this.updateTask({ repeatOption });
    },
    updateTask(data) {
      this.$emit("task:update", { data, task: this.task });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
  },
};
</script>

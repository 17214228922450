<template>
  <v-progress-circular
    indeterminate
    :color="color"
    v-bind="{ ...dimensions }"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary",
    },
    small: Boolean,
    page: Boolean,
    large: Boolean,
  },
  computed: {
    dimensions() {
      //default values {size: 32, width: 4}
      if (this.small) {
        return {
          size: 24,
          width: 3,
        };
      }
      if (this.large) {
        return {
          size: 48,
        };
      }
      if (this.page) {
        return {
          size: 64,
          width: 5,
        };
      }
    },
  },
};
</script>

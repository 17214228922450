<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="image_delete_btn"
    x-small
    icon
  >
    <v-icon
      color="white"
      x-small
    >
      {{ $icons.LIGHT.ACTION.CLOSE }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {};
</script>

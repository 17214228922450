import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function updateProfile(payload) {
  return axios
    .put(`${apiUrl}profile`, payload)
    .then(({ data }) => {
      const profile = data ? data.profile : null;
      return profile;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function createDomain(payload) {
  return axios.post(`${apiUrl}auth/create-domain`, payload).then((res) => {
    return res;
  });
}

const stonlyGuides = [
  { id: "storage", guideId: "0lkyBs1m7w" },
  { id: "project", guideId: "9aqYDq5Rg9" },
  { id: "seat", guideId: "zYvO9l3SYZ" },
  { id: "e_sign", guideId: "JGg62C70qi" },
];

export function normalizeSubscriptionUsages(usages) {
  return (usages || []).map((usage) => {
    const guide = stonlyGuides.find((guide) => guide.id === usage.id);

    return {
      ...usage,
      guideId: guide?.guideId || null,
    };
  });
}

<template>
  <span class="d-flex text-center justify-space-between">
    <span
      v-for="day of dateMixins_localeAwareWeekdays('abbr')"
      :key="day.value"
    >
      <v-simple-checkbox
        color="primary"
        hide-details
        class="mt-0"
        :value="isDaySelected(day.value)"
        @input="onDaysChange(day.value)"
      ></v-simple-checkbox>
      <div
        class="cursor-pointer user-select-none"
        @click="onDaysChange(day.value)"
      >
        {{ day.text }}
      </div>
    </span>
  </span>
</template>

<script>
import { dateMixins } from "@/helpers/mixins";

export default {
  mixins: [dateMixins],
  props: {
    days: Array,
  },
  model: {
    prop: "days",
    event: "days:change",
  },
  computed: {
    localDays: {
      get() {
        return this.days;
      },
      set(value) {
        this.$emit("days:change", value);
      },
    },
  },
  methods: {
    isDaySelected(day) {
      return this.localDays.includes(day);
    },
    onDaysChange(day) {
      const indexOfDay = this.localDays.indexOf(day);

      if (indexOfDay !== -1) {
        this.localDays.splice(indexOfDay, 1);
      } else {
        this.localDays.push(day);
      }
    },
  },
};
</script>

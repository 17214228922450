<template>
  <v-breadcrumbs
    :items="path"
    class="pa-0 mb-4"
  >
    <template v-slot:divider>
      <v-icon x-small>{{ $icons.LIGHT.COMMON.CHEVRON_RIGHT }}</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <li>
        <v-btn
          class="text-transform-none text-body-1 pa-0"
          @click="onClick(item.id)"
          depressed
          text
          small
          :class="isCurrentFolder(item) ? 'font-weight-bold' : ''"
        >
          {{ isRoot(item) ? $t("common.home") : item.name }}
        </v-btn>
      </li>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    path: {
      type: Array,
      required: true,
    },
    currentFolderId: {
      type: String,
      required: true,
    },
    isLoading: Boolean,
  },
  methods: {
    onClick(id) {
      if (this.isLoading) return;
      this.$emit("path:click", id);
    },
    isRoot(item) {
      return item.level === 0 && item.name === "root";
    },
    isCurrentFolder(item) {
      return item.id === this.currentFolderId;
    },
  },
};
</script>

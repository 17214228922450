<template>
  <AppDefaultTooltip
    :disabled="!isMini"
    right
  >
    <template v-slot:activator="{ attrs, on }">
      <v-list-item
        class="navigation-item"
        active-class="navigation-item-active"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
        @click="() => {}"
      >
        <v-list-item-icon
          class="d-flex justify-center"
          v-if="prependIcon"
        >
          <v-icon :small="prependIconSmall">{{ prependIcon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title class="text-body-2">
          {{ title }}
        </v-list-item-title>

        <v-chip
          :small="appendChipSmall"
          v-if="appendChipText"
        >
          {{ appendChipText }}
        </v-chip>

        <v-icon
          v-if="appendIcon"
          :class="showAppendIconOnlyOnHover ? 'hide-action-icon' : ''"
          :small="appendIconSmall"
        >
          {{ appendIcon }}
        </v-icon>
        <slot name="append-item"></slot>
      </v-list-item>
    </template>
    <span>
      {{ title }}
    </span>
  </AppDefaultTooltip>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    prependIcon: String,
    prependIconSmall: Boolean,
    title: String,
    appendIcon: String,
    appendIconSmall: Boolean,
    showAppendIconOnlyOnHover: Boolean,
    appendChipText: [String, Number],
    appendChipSmall: Boolean,
  },
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
};
</script>

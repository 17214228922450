<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card :disabled="isCreating">
      <v-card-title>
        <span class="text-h5 font-weight-bold">
          {{ $t("settings.supportWorspaces.createWorkspace.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="createWorkspaceForm"
          v-model="form.isValid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.ownerName") }}
            </div>
            <AppDefaultTextField
              v-model="form.ownerName.value"
              :rules="form.ownerName.rules"
              autofocus
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.ownerEmail") }}
            </div>
            <AppDefaultTextField
              v-model="form.ownerEmail.value"
              :rules="form.ownerEmail.rules"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.ownerRoles") }}
            </div>
            <AppDefaultAutocomplete
              :items="mappedRoles"
              :rules="form.roles.rules"
              v-model="form.roles.value"
              item-value="value"
              multiple
              clearable
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.ownerPhone") }}
            </div>
            <AppPhoneInput
              v-model="form.ownerPhone.value"
              hide-label
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.companyName") }}
            </div>
            <AppOrganizationLookupAutocomplete
              v-model="organization"
              :rules="$rules.REQUIRED"
              dense
              :placeholder="$t('common.search')"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.companyAddress") }}
            </div>
            <AppAddressCombobox
              ref="address"
              @change="onAddressChange"
              :value="form.address.value"
              :rules="form.address.rules"
              :hide-details="false"
              :dense="true"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.numberOfEmployees") }}
            </div>
            <AppDefaultTextField
              v-model="form.employees.value"
              :rules="form.employees.rules"
              type="number"
              :dense="true"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.language") }}
            </div>
            <AppLanguageSelect
              v-model="form.language.value"
              :rules="$rules.REQUIRED"
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.industries") }}
            </div>
            <AppDefaultAutocomplete
              :items="industries"
              v-model="form.industries.value"
              :rules="form.industries.rules"
              :loading="isLoadingIndustries"
              item-value="value"
              item-text="text"
              multiple
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.preferredIntegrations") }}
            </div>
            <AppDefaultAutocomplete
              :items="mappedIntegrations"
              v-model="form.integrations.value"
              :rules="form.integrations.rules"
              :loading="isLoadingIntegrations"
              item-value="value"
              multiple
              clearable
              select-all
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.selectPlan") }}
            </div>
            <AppDefaultSelect
              :items="mappedPlans"
              v-model="form.planCode.value"
              :clearable="false"
              :loading="isLoading"
              item-text="text"
              item-value="code"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onClose"
          :confirmText="$t('settings.workspaces.workspaceCommon.createButton')"
          :loading="isCreating"
          :disabled="isLoading"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { signupEvent } from "../../../helpers/util";
import { createDomain } from "../../../services/cms/createDomainService";
import { getIndustries } from "@/services/resources/industries";
import { getRoles } from "@/services/resources/roles";
import { getIntegrationNames } from "@/services/integration/common/integrationNames";
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    deleteBtnText: String,
  },
  data() {
    return {
      form: {
        isValid: false,
        companyName: {
          value: null,
        },
        orgnr: {
          value: null,
        },
        ownerName: {
          value: null,
          rules: this.$rules.REQUIRED,
        },
        ownerEmail: {
          value: null,
          rules: [...this.$rules.EMAIL_RULES, ...this.$rules.REQUIRED],
        },
        roles: {
          value: [],
          rules: [],
        },
        ownerPhone: {
          value: "",
        },
        language: {
          value: "nb",
        },
        planCode: {
          value: this.$constant.PLAN_CODE.GRIPR_TRIAL,
          rules: [],
        },
        website: {
          value: "",
          rules: [],
        },
        industries: {
          value: [],
          rules: this.$rules.REQUIRED,
        },
        integrations: {
          value: [],
          rules: [],
        },
        address: {
          value: "",
          rules: [],
        },
        employees: {
          value: "",
          rules: [...this.$rules.INTEGER],
        },
      },
      isCreating: false,
      industries: [],
      integrations: [],
      roles: [],
      isLoadingIndustries: false,
      isLoadingIntegrations: false,
      isLoadingRoles: false,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("plans", {
      plans: (state) => state.plans,
      currency: (state) => state.currency,
      isLoadingPlans: (state) => state.isLoading,
    }),
    mappedIntegrations() {
      if (!this.integrations) return [];
      return this.integrations.map((key) => {
        const isEbyggesok = key === this.$constant.EBYGGESOK;
        let name = isEbyggesok ? "EBYGGESØK" : key;
        name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
        return {
          value: key,
          text: name,
        };
      });
    },
    mappedRoles() {
      if (!this.roles) return [];
      const mappedRoles = this.roles.map((role) => {
        return { value: role, text: this.$t(`roles.${role}`) };
      });
      return mappedRoles;
    },
    mappedPlans() {
      if (!this.plans) return;
      const mainPlans = this.plans.filter((plan) => {
        return (
          this.$constant.MAIN_PLAN_CODES.includes(plan.code) &&
          plan.code !== this.$constant.PLAN_CODE.GRIPR_FREE
        );
      });
      const mapped = mainPlans.map((plan) => {
        const interval = this.$t(
          `settings.billing.subscription.planCard.intervalsAbbreviated.MONTH`,
        );
        const planName = plan.name;
        const planPrice = plan.price;
        const currency = this.currency;
        const text = this.$t("settings.workspaces.workspaceCommon.planText", {
          planName,
          planPrice,
          currency,
          interval,
        });
        return {
          ...plan,
          text,
        };
      });

      return mapped;
    },
    isLoading() {
      return this.plans.length === 0 && this.isLoadingPlans;
    },
    organization: {
      get() {
        return {
          name: this.form.companyName.value,
          orgnr: this.form.orgnr.value,
        };
      },
      set(item) {
        if (!this.form.employees.value) {
          this.form.employees.value = item?.numberOfEmployees ?? "";
        }
        this.form.companyName.value = item?.name ?? null;
        this.form.website.value = item?.website ?? null;
        this.form.orgnr.value = item?.orgnr ?? null;

        if (item?.fullAddress) {
          this.form.address.value = {
            address: item.address,
            place: item.place,
            postalNr: item.postalNr,
            text: item.fullAddress,
          };
        }
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getIndustries();
          this.getIntegrations();
          this.getRoles();
          this.getPlans();
        }
      },
    },
  },
  methods: {
    onSubmit() {
      const isValid = this.$refs.createWorkspaceForm.validate();
      if (!isValid) return;
      this.isCreating = true;
      this.createWorkspace();
    },
    async createWorkspace() {
      const domainBody = this.createBody();
      const language = this.form.language.value;
      await createDomain(domainBody)
        .then(() => {
          this.triggerSnackbar(
            this.$t("cms.createWorkspace.successfullyCreatedWorkspace"),
            "success",
          );
          signupEvent({ orgnr: domainBody.orgnr });
        })
        .catch((err) => {
          const text = err?.response?.data?.message || "Something went wrong...";
          this.triggerSnackbar(text, "error");
          throw new Error(err);
        })
        .finally(() => {
          this.onWorkspaceCreated(language);
        });
    },
    triggerSnackbar(text, color) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text,
        color,
      });
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.createWorkspaceForm.resetValidation();
    },
    onWorkspaceCreated(language) {
      this.$refs.createWorkspaceForm.reset();
      setTimeout(() => {
        this.form.language.value = language;
      }, 20);
      this.isCreating = false;
      this.closeDialog();
      this.$emit("workspace:created");
    },
    onAddressChange(location) {
      const value =
        location !== null && typeof location === "object" ? location.text : location;
      this.form.address.value = value;
    },
    async getIndustries() {
      this.isLoadingIndustries = true;
      getIndustries()
        .then((industries) => {
          this.industries = industries;
        })
        .finally(() => {
          this.isLoadingIndustries = false;
        });
    },
    async getIntegrations() {
      this.isLoadingIntegrations = true;
      getIntegrationNames()
        .then(({ integrations }) => {
          this.integrations = integrations;
        })
        .finally(() => {
          this.isLoadingIntegrations = false;
        });
    },
    async getRoles() {
      this.isLoadingRoles = true;
      getRoles()
        .then((roles) => {
          this.roles = roles;
        })
        .finally(() => {
          this.isLoadingRoles = false;
        });
    },
    createBody() {
      let address;
      if (typeof this.form.address.value === "object") {
        address = {
          place: this.form.address.value.place,
          address: this.form.address.value.address,
          zip: this.form.address.value.postalNr,
        };
      } else {
        address = {
          address: this.form.address.value,
          place: null,
          zip: null,
        };
      }
      const body = {
        firstName: this.form.ownerName.value,
        website: this.form.website.value,
        email: this.form.ownerEmail.value,
        ownerPhone: this.form.ownerPhone.value,
        roles: this.form.roles.value,
        name: this.form.companyName.value,
        orgnr: this.form.orgnr.value,
        ...address,
        employees: parseInt(this.form.employees.value) ?? null,
        language: this.form.language.value,
        industries: this.form.industries.value,
        integrations: this.form.integrations.value,
        planCode: this.form.planCode.value,
      };

      return body;
    },
    getPlans() {
      this.$store.dispatch("plans/getPlans");
    },
  },
};
</script>
@/services/signup/industries @/services/signup/roles
@/services/resources/industries@/services/resources/roles

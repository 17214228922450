<template>
  <v-checkbox
    class="pl-2 pb-2 mt-0"
    v-model="localValue"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div slot="label">
      <span>
        {{ $t("signUp.step2.termsAndConditions") }}
      </span>
      <AppTosAndPrivacyPolicy
        :tos="tos"
        :pp="pp"
        required
      />
    </div>
  </v-checkbox>
</template>

<script>
export default {
  props: {
    value: Boolean,
    tos: {
      type: Boolean,
      default: true,
    },
    pp: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>

<template>
  <v-menu
    offset-y
    transition="slide-y-transition"
    origin="top right"
  >
    <template v-slot:activator="{ on, attrs }">
      <AppBtnWithIndicator
        v-bind="attrs"
        v-on="on"
        :showIndicator="!groupByMixins_isGroupSelected(undefined)"
        :icon="$icons.SOLID.ACTION.DROPDOWN"
        :indicatorText="selectedText"
        :text="$t('common.groupBy')"
      />
    </template>
    <div
      class="pa-2 ui-background d-flex flex-column"
      style="min-width: 70px"
    >
      <AppMenuItem
        v-for="item in menuItems"
        :key="item.text"
        :text="item.text"
        :icon="item.icon"
        :isSelected="item.isSelected"
        @click="item.click"
      />
    </div>
  </v-menu>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
export default {
  mixins: [groupByMixins],
  props: {
    tableModel: String,
    menuItems: Array,
  },
  computed: {
    selectedText() {
      return this.menuItems?.find((item) => item.isSelected)?.text;
    },
  },
};
</script>

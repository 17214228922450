<template>
  <div
    class="d-flex position-relative"
    v-if="event"
    style="user-select: none"
  >
    <AppUserAvatar
      :user="event.user"
      v-if="event.user"
      small
      style="padding-left: 2px"
    />
    <AppDefaultTooltip
      class="w-100"
      top
      left
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="pl-1 pr-1 text-truncate caption w-100"
          style="margin-left: 1px"
        >
          <span class="w-100">{{ event.name }}</span>
        </div>
      </template>
      <span>{{ event.name }}</span>
      <!-- <div class="start-section" @mousedown="startDrag('start')"></div>
      <div class="center-section" @mousedown="startDrag('center')"></div>
      <div class="end-section" @mousedown="startDrag('end')"></div> -->
    </AppDefaultTooltip>
    <AppDefaultTooltip
      top
      v-if="onlyHasEndDateAndIsBoth"
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          small
          class="pr-1 custom-icon-background"
          color="info"
        >
          {{ $icons.SOLID.ACTION.INFO }}
        </v-icon>
      </template>
      <span>{{ $t("taskManagement.calendar.itemOnlyHasEndDateTooltip") }}</span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
    onlyHasEndDateAndIsBoth: Boolean,
  },
  // methods: {
  //   startDrag(position) {
  //     this.$emit("drag:start", { event: this.event, position });
  //   },
  // },
};
</script>

<style>
.custom-icon-background::before {
  background-color: white; /* Change this to your desired color */
  border-radius: 50%; /* Optional: to make the background circular */
  padding: 1.5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
<!-- <style scoped>
.start-section,
.center-section,
.end-section {
  position: absolute;
  top: 0;
  bottom: 0;
}

.start-section {
  cursor: w-resize;
  left: 0;
  width: 15%;
}

.center-section {
  cursor: move;
  left: 15%;
  width: 70%;
}

.end-section {
  cursor: e-resize;
  right: 0;
  width: 15%;
}
</style> -->

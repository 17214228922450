<template>
  <v-dialog
    v-model="localDialog"
    maxWidth="520"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <div
          class="label--small"
          :class="required ? 'required-asterisk' : ''"
        >
          {{ messageLabel }}
        </div>
        <AppDefaultTextarea
          ref="messageTextarea"
          v-model="message"
          label=""
          rows="2"
          :rules="required ? $rules.REQUIRED : []"
        />
      </v-card-text>

      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onClose"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    required: Boolean,
    title: {
      type: String,
      default: "Title",
    },
    messageLabel: {
      type: String,
      default: null,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      message: "",
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.$refs.messageTextarea.resetValidation();
      this.closeDialog();
    },
    onSubmit() {
      if (!this.$refs.messageTextarea.validate()) return;
      this.$emit("submit", { message: this.message });
      this.closeDialog();
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newLabelForm"
          v-model="label.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.name") }}
            </div>
            <AppDefaultTextField
              autofocus
              v-model="label.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
            />
          </div>

          <AppErrorAlert v-if="errorMessage">
            {{ errorMessage }}
          </AppErrorAlert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="close"
          :loading="isSubmitting"
          :confirmText="confirmText"
        />
        <template v-if="isEditMode">
          <v-spacer />
          <v-btn
            icon
            color="error"
            :loading="isDeleting"
            @click="onDeleteLabel"
          >
            <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
          </v-btn>
        </template>
      </v-card-actions>

      <AppDeleteConfirmationDialog
        v-model="deleteLabelDialog.active"
        :title="deleteTitle"
        :subtitle="deleteSubtitle"
        skip-validation
        @delete="deleteLabel"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    data: Object,
    type: String,
    title: String,
    confirmText: String,
    deleteTitle: String,
    deleteSubtitle: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isSubmitting: false,
      errorMessage: "",
      isDeleting: false,
      label: {
        valid: false,
        name: {
          value: "",
        },
      },
      deleteLabelDialog: {
        active: false,
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isEditMode() {
      return this.data ? true : false;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData(this.data);
        }
      },
    },
  },
  methods: {
    fillFormWithData(data) {
      if (data) {
        this.label.name.value = data.name;
      }
    },
    createOrUpdateLabel(data) {
      if (this.isEditMode) {
        this.updateLabel(data);
      } else {
        this.createLabel(data);
      }
    },
    createLabel(data) {
      this.isSubmitting = true;
      this.$store
        .dispatch("label/createLabel", data)
        .then((label) => {
          this.afterSubmit(label);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    updateLabel(data) {
      this.isSubmitting = true;
      this.$store
        .dispatch("label/updateLabel", data)
        .then((label) => {
          this.afterSubmit();
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    deleteLabel() {
      this.isDeleting = true;
      this.$store
        .dispatch("label/deleteLabels", { ids: [this.data.id] })
        .then(() => {
          this.afterDelete();
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    afterSubmit(label) {
      this.$refs.newLabelForm.reset();
      this.localDialog = false;
      this.$emit("confirm", { label });
    },
    afterDelete() {
      this.$refs.newLabelForm.reset();
      this.localDialog = false;
    },
    onCreate() {
      const valid = this.$refs.newLabelForm.validate();
      if (valid) {
        const data = {
          id: this.data?.id,
          body: {
            name: this.label.name.value,
            type: this.type,
          },
        };
        this.createOrUpdateLabel(data);
      }
    },
    close() {
      this.localDialog = false;
      this.$refs.newLabelForm.resetValidation();
    },
    onDeleteLabel() {
      this.deleteLabelDialog.active = true;
    },
  },
};
</script>

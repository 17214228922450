<template>
  <AppDefaultAutocomplete
    ref="autocomplete"
    v-bind="params"
    v-on="listeners"
    :items="units"
    :loading="isUnitsLoading"
    item-text="label"
    item-value="value"
  >
    <slot
      v-for="slot in Object.keys($slots)"
      :name="slot"
      :slot="slot"
    ></slot>
    <template
      v-for="(index, name) in $scopedSlots"
      v-slot:[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      ></slot>
    </template>
  </AppDefaultAutocomplete>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

export default {
  props: {
    isReadyToInitialFetch: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      //make sure it doesnt fetch again if isReadyToInitialFetch changes
      hasInitialFetched: false,
    };
  },
  watch: {
    isReadyToInitialFetch: {
      handler(newVal) {
        if (newVal && !this.hasInitialFetched) {
          this.getUnits().then(() => {
            this.hasInitialFetched = true;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
  },
};
</script>

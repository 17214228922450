<template>
  <AppDefaultTooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
      >
        <AppColorChip
          v-if="status"
          :text="$icons.LIGHT.COMMON.SIGNATURE"
          @click="$emit('click', $event)"
          clickable
          :color="`${color}--text`"
          :iconColor="color"
          is-icon
        />
      </div>
    </template>
    <span>{{ text }}</span>
  </AppDefaultTooltip>
</template>

<script>
export default {
  props: {
    signatureSession: Object,
  },
  computed: {
    status() {
      return this.signatureSession?.status;
    },
    text() {
      return this.$te(`formManagement.list.signStatus.${this.status}`)
        ? this.$t(`formManagement.list.signStatus.${this.status}`)
        : "";
    },
    color() {
      let color = "success";

      switch (this.status) {
        case this.$constant.FORM_SIGN_SESSION_STATUS.NOT_STARTED:
        case this.$constant.FORM_SIGN_SESSION_STATUS.IN_PROGRESS:
          color = "warning";
          break;
        case this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED:
          color = "success";
          break;
        case this.$constant.FORM_SIGN_SESSION_STATUS.DECLINED:
          color = "error";
          break;
      }

      return color;
    },
  },
};
</script>

<template>
  <AppWidgetTemplate
    @delete:click="onDelete"
    :title="$t('projectView.info.widgets.toDoList')"
    :isLoading="isLoading"
    v-bind="templateProps"
    guideId="8xdVxsWaDr"
  >
    <template v-slot:append-header>
      <AppToDoProgressIndicator />
    </template>
    <AppToDoList
      :modelId="modelId"
      :model="model"
      class="to-do-list-widget"
    />
  </AppWidgetTemplate>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    modelId: String,
    model: String,
    templateProps: Object,
  },
  computed: {
    ...mapState("todos", {
      isLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>

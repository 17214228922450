<template>
  <div
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="pb-4 d-flex align-center">
      <AppStorageSearchField
        :rootId="rootId"
        @search:click="onSearchClick"
      />
    </div>
    <AppStoragePath
      :path="path"
      :currentFolderId="folderId"
      :isLoading="isLoading || isCreateNewLoading"
      @path:click="onPathClick"
    />
    <AppStorageTable
      ref="dataTable"
      :storages="items"
      :isLoading="isLoading"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      :dataTableHeight="dataTableHeight"
      :contextMenuItemsToHide="contextMenuItemsToHide"
      :storageTypesNotAllowedToSelect="storageTypesNotAllowedToSelect"
      :actionConfigs="actionConfigs"
      :removeMutationType="`${storeNamespace}/removeStorageItem`"
      :updateMutationType="`${storeNamespace}/updateStorageItem`"
      @input="$emit('input', $event)"
      @storage:click="onStorageClick"
      @cell:click="handleInteractableClick"
    />

    <AppRenameStorageDialog
      v-model="dialog.rename.active"
      :item="dialog.rename.item"
      @submit:form="updateStorage"
    />

    <AppMoveStorageDialog
      v-model="dialog.move.active"
      :selectedStorages="dialog.move.storage"
      :permissionLevel="dialog.move.permissionLevel"
      :parentId="dialog.move.parentId"
      @after:save="afterMove"
    />

    <AppCopyItemsDialog
      v-model="dialog.copy.active"
      :inItems="dialog.copy.storages"
      @submit="afterCopy"
      @preview:item="(item) => onStorageClick({ storage: item, disablePagination: true })"
      type="STORAGE"
    />

    <AppLinkFilesDialog
      v-model="dialog.link.active"
      :files="dialog.link.files"
      :parentId="dialog.link.parentId"
    />

    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteConfirmation.active"
      :item="dialog.deleteConfirmation.item"
      :title="
        $tc(
          'fileManagement.fileExplorer.deleteConfirmation.title',
          dialog.deleteConfirmation.item.length,
        )
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="afterDeleteConfirmation"
    />

    <AppBoligmappaFileUploadDialog
      v-if="showBoligmappa"
      v-model="dialog.boligmappaFileUpload.active"
      :boligmappaNumber="boligmappa.boligmappaNumber"
      :projectId="boligmappa.projectId"
      :files="dialog.boligmappaFileUpload.files"
      @created="afterBoligmappaUpload"
    />

    <AppLoaderDialog
      v-model="dialog.creatingSignableDocument.active"
      :title="$t('fileManagement.creatingSignableDocumentDialog.title')"
    />

    <AppFilePreviewDialog
      v-model="fileId"
      :initialActiveMenu="activePreviewMenu"
      :folderId="folderId"
      :disablePagination="dialog.filePreview.disablePagination"
      @dialog:close="onPreviewDialogClose"
    />

    <AppActivityIndicator
      :show="isDownloading"
      :text="$t('fileManagement.common.downloadingFiles')"
    />
    <AppActivityIndicator
      :show="isDeleting"
      :text="$t('fileManagement.common.deletingFiles')"
    />
    <AppStorageMergeFilesMenu
      v-if="!contextMenuItemsToHide?.merge"
      :parentId="folderId"
      :positionMergeActionBar="positionMergeActionBar"
      toolbarClass="merge-action-bar"
    />
    <AppIsActivePickerMenu
      ref="shareableMenu"
      :activeLabelKey="$t('common.shareable')"
      :inactiveLabelKey="$t('common.notShareable')"
      @submit="
        ({ isActive }) => updateStorages({ isShareableWithChildDomains: isActive })
      "
    />
    <div
      :class="[isDragging ? 'file-management_drop-zone' : 'd-none']"
      @drop="drop"
    >
      <div class="drop-zone-overlay">
        <div class="drop-zone-info">
          <div class="drop-zone-info__text">Drop files to upload them</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";
import { isESignFile } from "@/helpers/util";
import { storageActionMixins } from "@/helpers/mixins/tableActionMixins/storageActionMixins";
import { planRestrictionsMixins } from "@/helpers/mixins/planRestrictionsMixins";

export default {
  mixins: [storageMixins, storageActionMixins, planRestrictionsMixins],
  data() {
    return {
      selectedStorages: [],
      isDragging: false,
      dialog: {
        rename: {
          active: false,
          item: null,
        },
        move: {
          active: false,
          storage: [],
          permissionLevel: null,
          parentId: null,
        },
        copy: {
          active: false,
          storages: [],
          parentId: null,
        },
        link: {
          active: false,
          files: [],
          parentId: null,
        },
        deleteConfirmation: {
          item: [],
          active: false,
        },
        creatingSignableDocument: {
          active: false,
        },
        filePreview: {
          disablePagination: false,
        },
        boligmappaFileUpload: {
          active: false,
          files: [],
        },
      },
      itemsToEdit: [],
    };
  },
  props: {
    storeNamespace: {
      type: String,
      required: true,
    },
    folderId: String,
    rootId: String,
    folderRouterName: String,
    dataTableHeight: String,
    contextMenuItemsToHide: Object,
    fileId: String,
    activePreviewMenu: String,
    storageTypesNotAllowedToSelect: Array,
  },
  computed: {
    ...mapGetters("auth", {
      isCorporateManagementFeatureEnabled: "isCorporateManagementFeatureEnabled",
      isDomainElevatedUser: "isDomainElevatedUser",
    }),
    ...mapGetters("acceptedFileTypes", {
      acceptedFileTypes: "acceptedFileTypes",
    }),
    ...mapGetters("wopiDiscovery", {
      getWopiApplicationOpenAction: "getApplicationOpenAction",
    }),
    ...mapState({
      state() {
        return this.$store.state[this.storeNamespace];
      },
      isLoading(state, getters) {
        return getters[this.storeNamespace + "/isLoading"];
      },
      canUpload(state, getters) {
        return getters[this.storeNamespace + "/canUpload"];
      },
      parentIsValid(state, getters) {
        return getters[this.storeNamespace + "/parentIsValid"];
      },
    }),
    items() {
      return this.state.items;
    },
    parent() {
      return this.state.parent;
    },
    path() {
      return this.state.path;
    },
    isFileUploading() {
      return this.state.file.isUploading;
    },
    isFolderUploading() {
      return this.state.folder.isUploading;
    },
    isDownloading() {
      return this.state.isDownloading;
    },
    isDeleting() {
      return this.state.isDeleting;
    },
    ...mapState("auth", {
      isCmsAdmin: (state) => state.isCmsAdmin,
    }),
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    ...mapState("wopiDiscovery", {
      wopiDiscovery: (state) => state.discovery,
    }),
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    ...mapState("project", {
      project: (state) => state.project,
    }),
    isCreateNewLoading() {
      return this.isFileUploading || this.isFolderUploading;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    hasSelectedFilesToMerge() {
      return this.selectedFilesToMerge.length > 0;
    },
    shouldPreventDestruction() {
      return this.hasSelectedFilesToMerge;
    },
    boligmappa() {
      if (!this.projectId) return {};
      if (!this.project) return {};
      const isBoligmappaEnabled = this.isIntegrationEnabled(this.$constant.BOLIGMAPPA);
      const boligmappaNumber = this.project.boligmappaNumber;
      if (!boligmappaNumber || !isBoligmappaEnabled) return {};
      return {
        projectId: this.projectId,
        boligmappaNumber,
      };
    },
    showBoligmappa() {
      if (!this.boligmappa) return false;
      if (!Object.values(this.boligmappa).length > 0) return false;
      return true;
    },
    actionConfigs() {
      const {
        OPEN_WITH,
        PREVIEW,
        OPEN_IN_WORD,
        OPEN_IN_EXCEL,
        OPEN_IN_POWERPOINT,
        RENAME,
        DOWNLOAD,
        DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS,
        MOVE,
        COPY,
        LINK_FILE_TO_FOLDER,
        ADD_TO_MERGE,
        SEND_TO_SIGN,
        REQUEST_READ_CONFIRMATION,
        COPY_URL,
        UPLOAD_TO_BOLIGMAPPA,
        DELETE,
        EDIT_IS_SHAREABLE,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: OPEN_WITH,
          props: {
            hidden: this.storageActionMixins_isFolderSelected,
          },
          on: {
            click: (e) =>
              this.$refs.dataTable.openOpenWithMenu({ ...e, direction: "right" }),
          },
        },
        {
          key: PREVIEW,
          on: {
            click: ({ items }) => {
              const id = items[0].id;
              this.previewFile({ fileId: id });
            },
          },
        },
        {
          key: RENAME,
          props: {
            hidden: ({ items }) => this.contextMenuItemsToHide?.rename,
            disabled: ({ items }) => !this.storageActionMixins_canRename({ items }),
          },
          on: {
            click: ({ items }) => this.onStorageRename(items[0]),
          },
          divider: true,
        },
        {
          key: DOWNLOAD,
          props: {
            hidden: ({ items }) => this.contextMenuItemsToHide?.download,
            disabled: () => !this.storageActionMixins_canDownload(),
          },
          on: {
            click: this.onDownload,
          },
        },
        {
          key: DOWNLOAD_WITHOUT_CONVERTED_MERGE_WORDS,
          props: {
            hidden: ({ items }) =>
              !this.storageActionMixins_showDownloadWithoutConvertedMergeWords({ items }),
          },
          on: {
            click: this.onDownloadWithoutConvertedMergeWords,
          },
        },
        {
          key: MOVE,
          props: {
            hidden: ({ items }) => this.contextMenuItemsToHide?.move,
            disabled: ({ items }) => !this.storageActionMixins_canMove({ items }),
          },
          on: {
            click: ({ items }) => this.onStorageMove(items),
          },
          divider: true,
        },
        {
          key: COPY,
          props: {
            hidden: ({ items }) =>
              this.contextMenuItemsToHide?.copy ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) => !this.storageActionMixins_canCopy({ items }),
          },
          on: {
            click: ({ items }) => this.onStorageCopy(items),
          },
        },
        {
          key: LINK_FILE_TO_FOLDER,
          props: {
            hidden: ({ items }) =>
              this.contextMenuItemsToHide?.link ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) => !this.storageActionMixins_canLink({ items }),
          },
          on: {
            click: ({ items }) => this.onStorageLink(items),
          },
        },
        {
          key: ADD_TO_MERGE,
          props: {
            hidden: ({ items }) =>
              this.contextMenuItemsToHide?.merge ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) => !this.storageActionMixins_canMerge({ items }),
            showStar: !this.planRestrictionsMixins_canMergeFiles,
            planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
          },
          on: {
            click: this.onAddToMerge,
          },
        },
        {
          key: SEND_TO_SIGN,
          props: {
            hidden: ({ items }) =>
              this.contextMenuItemsToHide?.signableDocument ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) =>
              !this.storageActionMixins_canCreateSignableStorage({ items }),
            labelKeyParams: ({ items }) => ({
              isSent: items.every(isESignFile),
            }),
          },
          on: {
            click: this.onSendToSign,
          },
        },
        {
          key: REQUEST_READ_CONFIRMATION,
          props: {
            hidden: ({ items }) =>
              this.contextMenuItemsToHide?.requestReadConfirmation ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) =>
              !this.storageActionMixins_canRequestReadConfirmation({ items }),
          },
          on: {
            click: this.onRequestReadConfirmation,
          },
        },
        {
          key: EDIT_IS_SHAREABLE,
          props: {
            hidden: ({ items }) =>
              !this.isCorporateManagementFeatureEnabled ||
              !this.isDomainElevatedUser ||
              this.storageActionMixins_isOnlyFoldersSelected({ items }),
            disabled: ({ items }) =>
              !this.storageActionMixins_canEditIsShareable({ items }),
          },
          on: {
            click: _openEditMenu("shareableMenu"),
          },
        },
        {
          key: COPY_URL,
          props: {
            hidden: ({ items }) => this.contextMenuItemsToHide?.copyUrl,
          },
          on: {
            click: ({ items }) => this.onCopyUrl({ items }),
          },
        },
        {
          key: UPLOAD_TO_BOLIGMAPPA,
          props: {
            hidden: ({ items }) =>
              !this.showBoligmappa || this.contextMenuItemsToHide?.uploadToBoligmappa,
            disabled: ({ items }) =>
              !this.storageActionMixins_canUploadToBoligmappa({ items }),
          },
          on: {
            click: this.onSendToBoligmappa,
          },
        },
        {
          key: DELETE,
          props: {
            hidden: ({ items }) => this.contextMenuItemsToHide?.delete,
            disabled: ({ items }) => !this.storageActionMixins_canDelete({ items }),
          },
          on: {
            click: this.onDelete,
          },
          divider: true,
        },
        {
          key: OPEN_IN_WORD,
          props: {
            disabled: () => !this.planRestrictionsMixins_canUseOfficeOnline,
            showStar: !this.planRestrictionsMixins_canUseOfficeOnline,
          },
          on: {
            click: this.handleOfficeOpen,
          },
        },
        {
          key: OPEN_IN_EXCEL,
          props: {
            disabled: () => !this.planRestrictionsMixins_canUseOfficeOnline,
            showStar: !this.planRestrictionsMixins_canUseOfficeOnline,
          },
          on: {
            click: this.handleOfficeOpen,
          },
        },
        {
          key: OPEN_IN_POWERPOINT,
          props: {
            disabled: () => !this.planRestrictionsMixins_canUseOfficeOnline,
            showStar: !this.planRestrictionsMixins_canUseOfficeOnline,
          },
          on: {
            click: this.handleOfficeOpen,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.STORAGE_ACTIONS,
      });

      return config;
    },
  },
  watch: {
    folderId() {
      this.getStorage(this.folderId);
    },
  },
  methods: {
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    positionMergeActionBar() {
      const child = document.getElementById("mergeActionBar");

      if (!child) return;

      const pageWidth = window.innerWidth;
      const mergeActionBarWidth = 300; // Width of the merge action bar

      // Calculate the left position for centering
      const leftPos = (pageWidth - mergeActionBarWidth / 2) / 2;

      child.style.left = `${leftPos}px`;
      child.style.bottom = "10px";
    },
    getStorage(id) {
      this.$store.dispatch(`${this.storeNamespace}/getStorage`, id);
      this.$store.dispatch(`${this.storeNamespace}/getParent`, id);
      this.$store.dispatch(`${this.storeNamespace}/getStoragePath`, {
        id,
        rootId: this.rootId,
      });
    },
    getAcceptedFileTypes() {
      this.$store.dispatch(`acceptedFileTypes/getAcceptedFileTypes`);
    },
    getWopiDiscovery() {
      this.$store.dispatch("wopiDiscovery/getDiscovery");
    },
    navigateToFolder(id, rootId = this.rootId) {
      this.$emit("navigateToFolder", { id, rootId });
    },
    dragover(e) {
      e.preventDefault();
      if (!e.dataTransfer?.types?.includes("Files")) return;
      this.isDragging = true;
    },
    dragleave(e) {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.onFileUpload(e);
      this.isDragging = false;
    },
    previewFile({ fileId, activeMenu, openAddRecipients }) {
      this.$emit("previewFile", {
        fileId,
        activeMenu,
        openAddRecipients,
      });
    },
    onPathClick(id) {
      if (id === this.folderId) return this.getStorage(id);
      this.navigateToFolder(id);
    },
    onStorageClick({ storage, disablePagination = false }) {
      if (storage.type === this.$constant.FOLDER) {
        this.navigateToFolder(storage.id);
      } else if (storage.type.includes(this.$constant.FILE)) {
        this.dialog.filePreview.disablePagination = disablePagination;
        this.previewFile({ fileId: storage.id });
      }
    },
    onSearchClick({ item }) {
      if (item.type === this.$constant.FOLDER && item.id === this.folderId)
        return this.getStorage(item.id);
      this.onStorageClick({ storage: item, disablePagination: true });
    },
    onSendToBoligmappa({ items }) {
      this.dialog.boligmappaFileUpload.active = true;
      this.dialog.boligmappaFileUpload.files = items;
    },
    onStorageRename(storage) {
      this.dialog.rename.item = storage;
      this.dialog.rename.active = true;
    },
    onStorageMove(storages) {
      const permissionLevel = storages[0].permissionLevel;
      this.dialog.move.storage = storages;
      this.dialog.move.permissionLevel = permissionLevel;
      this.dialog.move.parentId = this.folderId;
      this.dialog.move.active = true;
    },
    onStorageCopy(storages) {
      this.dialog.copy.storages = storages;
      this.dialog.copy.active = true;
      this.dialog.copy.parentId = this.folderId;
    },
    onStorageLink(storages) {
      this.dialog.link.files = storages;
      this.dialog.link.parentId = this.folderId;
      this.dialog.link.active = true;
    },
    onDownload({ items }) {
      const ids = items.map((item) => item.id);
      this.$store.dispatch("storage/download", { ids });
    },
    onDownloadWithoutConvertedMergeWords({ items }) {
      const ids = items.map((item) => item.id);
      this.$store.dispatch("storage/download", {
        ids,
        queryParams: { withoutConvertedMergeWords: true },
      });
    },
    onCopyUrl({ items }) {
      const taskUrl = this.$router.resolve(this.copyUrlRoute({ items })).href;

      const fullPath = `${window.location.origin}${taskUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    copyUrlRoute({ items }) {
      const storage = items[0];
      const isFolderSelected = this.storageActionMixins_isFolderSelected({
        items: [storage],
      });

      const query = isFolderSelected ? {} : { fileId: storage?.id };
      const folderId = isFolderSelected ? storage.id : storage?.parentId;

      //dont send user into project until this issue is fixed
      const rootId = this.domainId;
      const name = this.$routeNames.STORAGE.ALL_FILES;

      const route = {
        name: name,
        params: { rootId: rootId, folderId },
        query,
      };
      //Oppgave eller skjema inne på prosjekt, hvis man linker til de på prosjektet så janker den
      //parent idn til oppgaver og skjemaer på et prosjekt er jank
      return route;
    },
    async onFileUpload(e) {
      if (!this.canUpload) return;
      const parentId = this.folderId;
      const files = await this.storageMixins_getFiles(e);
      this.$store.dispatch(`${this.storeNamespace}/uploadFiles`, {
        parentId,
        files,
      });
    },
    onDelete({ items }) {
      this.dialog.deleteConfirmation.item = items;
      this.dialog.deleteConfirmation.active = true;
    },
    async afterDeleteConfirmation({ item }) {
      await this.$store.dispatch(`${this.storeNamespace}/deleteStorageItems`, {
        items: item,
      });
    },
    onPreviewDialogClose() {
      this.previewFile({});
    },
    updateStorage({ value, item }) {
      const body = {
        ...value,
        id: item.id,
      };

      switch (item?.type) {
        case this.$constant.FOLDER:
          this.$store.dispatch(`${this.storeNamespace}/updateFolder`, body);
          break;
        case this.$constant.FILE:
          this.$store.dispatch(`${this.storeNamespace}/updateFile`, body);
          break;
        case this.$constant.FILE_RELATION:
          this.$store.dispatch(`${this.storeNamespace}/updateFile`, body);
          break;
      }
    },
    updateStorages(value) {
      const ids = this.itemsToEdit.map((item) => item.id);
      this.$store.dispatch(`${this.storeNamespace}/updateStorages`, {
        body: {
          ids,
          ...value,
        },
      });
    },
    afterCopy(items) {
      const parentId = this.dialog.copy.parentId;
      this.$store.dispatch(`${this.storeNamespace}/copyItems`, {
        body: {
          storages: items,
          parentId,
        },
        parentId,
      });
    },
    afterMove() {
      this.getStorage(this.folderId);
    },
    onAddToMerge({ items }) {
      if (!this.planRestrictionsMixins_canMergeFiles) {
        this.openUpgradePlanDialog();
        return;
      }
      this.$store.commit("storageMergeToPdf/setSelectedFiles", [
        ...this.selectedFilesToMerge,
        ...items,
      ]);
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    afterBoligmappaUpload() {
      this.getStorage(this.folderId);
    },
    onSendToSign({ items }) {
      const storage = items[0];
      if (isESignFile(storage)) {
        this.previewFile({
          fileId: storage.id,
          activeMenu: "E_SIGN",
        });
      } else {
        this.onCreateSignableDocument({ storage });
      }
    },
    async onCreateSignableDocument({ storage }) {
      this.dialog.creatingSignableDocument.active = true;
      this.$store
        .dispatch(`${this.storeNamespace}/createSignableDocument`, {
          storageId: storage.id,
          parentId: storage.parentId,
        })
        .then((newStorage) => {
          if (Object.prototype.hasOwnProperty.call(newStorage, "id")) {
            this.previewFile({
              fileId: newStorage.id,
              activeMenu: "E_SIGN",
              openAddRecipients: true,
            });
          }
        })
        .finally(() => {
          this.dialog.creatingSignableDocument.active = false;
        });
    },
    onRequestReadConfirmation({ items }) {
      const storage = items[0];
      this.previewFile({
        fileId: storage.id,
        activeMenu: "READ_CONFIRMATION",
        openAddRecipients: true,
      });
    },
    clearSelection() {
      this.$refs.dataTable.clearSelection();
    },
    beforeUnload(e) {
      if (this.shouldPreventDestruction) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    async initializeStorageManager() {
      await this.$store.dispatch(`${this.storeNamespace}/resetState`);
      this.getAcceptedFileTypes();
      this.getStorage(this.folderId);
      if (!this.wopiDiscovery) {
        this.getWopiDiscovery();
      }
    },
    handleOfficeOpen({ items }) {
      const item = items[0];

      if (!this.planRestrictionsMixins_canUseOfficeOnline) {
        setTimeout(() => {
          this.openUpgradePlanDialog();
        }, 100);
        return;
      }

      const extension = item.extension.split(".").pop();
      const action = this.getWopiApplicationOpenAction(extension);
      const route = this.$router.resolve({
        name: this.$routeNames.OFFICE_ONLINE.ROOT,
        params: { fileId: item.id },
        query: { actionUrl: action.url, faviconUrl: action.faviconUrl },
      });

      window.open(route.href, "_blank");
    },
  },
  created() {
    addEventListener("beforeunload", this.beforeUnload);
  },
  destroyed() {
    removeEventListener("beforeunload", this.beforeUnload);
  },
};
</script>

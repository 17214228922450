<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteForm.active"
      :item="dialog.deleteForm.item"
      :title="
        $tc('formManagement.deleteConfirmation.title', dialog?.deleteForm?.item?.length)
      "
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="formsDelete"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="forms"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noForms')"
      :getAllItems="getAllItems"
      :bulkActionConfigs="bulkActionConfigs"
      removeMutationType="forms/removeForm"
      updateMutationType="forms/updateForm"
      :statsText="statsText"
      :isStatsLoading="isStatsLoading"
      show-select
    >
      <template
        v-slot:group.header="{
          toggle,
          group,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="cell-interactable d-flex align-center justify-center"
          v-ripple
        >
          <AppDefaultCheckboxIcon
            :isIndeterminate="isGroupIndeterminate"
            :isSelected="isGroupSelected"
          />
        </td>
        <td :colspan="headers.length - 6">
          <div class="d-flex align-center">
            <v-btn
              class="mr-4"
              icon
              @click.stop="toggle"
            >
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="5"></td>
      </template>
      <template v-slot:item="{ item, headers, select, isSelected }">
        <AppFormTableItem
          :form="item"
          :selectedFormId="formId"
          :headers="headers"
          :tableModel="tableModel"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :isActiveItem="isActiveItem(item)"
          :dataFields="filtersMixins_dataFields"
          @cell:click="handleInteractableClick"
          @menu:click="openContextMenu"
          @name:click="onFormNameClick"
          @signature:click="onSignatureClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppCustomStatusPickerMenu
      ref="statusMenu"
      @submit="onFormsUpdate"
      model="form"
    />
    <AppUserPickerMenu
      ref="responsibleMenu"
      @submit="(id) => onFormsUpdate({ ownerId: id })"
    />
    <AppProjectPickerMenu
      ref="projectMenu"
      @submit="onFormsUpdate"
    />
    <AppDatePickerMenu
      ref="dueDateMenu"
      @submit="({ date }) => onFormsUpdate({ dueDate: date })"
    />
    <AppTaskPickerMenu
      ref="taskMenu"
      @submit="onFormsUpdate"
      :queryParams="{ projectId: $route?.params?.projectId }"
      :data="{ projectId: $route?.params?.projectId }"
    />
    <AppFormCategoryPickerMenu
      ref="categoryMenu"
      @submit="onFormsUpdate"
    />
    <AppIsActivePickerMenu
      ref="isSignableMenu"
      @submit="({ isActive }) => onFormsUpdate({ isSignable: isActive })"
    />
    <AppAccessLevelPickerMenu
      ref="isPrivateMenu"
      :allowedLevels="['PUBLIC', 'PRIVATE']"
      @submit="
        ({ permissionLevel }) =>
          onFormsUpdate({ isPrivate: permissionLevel === 'PRIVATE' })
      "
    />
    <AppCopyItemsDialog
      v-model="dialog.copyDialog.active"
      :inItems="dialog.copyDialog.items"
      type="FORM"
      @submit="onCopy"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { createTableHeaders } from "@/helpers/util";
import { getAllForms } from "@/services/form/allForms";

export default {
  mixins: [groupByMixins],

  props: {
    forms: Array,
    serverItemsLength: Number,
    isLoading: Boolean,
    formId: String,
    tableModel: String,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      contextMenuActiveItem: null,
      dialog: {
        deleteForm: {
          active: false,
          item: null,
        },
        copyDialog: {
          active: false,
          items: null,
        },
      },
      isContextMenuOpen: false,
      contextMenuActiveItem: null,
      itemsToEdit: null,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },

    actionConfigs() {
      const {
        FILL_OUT_FORM,
        OVERVIEW,
        COPY,
        SEND_TO_SIGN,
        COPY_URL,
        DELETE,
        EDIT_STATUS,
        EDIT_RESPONSIBLE,
        EDIT_DUE_DATE,
        EDIT_TASK,
        EDIT_PROJECT,
        EDIT_CATEGORY,
        EDIT_IS_SIGNABLE,
        EDIT_PERMISSION_LEVEL,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: FILL_OUT_FORM,
          on: { click: this.onFormFillOutClick },
        },
        {
          key: OVERVIEW,
          on: { click: this.onFormDetailsClick },
        },
        {
          key: COPY,
          on: { click: this.onCopyClick },
        },
        {
          key: SEND_TO_SIGN,
          props: {
            labelKeyParams: { isSent: this.isSentToSign },
          },
          on: { click: this.onSendToSignClick },
        },
        {
          key: EDIT_STATUS,
          on: {
            click: _openEditMenu("statusMenu"),
          },
        },
        {
          key: COPY_URL,
          on: { click: this.onCopyUrl },
        },
        {
          key: EDIT_RESPONSIBLE,
          on: { click: _openEditMenu("responsibleMenu") },
        },
        {
          key: EDIT_DUE_DATE,
          on: { click: _openEditMenu("dueDateMenu") },
        },
        {
          key: EDIT_TASK,
          on: { click: _openEditMenu("taskMenu") },
        },
        {
          key: EDIT_PROJECT,
          on: { click: _openEditMenu("projectMenu") },
        },
        {
          key: EDIT_CATEGORY,
          on: { click: _openEditMenu("categoryMenu") },
        },
        {
          key: EDIT_IS_SIGNABLE,
          on: { click: _openEditMenu("isSignableMenu") },
        },
        {
          key: EDIT_PERMISSION_LEVEL,
          on: { click: _openEditMenu("isPrivateMenu") },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) => !this.canEditForms({ items }),
          },
          on: { click: this.onFormDelete },
          divider: true,
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.FORM_ACTIONS,
      });

      return config;
    },
    isSentToSign() {
      return !!this.contextMenuActiveItem?.signatureSession;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.filtersMixins_dataFields.STATUS,
          menuRef: "statusMenu",
        },
        {
          preset: "NAME",
          text: this.$t("formManagement.list.formName"),
          value: this.filtersMixins_dataFields.NAME,
        },
        {
          text: this.$t("formManagement.list.signatureStatus"),
          value: this.filtersMixins_dataFields.SIGNATURE_STATUS,
          interactable: true,
        },
        {
          text: this.$t("common.progress"),
          value: this.filtersMixins_dataFields.PROGRESS,
        },
        {
          text: this.$t("formManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          interactable: true,
          menuRef: "responsibleMenu",
        },
        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          interactable: true,
          menuRef: "projectMenu",
          access: !this.projectId,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.belongsToTask"),
          ),
          value: this.filtersMixins_dataFields.TASK,
          interactable: true,
          menuRef: "taskMenu",
        },
        {
          text: this.$t("common.category"),
          value: this.filtersMixins_dataFields.CATEGORY,
          interactable: true,
          menuRef: "categoryMenu",
        },
        {
          preset: "DATE",
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          menuRef: "dueDateMenu",
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
  },
  methods: {
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    async getAllItems() {
      return await getAllForms({
        filters: {
          ...this.filtersMixins_filters,
          ...(this.projectId ? { projectId: this.projectId } : {}),
        },
      });
    },
    canEditForms({ items }) {
      return items?.every((item) => item?.permissions?.canEdit);
    },
    onCopyUrl({ items }) {
      const form = items[0];
      const formUrl = this.$router.resolve({
        name: this.$routeNames.FORM_VIEW.ROOT,
        params: { formId: form?.id },
      }).href;

      const fullPath = `${window.location.origin}${formUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem === item;
    },
    isActiveItem(item) {
      return item.id === this.formId;
    },
    onCopyClick({ items }) {
      this.dialog.copyDialog.active = true;
      this.dialog.copyDialog.items = items;
    },
    onCopy(e) {
      this.$emit("forms:copy", { forms: e });
    },
    onSignatureClick({ form }) {
      this.$emit("signature:click", { form });
    },
    onSendToSignClick({ items }) {
      const form = items[0];
      this.$emit("form:sendToSign", { form });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.CATEGORY)) {
        return this.translatedCategory(group);
      }

      return group;
    },
    onStatusChange({ statusId }) {
      const body = { statusId };

      this.onFormUpdate({ body, form: this.itemsToEdit[0] });
    },
    onStatusClick(e) {
      const { items } = e;
      this.$refs.statusMenu.open(e);
      this.itemsToEdit = items;
    },
    translatedCategory(key) {
      return this.$te(`formManagement.category.${key}`)
        ? this.$t(`formManagement.category.${key}`)
        : key;
    },
    openContextMenu({ from, direction, form }) {
      this.$refs.contextMenu.open({ direction, from });
      this.$nextTick(() => {
        this.contextMenuActiveItem = form;
      });
    },
    onContextMenuChange(isOpen) {
      this.isContextMenuOpen = isOpen;
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    onFormDetailsClick({ items }) {
      const form = items[0];
      this.$emit("form:details", { item: form });
    },
    onFormNameClick({ form }) {
      this.contextMenuActiveItem = form;
      this.onFormFillOutClick({ items: [form] });
    },
    onFormFillOutClick({ items }) {
      const form = items[0];
      this.$emit("form:fillOut", { item: form });
    },
    onFormUpdate(e) {
      this.$emit("form:update", e);
    },
    onFormsUpdate(value) {
      const body = { ...value, ids: this.itemsToEdit.map((item) => item.id) };
      this.$emit("forms:update", { body });
    },
    onFormDelete({ items }) {
      this.dialog.deleteForm.active = true;
      this.dialog.deleteForm.item = items;
    },
    formsDelete({ item }) {
      this.$emit("forms:delete", { forms: item });
    },
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
  },
};
</script>

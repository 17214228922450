<template>
  <v-slide-x-reverse-transition :hide-on-leave="true">
    <div class="filters_drawer primary-box-shadow border-l">
      <v-toolbar
        class="border-b primary"
        dense
        flat
        fixed
        height="68"
      >
        <v-toolbar-title class="ui-background--text">
          {{ $t("common.filters") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          @click="close"
          icon
        >
          <v-icon color="ui-background">{{ $icons.SOLID.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="filtersMixins_isLoadingFilters"
        style="height: calc(100% - (68px + 68px))"
        class="d-flex align-center justify-center"
      >
        <AppCircularLoader />
      </div>
      <div
        v-else
        class="pa-6 overflow-y-auto"
        style="height: calc(100% - (68px + 68px))"
      >
        <template v-for="child in children">
          <component
            :is="child.is"
            :key="child.id"
            :value="filtersMixins_filterByKey(child.filterValueKey)"
            v-bind="child"
            @change="(event) => onFiltersChange({ event, child })"
          />
        </template>
      </div>
      <v-toolbar
        class="border-b"
        dense
        flat
        fixed
        height="68"
      >
        <v-toolbar-title class="w-100">
          <v-btn
            class="primary"
            :disabled="filtersMixins_isSaved"
            block
            @click="onFilterSave"
            :loading="filtersMixins_isSavingFilters"
          >
            {{ $t(`common.${filtersMixins_isSaved ? "saved" : "save"}`) }}
          </v-btn>
        </v-toolbar-title>
        <div class="pl-2"></div>
        <v-menu
          offset-y
          transition="custom-menu-transition"
          top
          nudge-top="4px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="filtersMixins_isLoadingFilters"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ $icons.REGULAR.ACTION.MENU }}</v-icon>
            </v-btn>
          </template>
          <div
            class="pa-3 ui-background d-flex flex-column"
            style="min-width: 70px"
          >
            <AppMenuItem
              :text="$t('common.resetToDefault')"
              :icon="$icons.REGULAR.ACTION.RESET"
              @click="onResetPress"
            />
            <AppMenuItem
              :text="$t('common.resetToSaved')"
              :icon="$icons.REGULAR.ACTION.RESET_TO_LAST"
              @click="onResetToSavedPress"
            />
          </div>
        </v-menu>
      </v-toolbar>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    children: Array,
    tableModel: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onFiltersChange({ event, child }) {
      this.filtersMixins_updateFilters({
        filters: { [child.filterValueKey]: event },
        dataTableOptions: this.filtersMixins_dataTableOptions,
      });
    },
    onFilterSave() {
      this.filtersMixins_saveFilter();
    },
    onResetPress() {
      this.filtersMixins_resetToDefault();
    },
    onResetToSavedPress() {
      this.filtersMixins_resetToSaved();
    },
  },
};
</script>

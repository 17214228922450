<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto d-flex justify-center align-center"
  >
    <template v-if="!isSendingToSign">
      <div class="text-center pa-2">
        <div class="text-h5 font-weight-bold mb-2">
          {{ $t("fileManagement.preview.navMenu.e-signature.DRAFT.title") }}
        </div>
        <div class="text-body-1 mb-7">
          {{ $t("fileManagement.preview.navMenu.e-signature.DRAFT.subTitle") }}
        </div>
        <v-btn
          depressed
          color="primary"
          @click="openEmbeddedRequestDialog"
        >
          {{ $t("fileManagement.preview.navMenu.e-signature.DRAFT.button") }}
        </v-btn>
      </div>
    </template>
    <template v-else>
      <AppCenterLoader
        v-model="isSendingToSign"
        :title="$t('fileManagement.preview.navMenu.e-signature.DRAFT.sendingToSign')"
        :height="'100%'"
      ></AppCenterLoader>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    isSendingToSign: Boolean,
  },
  computed: {
    ...mapState("storageESign", {
      storage: (state) => state.storage,
    }),
    embeddedRequestUrl() {
      const meta = this.storage?.meta?.find((m) => m.field === "SIGN_EMBEDDED_URL");
      return meta?.value;
    },
  },
  methods: {
    openEmbeddedRequestDialog() {
      this.$emit("openEmbeddedRequestDialog", this.embeddedRequestUrl);
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-subtitle
        class="pt-1"
        v-if="subtitle"
      >
        {{ subtitle }}
      </v-card-subtitle>
      <v-card-text v-if="!skipValidation">
        <v-form
          ref="deleteForm"
          v-model="form.valid"
          v-on:submit.prevent
          @submit="onDelete"
        >
          <div class="p-relative">
            <div
              class="label--small required-asterisk cursor-pointer d-flex align-center"
              style="display: inline-block"
              @mousedown="onValidatorTextClick"
            >
              {{ validatorText }}
              <v-icon
                small
                class="mx-1"
                color="rgba(0, 0, 0, 0.6)"
              >
                {{ $icons.LIGHT.ACTION.COPY }}
              </v-icon>
            </div>
            <AppDefaultTextField
              v-model="form.delete.value"
              :rules="form.delete.rules"
              autofocus
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onDelete"
          @cancel="closeDialog"
          confirmColor="error"
          :confirmText="deleteBtnText || $t('common.delete')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    title: {
      type: String,
      default: "Delete",
    },
    subtitle: {
      type: String,
      default: null,
    },
    validator: {
      type: String,
      default: "delete",
    },
    validatorText: {
      type: String,
      default: `Write "delete" to delete something`,
    },
    deleteBtnText: String,
    item: [Object, Array],
    event: String,
    skipValidation: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      form: {
        valid: false,
        delete: {
          value: "",
          rules: [
            (v) => !!v || this.$t("common.required"),
            (v) => v === this.validator || this.$t("common.invalid"),
          ],
        },
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("dialog:change", val);
      },
    },
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.resetDialog();
        }
      },
    },
  },
  methods: {
    onValidatorTextClick() {
      this.form.delete.value = this.validator;
    },
    closeDialog() {
      this.$emit("dialog:change", false);
    },
    resetDialog() {
      if (this.$refs.deleteForm) {
        this.$refs.deleteForm.reset();
      }
    },
    isValid() {
      const isValid = this.$refs?.deleteForm?.validate() || false;
      return isValid;
    },
    onDelete() {
      const isValid = this.isValid();
      if (isValid || this.skipValidation) {
        this.$emit("delete", { item: this.item, event: this.event });
        this.closeDialog();
      }
    },
  },
};
</script>

<template>
  <AppDefaultTooltip
    top
    :disabled="!tooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="mr-2 d-flex align-center"
        v-on="on"
        v-bind="attrs"
      >
        <v-icon
          v-bind:[iconSize]="true"
          class="pr-1"
        >
          {{ icon }}
        </v-icon>
        <span class="caption text--secondary">
          {{ text }}
        </span>
      </div>
    </template>
    <span class="break-word">
      {{ tooltip }}
    </span>
  </AppDefaultTooltip>
</template>

<script>
export default {
  props: {
    text: String,
    icon: String,
    iconSize: {
      type: String,
      default: "x-small",
    },
    tooltip: String,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-dialog
    v-model="localDialog"
    maxWidth="520"
    scrollable
  >
    <v-card class="d-flex flex-column align-center pa-10">
      <div class="dialog__close-btn">
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <div
        class="mb-5"
        v-if="image"
      >
        <v-img
          :src="image"
          alt="Gripr"
        />
      </div>
      <div class="text-h5 font-weight-bold text-center mb-4">
        {{ title || $t("common.error.title") }}
      </div>
      <div class="text-body-1 text-center mb-5">
        {{ subTitle || $t("common.error.subTitle") }}
      </div>
      <v-btn
        color="primary"
        depressed
        @click="onRetry"
      >
        {{ retryBtnText || $t("common.error.retry") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import errorLaptop from "../../../assets/error-laptop.svg";
export default {
  props: {
    dialog: Boolean,
    image: {
      type: String,
      default: errorLaptop,
    },
    title: String,
    subTitle: String,
    retryBtnText: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onRetry() {
      this.$emit("retry");
      this.localDialog = false;
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="label--small"
      :class="required ? 'required-asterisk' : ''"
    >
      {{ label | capitalize }}
    </div>
    <v-menu
      ref="menu"
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
      :disabled="disabled"
      @input="onInput"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-layout
          v-on="on"
          v-bind="attrs"
        >
          <AppDefaultTextField
            :disabled="disabled"
            :class="disabled ? '' : 'date-field-cursor-pointer'"
            v-model="localInputDate"
            :append-icon="$icons.LIGHT.COMMON.CALENDAR_DAY"
            :label="placeholder"
            :clearable="clearable"
            :hide-details="hideDetails"
            :rules="required ? $rules.REQUIRED : []"
            name="date"
            readonly
          />
        </v-layout>
      </template>
      <div>
        <v-date-picker
          v-model="localPickerDate"
          no-title
          show-week
          locale-first-day-of-year="4"
          color="primary"
          :first-day-of-week="dateMixins_firstDayOfWeek"
          :picker-date.sync="localDefaultPickerDate"
          :min="min"
          :max="max"
          @input="dateMenu = false"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
import * as moment from "moment";
import { dateMixins } from "@/helpers/mixins";

export default {
  mixins: [dateMixins],

  props: {
    label: String,
    date: String,
    placeholder: String,
    hideDetails: Boolean,
    required: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    min: String,
    max: String,
    defaultPickerDate: String,
  },
  model: {
    prop: "date",
    event: "date:change",
  },
  data() {
    return {
      dateMenu: false,
      localDefaultPickerDate: null,
    };
  },
  computed: {
    localPickerDate: {
      get() {
        const date = this.date ? this.formatPickerDate(this.date) : null;
        return date;
      },
      set(value) {
        this.$emit("date:change", value);
      },
    },
    localInputDate: {
      get() {
        return this.localPickerDate ? this.formatInputDate(this.localPickerDate) : "";
      },
      set(value) {
        this.$emit("date:change", value);
      },
    },
  },
  methods: {
    formatInputDate(date) {
      return moment(new Date(date)).format("DD.MM.YYYY");
    },
    formatPickerDate(date) {
      return moment(new Date(date)).format("YYYY-MM-DD");
    },
    onInput(value) {
      if (!value) return;

      const defaultDate = this.date || this.defaultPickerDate;
      this.localDefaultPickerDate = defaultDate
        ? this.formatPickerDate(defaultDate)
        : null;
    },
  },
};
</script>

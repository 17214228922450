<template>
  <div class="p-relative">
    <div class="label--small">
      {{ $t("common.status") | capitalize }}
    </div>
    <AppDefaultSelect
      v-model="localValue"
      :items="statuses"
      multiple
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-on="on"
          v-bind="attrs"
          ripple
          @mousedown.prevent
        >
          <v-list-item-action>
            <AppDefaultCheckboxIcon :isSelected="attrs.inputValue" />
          </v-list-item-action>
          <v-list-item-content>
            <div>
              <v-icon
                class="mr-2"
                :color="statusMixins_statusColor(item.value, false, statuses)"
              >
                {{ $icons.SOLID.ACTION.STATUS }}
              </v-icon>
              {{ statusMixins_statusTranslation(item.value, statuses) }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>
    </AppDefaultSelect>
  </div>
</template>

<script>
import { statusMixins } from "@/helpers/mixins";

export default {
  props: {
    value: Array,
    labelKey: String,
    timeEntries: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    statuses() {
      return this.statusMixins_timeEntriesStatusItems;
    },
  },
  mixins: [statusMixins],
};
</script>

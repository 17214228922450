<template>
  <div>
    <AppDefaultAutocomplete
      v-model="localValue"
      v-bind="$attrs"
      v-on="$listeners"
      item-text="name"
      item-value="id"
      :noDataText="$t(`${translationPath}.noLabels`)"
      :items="labels"
      :loading="isLoading"
    >
      <template v-slot:item="{ item }">
        <div>{{ item.name }}</div>
        <v-spacer />
        <v-btn
          icon
          @click.stop="onEditLabel(item)"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.EDIT }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:append-item>
        <AppSelectAppendBtn @click="onAddLabel">
          {{ $t(`${translationPath}.createLabel`) }}
        </AppSelectAppendBtn>
      </template>
    </AppDefaultAutocomplete>
    <AppLabelDialog
      v-model="addLabelDialog.active"
      :type="labelType"
      :title="$t(`${translationPath}.addLabelDialog.title`)"
      :confirmText="$t('common.create')"
      @confirm="afterLabelCreate"
    />
    <AppLabelDialog
      v-model="updateLabelDialog.active"
      :data="updateLabelDialog.data"
      :type="labelType"
      :title="$t(`${translationPath}.updateLabelDialog.title`)"
      :confirmText="$t('common.save')"
      :deleteTitle="$t(`${translationPath}.updateLabelDialog.deleteLabelDialog.title`)"
      :deleteSubtitle="
        $t(`${translationPath}.updateLabelDialog.deleteLabelDialog.subTitle`)
      "
    />
  </div>
</template>

<script>
import { labelMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [labelMixins],
  props: {
    value: [String, Number, Object],
    labelType: [String],
  },
  data() {
    return {
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    ...mapGetters("label", {
      labelsByType: "labelsByType",
      isLoadingByType: "isLoadingByType",
    }),
    labels() {
      return this.labelsByType(this.labelType);
    },
    isLoading() {
      return this.isLoadingByType(this.labelType);
    },
    translationPath() {
      return this.labelMixins_translationPath(this.labelType);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    getLabels() {
      this.$store.dispatch("label/getLabels", {
        labelType: this.labelType,
      });
    },
    onAddLabel() {
      this.addLabelDialog.active = true;
    },
    afterLabelCreate({ label }) {
      if (!label) return;
      this.localValue = label.id;
    },
    onEditLabel(label) {
      this.updateLabelDialog.active = true;
      this.updateLabelDialog.data = label;
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>

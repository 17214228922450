<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <div class="pb-4">
          <span class="text-body-2">
            {{ subTitle }}
          </span>
        </div>

        <div class="label--small required-asterisk">
          {{ textareaLabel }}
        </div>
        <AppDefaultTextarea v-model="comment" />

        <v-alert
          v-if="alertText"
          type="warning"
          outlined
          text
          dense
        >
          {{ alertText }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="localDialog = false"
          :disabled="!isValidComment"
          :confirmText="confirmText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    title: String,
    subTitle: String,
    textareaLabel: String,
    alertText: String,
    confirmText: String,
    data: undefined,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isValidComment() {
      return this.comment && this.comment?.trim(" ");
    },
  },
  methods: {
    onConfirm() {
      if (!this.isValidComment) return;
      this.$emit("confirm", { comment: this.comment, data: this.data });
      this.comment = "";
      this.localDialog = false;
    },
  },
};
</script>

<template>
  <v-list
    dense
    class="navigation-list"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-list>
</template>

<script>
export default {};
</script>

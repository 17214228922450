<template>
  <div class="d-flex flex-column mw-650px">
    <v-img
      class="mb-10"
      contain
      max-height="200"
      src="../../../../assets/import_success.png"
      alt="Importing"
    />
    <div class="text-h4 pb-2 text-center font-weight-bold">
      {{ $t(`integration.${importType}.${state}.title`) }}
    </div>
    <div class="pb-10">
      <div class="text-center">
        {{ $tc(`integration.${importType}.${state}.subTitle`) }}
      </div>
      <AppInfoAlert
        v-if="$te(`integration.${importType}.${state}.inviteUsersInfoAlert`)"
        class="mt-4"
      >
        {{ $t(`integration.${importType}.${state}.inviteUsersInfoAlert`) }}
      </AppInfoAlert>
    </div>
    <div class="text-center">
      <v-btn
        color="background"
        depressed
        @click="close"
      >
        {{ $t(`integration.common.closeWindow`) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: String,
    importType: String,
  },
  methods: {
    close() {
      this.$emit("close:click");
    },
  },
};
</script>

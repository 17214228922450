<template>
  <div class="d-flex w-100 align-center pt-2">
    <span class="font-weight-bold">{{ text }}</span>
    <AppStonlyGuideBtn
      v-if="guideId"
      class="ml-2"
      :guideId="guideId"
    />
    <v-spacer />
    <AppDefaultSwitch
      :input-value="active"
      @change="onSwitchClick"
      class="ma-0 pa-0"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    guideId: String,
    active: Boolean,
  },
  methods: {
    onSwitchClick(e) {
      this.$emit("switch:toggle", e);
    },
  },
  mounted() {
    this.active;
  },
};
</script>

<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title>
        {{ $t("fileManagement.preview.navMenu.fileVersionHistory.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />

    <template v-if="storage">
      <AppDeleteConfirmationDialog
        ref="deleteConfirmation"
        v-model="dialog.restoreVersion.active"
        :item="dialog.restoreVersion.item"
        :title="
          $t(
            'fileManagement.preview.navMenu.fileVersionHistory.restoreConfirmationDialog.title',
          )
        "
        :subtitle="
          $t(
            'fileManagement.preview.navMenu.fileVersionHistory.restoreConfirmationDialog.subTitle',
          )
        "
        :deleteBtnText="$t('fileManagement.preview.navMenu.fileVersionHistory.restore')"
        skip-validation
        @delete="restoreVersion"
      />
      <div
        style="height: calc(100% - 68px)"
        class="overflow-y-auto"
      >
        <v-timeline
          align-top
          dense
          reverse
          class="ml-6"
        >
          <v-timeline-item
            v-for="version in versions"
            :key="version.id"
            :color="versionBackgroundColor(version.version)"
            fill-dot
            large
          >
            <template v-slot:icon>
              <span
                class="text-body-2 font-weight-bold"
                :class="versionTextColor(version.version)"
              >
                v{{ version.version }}
              </span>
            </template>

            <v-card
              elevation="0"
              class="border-a-2"
              :color="versionBackgroundColor(version.version)"
            >
              <v-card-title
                class="text-h6"
                :class="versionTextColor(version.version)"
              >
                {{
                  version.version === currentFileVersion
                    ? $t("fileManagement.preview.navMenu.fileVersionHistory.current")
                    : $t("fileManagement.preview.navMenu.fileVersionHistory.version", {
                        version: version.version,
                      })
                }}
              </v-card-title>

              <v-card-text class="white text--primary">
                <div class="pt-4">
                  <AppUserAvatar
                    :user="version.changedBy"
                    showName
                    :truncate="23"
                  />
                  <div class="text-body-2 pt-2">
                    {{ formatDate(version.changedAt) }}
                  </div>
                  <div class="pt-2 d-flex flex-column">
                    <v-btn
                      :disabled="isActiveVersion(version.version)"
                      depressed
                      small
                      block
                      color="secondary"
                      class="text-transform-none"
                      @click="setPreviewVersion(version.version)"
                    >
                      {{ $t("fileManagement.preview.navMenu.fileVersionHistory.view") }}
                    </v-btn>
                    <v-expand-transition>
                      <div
                        v-if="
                          isActiveVersion(version.version) &&
                          version.version !== currentFileVersion
                        "
                      >
                        <v-btn
                          depressed
                          block
                          small
                          color="background"
                          class="text-transform-none mt-2"
                          @click="onRestoreVersion(version)"
                        >
                          {{
                            $t(
                              "fileManagement.preview.navMenu.fileVersionHistory.restore",
                            )
                          }}
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </template>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  props: {
    storage: Object,
    previewVersion: String,
  },
  data() {
    return {
      dialog: {
        restoreVersion: {
          active: false,
          item: null,
        },
      },
    };
  },
  computed: {
    isLoading() {
      return !this.storage;
    },
    currentFileVersion() {
      return this.storage?.fileVersion;
    },
    versionInPreview() {
      const version = this.previewVersion;
      const activeVersion = version ? version : this.currentFileVersion;
      const isValidVersion = this.validVersions.includes(activeVersion);
      return isValidVersion ? activeVersion : this.currentFileVersion;
    },
    versions() {
      const fileVersionHistory = [...(this.storage?.fileVersionHistory || [])].sort(
        (a, b) => b.version - a.version,
      );
      const currentVersion = {
        id: this.storage?.id,
        size: this.storage?.size,
        mime: this.storage?.mime,
        extension: this.storage?.extension,
        version: this.storage?.fileVersion,
        changedAt: this.storage?.fileVersionChangedAt,
        changedBy: this.storage?.fileVersionChangedBy,
      };
      return [currentVersion, ...fileVersionHistory];
    },
    validVersions() {
      return this.versions.map((version) => version.version);
    },
  },
  methods: {
    isActiveVersion(version) {
      return version === this.versionInPreview;
    },
    formatDate(date) {
      return moment(date).fromNow();
    },
    versionTextColor(version) {
      return version === this.versionInPreview ? "white--text" : "black--text";
    },
    versionBackgroundColor(version) {
      return version === this.versionInPreview ? "secondary" : "background";
    },
    setPreviewVersion(version) {
      if (version === this.versionInPreview) return;
      const versionToSet = version === this.currentFileVersion ? undefined : version;
      this.$emit("fileVersion:changePreview", versionToSet);
    },
    onRestoreVersion(version) {
      this.dialog.restoreVersion.active = true;
      this.dialog.restoreVersion.item = version;
    },
    restoreVersion() {
      this.$emit("fileVersion:restore", { version: this.dialog.restoreVersion.item });
    },
  },
};
</script>

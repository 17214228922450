<template>
  <span
    class="drawer-menu__toggle-button d-flex align-center justify-center cursor-pointer"
    :class="{
      'drawer-menu__toggle-button--mini': isMini,
      'drawer-menu__toggle-button--full': !isMini,
    }"
    @click="onDrawerVariantToggle"
  >
    <div
      class="d-flex justify-center align-center"
      style="width: 2rem"
    >
      <div
        class="d-flex flex-column justify-center align-center pr-1"
        style="width: 1.5rem; height: 1.5rem"
      >
        <div
          :class="[
            'drawer-menu__toggle-handle-1',
            isMini
              ? 'drawer-menu__toggle-handle-1--mini'
              : 'drawer-menu__toggle-handle-1--full',
          ]"
        ></div>
        <div
          :class="[
            'drawer-menu__toggle-handle-2',
            isMini
              ? 'drawer-menu__toggle-handle-2--mini'
              : 'drawer-menu__toggle-handle-2--full',
          ]"
        ></div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      startX: 0,
      startY: 0,
    };
  },
  computed: {
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
  },
  methods: {
    onDrawerVariantToggle() {
      this.$store.commit("appDrawer/setIsDrawerMiniVariant", !this.isMini);
    },
  },
};
</script>

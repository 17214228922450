<template>
  <div>
    <DropArea
      ref="dropArea"
      :isDraggingOver="isDraggingOver"
      :hasFiles="hasSelectedItems"
      :loading="loading"
      @click="openFileExplorer"
      @dragOver="onDragOver"
      @dragLeave="onDragLeave"
      @fileDrop="onFileDrop"
    >
      <FileList
        v-if="hasSelectedItems"
        ref="fileList"
        :items="selectedItems"
        :maxHeight="maxHeight"
        :disableNameEdit="disableNameEdit"
        @removeItem="removeItem"
        @updateItemName="updateItemName"
        :isDraggingOver="isDraggingOver"
      />
      <EmptyState
        v-else
        :loading="loading"
      />
    </DropArea>
    <AddFileBtn
      v-if="isModeFileExplorer"
      translationKey="common.selectFilesFromFileExplorer"
      @click="openFileExplorer"
    />
    <AddFileBtn
      v-if="isModeStorageManager"
      translationKey="common.selectFilesFromGripr"
      @click="openStorageManager"
    />
    <FileInput
      v-if="isModeFileExplorer"
      ref="uploader"
      :acceptedTypes="acceptedTypes"
      @fileSelect="afterFileSelect"
    />
    <AppStorageManagerDialog
      v-if="isModeStorageManager"
      v-model="dialogs.storageManager"
      @confirm="addItems"
    />
  </div>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";
import DropArea from "./DropArea.vue";
import FileList from "./FileList.vue";
import EmptyState from "./EmptyState.vue";
import AddFileBtn from "./AddFileBtn.vue";
import FileInput from "./FileInput.vue";

export default {
  components: {
    DropArea,
    FileList,
    EmptyState,
    AddFileBtn,
    FileInput,
  },
  mixins: [storageMixins],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    acceptedTypes: {
      type: String,
      default: "",
    },
    loading: Boolean,
    modes: {
      type: Array,
      default: () => ["FILE_EXPLORER", "STORAGE_MANAGER"],
    },
    maxHeight: String,
    disableNameEdit: Boolean,
  },
  data() {
    return {
      dialogs: {
        storageManager: false,
      },
      isDraggingOver: false,
    };
  },
  watch: {
    selectedItems: {
      handler(newItems, oldItems) {
        if (newItems.length > oldItems.length) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(newItems) {
        this.$emit("change", newItems);
      },
    },
    isModeStorageManager() {
      return this.modes.includes("STORAGE_MANAGER");
    },
    isModeFileExplorer() {
      return this.modes.includes("FILE_EXPLORER");
    },
    hasSelectedItems() {
      return this.selectedItems.length > 0;
    },
  },
  methods: {
    onDragOver(event) {
      if (!event.dataTransfer?.types?.includes("Files")) return;
      this.isDraggingOver = true;
    },
    onDragLeave() {
      this.isDraggingOver = false;
    },
    onFileDrop(event) {
      this.isDraggingOver = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const acceptedFiles = [];
        const rejectedFileTypes = [];

        Array.from(files).forEach((file) => {
          if (this.isFileTypeAccepted(file)) {
            acceptedFiles.push(file);
          } else {
            rejectedFileTypes.push(file.type || "unknown");
          }
        });

        if (acceptedFiles.length > 0) {
          this.addItems(acceptedFiles);
        }

        if (rejectedFileTypes.length > 0) {
          this.showRejectedFilesError(rejectedFileTypes);
        }
      }
    },
    isFileTypeAccepted(file) {
      if (!this.acceptedTypes) return true;
      const fileType = file.type || "";
      const fileExtension = "." + file.name.split(".").pop().toLowerCase();
      return this.acceptedTypes
        .split(",")
        .some((type) => type.trim() === fileType || type.trim() === fileExtension);
    },
    showRejectedFilesError(rejectedTypes) {
      const uniqueRejectedTypes = [...new Set(rejectedTypes)];
      const errorMessage = this.$tc(
        "fileManagement.fileExplorer.uploadRejectionTypes",
        uniqueRejectedTypes.length,
        {
          types: uniqueRejectedTypes
            .map(this.storageMixins_getReadableFileType)
            .join(", "),
        },
      );
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: errorMessage,
        color: "error",
      });
    },
    removeItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    },
    openFileExplorer() {
      this.$refs.uploader.$el.click();
    },
    openStorageManager() {
      this.dialogs.storageManager = true;
    },
    async afterFileSelect(fileList) {
      const files = Array.from(fileList);
      const convertedFiles = await this.storageMixins_filesConvetion(files);
      this.addItems(convertedFiles);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.fileList.scrollToBottom();
      });
    },
    addItems(newItems) {
      const itemsWithSource = newItems.map((item) => {
        if (item instanceof File) {
          //spread syntax not work on File because its a specialized blob
          item.source = "FILE";
          return item;
        } else {
          item.source = "STORAGE";
          return item;
        }
      });

      this.selectedItems.push(...itemsWithSource);
    },
    updateItemName({ item, newName }) {
      const index = this.selectedItems.findIndex((i) => i === item);
      if (index !== -1) {
        let updatedItem;
        if (item.source === "FILE") {
          updatedItem = this.renameFileWithProperties(item, newName);
        } else if (item.source === "STORAGE") {
          updatedItem = { ...item, name: newName };
        }

        this.selectedItems[index] = updatedItem;
      }
    },
    renameFileWithProperties(oldFile, newFileName) {
      const newFile = new File([oldFile], newFileName, {
        type: oldFile.type,
        lastModified: oldFile.lastModified,
      });

      for (let key in oldFile) {
        if (oldFile.hasOwnProperty(key) && !newFile.hasOwnProperty(key)) {
          newFile[key] = oldFile[key];
        }
      }

      return newFile;
    },
  },
};
</script>

<style scoped lang="scss">
.drop-area--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
}
</style>

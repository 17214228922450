<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto"
  >
    <div class="d-flex flex-column pa-4">
      <div class="mb-4">
        <div class="mb-1 d-flex">
          <div class="font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.readConfirmation.IN_PROGRESS.title") }}
          </div>
          <v-spacer />

          <AppColorChip
            style="max-height: 24px"
            :text="statusChipText()"
            color="warning--text"
          />
        </div>
        <div>
          {{ createdDate }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{ $t("fileManagement.preview.navMenu.readConfirmation.IN_PROGRESS.sentBy") }}
        </div>
        <AppUserAvatar
          :user="sender"
          :truncate="30"
          show-name
        />
      </div>

      <div class="font-weight-bold body-2">
        {{ $t("fileManagement.preview.navMenu.readConfirmation.IN_PROGRESS.recipients") }}
      </div>

      <div>
        <v-divider class="my-2" />
        <div
          v-for="(recipient, index) of recipients"
          :key="index"
        >
          <div class="d-flex flex-column">
            <div class="mb-1">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.name") }}</span>
                <v-spacer />
                <AppColorChip
                  :text="recipientStatusText(recipient)"
                  :color="recipientStatusColor(recipient.status)"
                />
              </div>
              <AppTextTruncateValue
                :disabled="recipient.name.length <= truncateLength"
                :text="recipient.name"
                :truncate="truncateLength"
              />
              <v-spacer />
            </div>

            <div>
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.email") }}</span>
              </div>
              <a
                class="text-decoration-none primary-link--text"
                :href="`mailto:${recipient.email}`"
                target="_blank"
              >
                <AppTextTruncateValue
                  :disabled="recipient.email.length <= truncateLength"
                  :text="recipient.email"
                  :truncate="truncateLength"
                />
              </a>
            </div>

            <div v-if="recipient.declineComment">
              <div class="d-flex mt-1">
                <span class="text-caption">
                  {{
                    $t(
                      "fileManagement.preview.navMenu.readConfirmation.IN_PROGRESS.declineComment",
                    )
                  }}
                </span>
              </div>
              <span>{{ recipient.declineComment }}</span>
            </div>
            <div v-else-if="recipient.comment">
              <div class="d-flex mt-1">
                <span class="text-caption">{{ $t("common.comment") | capitalize }}</span>
              </div>
              <span>{{ recipient.comment }}</span>
            </div>
          </div>
          <v-divider class="my-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      truncateLength: 35,
    };
  },
  props: {
    actionRequest: Object,
  },
  computed: {
    sender() {
      return this.actionRequest?.user || null;
    },
    recipients() {
      return this.actionRequest?.recipients || [];
    },
    createdDate() {
      if (!this.actionRequest.createdAt) return "";
      return moment(this.actionRequest.createdAt).format("DD.MM.YYYY HH:mm");
    },
    allSignersThatHasCompleted() {
      return this.recipients.filter((recipient) => recipient.status === "COMPLETED");
    },
    completedCount() {
      return this.allSignersThatHasCompleted.length;
    },
  },
  methods: {
    showReminder(recipient) {
      if (recipient.status === "COMPLETED") return false;
      return true;
    },
    sendReminder(recipient) {
      if (!this.showReminder(recipient)) return;
      this.$store.dispatch("inProgressDocumentProperties/sendReminder", {
        storageId: this.storageId,
        email: recipient.email,
      });
    },
    statusChipText() {
      const count = `${this.completedCount}/${this.recipients.length}`;
      const translationText = this.$t("actionRequest.status.COMPLETED");
      const inProgressText = `${count} ${translationText}`;

      return inProgressText;
    },
    recipientStatusColor(status) {
      let color = "primary";
      if (status === "IN_PROGRESS") {
        color = "warning";
      } else if (status === "COMPLETED") {
        color = "success";
      } else if (status === "DECLINED") {
        color = "error";
      }
      return `${color}--text`;
    },
    recipientStatusText(recipient) {
      let date = "";
      if (recipient.status === "COMPLETED") {
        date = moment(recipient.signedAt).format("DD.MM.YYYY HH:mm");
      } else if (recipient.status === "DECLINED") {
        date = moment(recipient.declinedAt).format("DD.MM.YYYY HH:mm");
      }
      return `${this.$t(`actionRequest.status.${recipient.status}`)} ${date}`;
    },
  },
};
</script>

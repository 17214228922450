<template>
  <div>
    <AppPageHeader
      v-if="!hideHeader"
      class="mx-2"
      :title="title"
      large
    >
      <template v-slot:append>
        <AppWidgetButtons
          @save="save"
          @cancel="cancel"
          @enableEditMode="enableEditMode"
          background="background"
        />
      </template>
    </AppPageHeader>
    <div
      class="h-100"
      v-if="isLoadingLayout || !localLayout"
    >
      <AppWidgetsSkeletonLoaders />
    </div>

    <dashboard
      v-else
      id="dashboard"
    >
      <dash-layout
        ref="dashLayout"
        :breakpoint="localLayout.breakpoint"
        :numberOfCols="localLayout.numberOfCols"
        :debug="false"
        :key="localLayout.breakpoint"
      >
        <dash-item
          v-for="widget in localLayout.widgetItems"
          @moveStart="onTransform($event, true)"
          @resizeStart="onTransform($event, true)"
          @moveEnd="onTransform($event, false)"
          @resizeEnd="onTransform($event, false)"
          :draggable="isEditMode"
          :resizable="isEditMode"
          :key="widget.id"
          v-bind.sync="widget"
          style="user-select: unset !important"
        >
          <!-- X: {{ widget.x }} Y: {{ widget.y }} height: {{ widget.height }} width:
              {{ widget.width }} maxHeight: {{ widget.maxHeight }} maxWidth:
              {{ widget.maxWidth }} -->
          <component
            @delete:click="onDeleteClick(widget.id)"
            @item:update="onItemUpdate({ widget: widget, propsToUpdate: $event })"
            @editChart:click="onEditChartClick({ id: widget.id, props: widget.props })"
            :is="widget.component.widget"
            :templateProps="{
              //isTransforming is used to set transparency when resizing or moving widget
              isTransforming,
              isEditMode,
              id: widget.id,
              //whether the user has the required roles to use the widget
              hasRequiredRoles: widget.hasRequiredRoles,
              //add to this funcion if we implement more widgets for specific plans
              showStar: !planRestrictionsMixins_canAddWidget(widget.name),
              planCode: widget.minPlanCode,
            }"
            v-bind="widget.props"
            class="h-100"
          />
        </dash-item>
      </dash-layout>
    </dashboard>
    <AppWidgetLibraryDrawer
      v-show="isEditMode"
      @add="addWidget"
      :addedWidgets="localWidgetItems"
      :model="model"
      :modelId="modelId"
    />
    <AppConfigureWidgetsDialog
      v-model="editChart.dialog"
      :data="editChart.data"
      @save="onEditChartSave"
    />
  </div>
</template>

<script>
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import { mapState } from "vuex";
import { planRestrictionsMixins } from "@/helpers/mixins";
export default {
  mixins: [planRestrictionsMixins],
  props: {
    model: String,
    modelId: String,
    title: String,
    hideHeader: Boolean,
  },
  components: {
    Dashboard,
    DashLayout,
    DashItem,
  },

  data() {
    return {
      localLayout: null,
      isTransforming: {
        id: null,
        val: false,
      },
      maxY: null,
      maxH: null,
      maxX: null,

      editChart: {
        id: null,
        data: null,
        dialog: false,
      },
    };
  },
  watch: {
    layout: {
      handler(val) {
        this.setLocalLayout(val);
      },
      deep: true,
    },
    isEditMode: {
      handler(val) {
        if (val) {
          this.getWidgetLibrary();
        }
      },
    },
  },
  computed: {
    ...mapState("widgetLayout", {
      layout: (state) => state.widgetLayout,
      isLoadingLayout: (state) => state.isLoading,
      isSavingLayout: (state) => state.isSaving,
      isEditMode: (state) => state.isEditMode,
    }),
    localWidgetItems() {
      return this.localLayout?.widgetItems ?? [];
    },
  },
  methods: {
    onEditChartSave(props) {
      this.localLayout.widgetItems.find((item) => item.id === this.editChart.id).props =
        props;
      this.save();
    },
    save() {
      this.$store.dispatch("widgetLayout/updateWidgetLayout", {
        layout: this.localLayout,
      });
    },
    onEditChartClick({ id, props }) {
      this.editChart.id = id;
      this.editChart.data = props;
      this.editChart.dialog = true;
    },
    cancel() {
      this.setLocalLayout();
      this.disableEditMode();
    },
    disableEditMode() {
      this.setIsEditMode(false);
    },
    enableEditMode() {
      this.setIsEditMode(true);
    },
    setIsEditMode(isEditMode) {
      this.$store.commit("widgetLayout/setIsEditMode", isEditMode);
    },
    onTransform(event, val) {
      this.setIsTransform(event, val);
    },
    setIsTransform(event, val) {
      this.isTransforming.id = val ? event.id : null;
      this.isTransforming.val = val;
    },
    onDeleteClick(id) {
      const index = this.localWidgetItems.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.localLayout.widgetItems.splice(index, 1);
      }
    },
    onItemUpdate({ widget, propsToUpdate }) {
      const body = { props: { ...widget.props, ...propsToUpdate } };
      this.$store.dispatch("widgetLayout/updateWidgetLayoutItem", {
        id: widget.id,
        body,
      });
    },
    getWidgets() {
      this.$store.dispatch("widgetLayout/getWidgetLayout", {
        model: this.model,
        modelId: this.modelId,
      });
    },
    getWidgetLibrary() {
      this.$store.dispatch("widgetLibrary/getLibrary", {
        model: this.model,
        modelId: this.modelId,
      });
    },
    addWidget({ widgetLibraryItem }) {
      this.localLayout.widgetItems.unshift(widgetLibraryItem);
      this.calculateItemPositions();
    },
    setLocalLayout(val) {
      this.localLayout = JSON.parse(JSON.stringify(val ?? this.layout));
    },
    mapToDebug(widgets) {
      return widgets.map((l) => {
        return { name: l.name, x: l.x, y: l.y };
      });
    },
    calculateItemPositions() {
      const items = this.localWidgetItems;
      const numberOfCols = this.localLayout.numberOfCols;

      function hasNoPosition(value) {
        return value === null || value === undefined;
      }

      const itemsWithNoPosition = items.filter(
        (item) => hasNoPosition(item.x) || hasNoPosition(item.y),
      );

      // Define initial positions for the first new item
      let currentRow = 0;
      let currentCol = 0;

      itemsWithNoPosition.forEach((item) => {
        // Calculate the next available row and column
        while (true) {
          let canPlace = true;

          // Check if the current position is within the grid boundaries
          if (currentCol + item.width > numberOfCols) {
            currentCol = 0;
            currentRow++;
          }

          // Check if the current position is occupied
          for (let i = 0; i < item.height; i++) {
            for (let j = 0; j < item.width; j++) {
              const targetRow = currentRow + i;
              const targetCol = currentCol + j;

              const isOccupied = items.some(
                (existingItem) =>
                  existingItem.x <= targetCol &&
                  targetCol < existingItem.x + existingItem.width &&
                  existingItem.y <= targetRow &&
                  targetRow < existingItem.y + existingItem.height,
              );

              if (isOccupied) {
                canPlace = false;
                break;
              }
            }
            if (!canPlace) {
              break;
            }
          }

          // If the current position is available, update the item's position
          if (canPlace) {
            item.x = currentCol;
            item.y = currentRow;

            // Update the current column for the next iteration
            currentCol += item.width;

            // If the item doesn't fit in the current row, move to the next row
            if (currentCol >= numberOfCols) {
              currentCol = 0;
              currentRow++;
            }

            break; // Exit the while loop
          }

          // Move to the next column
          currentCol++;

          // If the end of the row is reached, move to the next row
          if (currentCol >= numberOfCols) {
            currentCol = 0;
            currentRow++;
          }
        }
      });
    },
  },
  mounted() {
    if (!this.layout || this.layout?.layoutModelId !== this.modelId) {
      this.getWidgets();
    } else {
      this.setLocalLayout();
    }
  },
  destroyed() {
    this.disableEditMode();
  },
};
</script>

<style>
.switch-center {
  display: flex;
  justify-content: center;
}
.content {
  height: 100%;
  width: 100%;
  border: 2px solid #42b983;
  border-radius: 5px;
}
</style>

<template>
  <AppDefaultAutocomplete
    ref="statusSelect"
    item-text="name"
    item-value="id"
    v-model="localValue"
    :items="statusesFormattedForSelect"
    :clearable="clearable"
    :rules="rules"
    :hideDetails="hideDetails"
    :multiple="multiple"
    :loading="isLoadingStatuses"
    :disabled="disabled"
  >
    <template
      v-if="!multiple"
      v-slot:selection="{ item }"
    >
      <v-icon
        class="mr-3"
        v-if="item.id === 'NO_STATUS'"
      >
        {{ $icons.SOLID.ACTION.NO_STATUS }}
      </v-icon>
      <v-icon
        class="mr-3"
        v-else
        :color="item.color"
      >
        {{ $icons.SOLID.ACTION.STATUS }}
      </v-icon>
      <div class="mr-1">{{ item.name }}</div>
    </template>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item
        v-on="on"
        v-bind="attrs"
      >
        <v-list-item-action v-if="multiple">
          <AppDefaultCheckboxIcon :isSelected="attrs.inputValue" />
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex">
            <v-icon
              class="mr-3"
              v-if="item.id === 'NO_STATUS'"
            >
              {{ $icons.SOLID.ACTION.NO_STATUS }}
            </v-icon>
            <v-icon
              class="mr-3"
              v-else
              :color="item.color"
            >
              {{ $icons.SOLID.ACTION.STATUS }}
            </v-icon>

            <div>
              <AppTextTruncateValue
                :text="item.name"
                :truncate="50"
              />
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </AppDefaultAutocomplete>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    value: [String, Array],
    model: String,
    rules: Array,
    hideDetails: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    shouldSetDefaultValue: Boolean,
    disabled: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusesByModel: "getStatusesByModel",
      hasFetchedStatuses: "hasFetchedStatuses",
      isLoadingStatusesForModel: "isLoadingStatusesForModel",
    }),
    isLoadingStatuses() {
      return this.isLoadingStatusesForModel(this.model);
    },
    statusesFormattedForSelect() {
      if (Object.keys(this.statuses).length === 0) return;

      const items = [];
      Object.keys(this.statuses).forEach((category) => {
        if (this.statuses[category].length > 0) {
          items.push({
            header: this.$t(`statuses.categories.${category}`).toUpperCase(),
            type: "category",
          });

          this.statuses[category].forEach((status) => {
            items.push({
              name: status.name,
              color: status.color,
              id: status.id,
              type: "status",
            });
          });
        }
      });

      if (this.multiple) {
        items.push(
          {
            header: this.$t(`statuses.noStatus`).toUpperCase(),
            type: "category",
          },
          {
            name: this.$t("statuses.noStatus"),
            id: "NO_STATUS",
            type: "status",
          },
        );
      }

      return items;
    },

    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    statuses() {
      return this.getStatusesByModel(this.model);
    },
  },
  methods: {
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: this.model });
    },
    async initializeComponent() {
      if (!this.hasFetchedStatuses(this.model)) {
        await this.getStatuses();
      }
      if (
        this.shouldSetDefaultValue &&
        !this.multiple &&
        !this.localValue &&
        this.statuses?.NOT_STARTED?.length > 0
      ) {
        this.localValue = this.statuses.NOT_STARTED[0].id;
      }
    },
  },
  async mounted() {
    this.initializeComponent();
  },
};
</script>

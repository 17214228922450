<template>
  <v-overlay
    color="background"
    v-if="value"
  >
    <v-card
      style="min-width: 250px"
      class="px-7 py-5 rounded-pill d-flex flex-column justify-center align-center"
      color="primary"
      flat
    >
      <div class="pb-1 font-weight-medium">
        {{ text }}
      </div>
      <v-progress-linear
        style="width: 96%"
        color="white"
        height="6"
        width="4"
        bottom
        indeterminate
      />
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Loading...",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    scrollable
    max-width="800px"
    min-height="90%"
  >
    <v-card height="90vh">
      <v-card-title>
        <div class="pb-6 d-flex align-center w-100">
          <div class="text-h6 font-weight-bold">
            {{ title }}
          </div>
          <v-spacer />
          <slot name="header-actions"></slot>
          <div
            v-if="!isLoadingStats"
            class="text-subtitle-2"
          >
            {{ availableItemsText }}
          </div>
        </div>
        <AppDefaultTextField
          ref="searchInput"
          autofocus
          :loading="isLoadingItems"
          class="mt-1 mr-2"
          :placeholder="searchPlaceholder"
          :append-icon="$icons.LIGHT.ACTION.SEARCH"
          hide-details
          clearable
          :value="search"
          @input="$emit('searchInput', $event)"
        />
      </v-card-title>
      <v-card-text
        class="primary-text--text"
        style="scrollbar-gutter: stable"
      >
        <slot
          name="item-list"
          :items="filteredItems"
        ></slot>
        <div
          class="w-100 d-flex align-center justify-center pt-2"
          v-if="isMoreItems"
        >
          <AppCircularLoader v-intersect="onIntersect" />
        </div>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="confirmText"
        />
        <v-spacer />
        <AppDefaultSwitch
          class="ma-0 pa-0 mr-4"
          hide-details
          :label="$t('common.showSelected')"
          v-model="showSelected"
        />
        <div class="font-weight-bold">
          {{ selectedItemsText }}
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//usage:
//for backend pagination and filters, check implementation of AppSelectProductsDialog
//for full frontend (for example search endpoints), check implementation of AppSelectUsersAndContactsDialog
export default {
  props: {
    dialog: Boolean,
    title: String,
    availableItemsText: String,
    searchPlaceholder: String,
    confirmText: String,
    selectedItemsText: String,
    isLoadingStats: Boolean,
    isLoadingItems: Boolean,
    items: Array,
    count: Number,
    selectedItems: Array,
    search: String,
    filter: Function,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      showSelected: false,
    };
  },
  computed: {
    filteredItems() {
      if (this.showSelected) return this.selectedItems;
      if (this.filter) return this.items.filter(this.filter);
      else return this.items;
    },
    isMoreItems() {
      return this.count > this.items.length && !this.showSelected;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    focusSearchInput() {
      this.$refs.searchInput.focus();
    },
    onIntersect(e) {
      const isIntersecting = e[0].isIntersecting;
      if (isIntersecting || !this.isMoreItems) {
        this.$emit("load-more");
      }
    },
    onSubmit() {
      this.close();
      this.$emit("submit");
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>

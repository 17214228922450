<template>
  <div>
    <v-data-table
      v-model="selectedItems"
      ref="dataTable"
      class="elevation-0 border-a overflow-hidden"
      fixed-header
      :height="height"
      v-bind="$attrs"
      v-on="$listeners"
      :loadingText="$t('common.loading')"
      hide-default-footer
      :headers="_visibleHeaders"
      :itemsPerPage="options.itemsPerPage"
      :page.sync="options.page"
      @page-count="options.pageCount = $event"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="item"
      >
        <slot
          :name="slot"
          v-bind="item"
        ></slot>
      </template>
      <template
        v-slot:footer
        v-if="!disablePagination"
      >
        <div
          ref="dataTableFooter"
          class="py-1 border-t p-relative d-flex align-center justify-center"
        >
          <AppStatsText
            :isStatsLoading="isStatsLoading"
            :statsText="statsText"
          />
          <v-pagination
            class="pagination-items-remove-box-shadow justify-center"
            v-model="options.page"
            :length="options.pageCount"
            total-visible="7"
          />
        </div>
      </template>
      <template v-slot:header.data-table-select>
        <v-simple-checkbox
          :class="{ 'v-card--disabled': !selectableItems.length }"
          :disabled="!selectableItems.length"
          :value="isAllSelectableItemsSelected"
          :indeterminate="isIndeterminate"
          @input="handleToggleSelectAll"
        />
      </template>
      <template v-slot:item="slotProps">
        <slot
          name="item"
          v-bind="slotProps"
          :isSelectable="isItemSelectable(slotProps.item)"
        ></slot>
      </template>
    </v-data-table>
    <AppBulkActionMenu
      :bulkActionConfigs="bulkActionConfigs"
      :selectedItems="selectedItems"
      :style="{
        bottom: disablePagination ? '60px' : '100px',
        zIndex: 3,
        transform: 'translateX(-50%)',
        left: '50%',
        position: 'absolute',
        width: 'fit-content',
      }"
      @clear:click="dataTableBulkActionMixin_clearSelection"
    />
  </div>
</template>

<script>
import { dataTableBulkActionMixin } from "@/helpers/mixins/dataTableBulkActionMixin";

export default {
  mixins: [dataTableBulkActionMixin],
  props: {
    maxHeight: String,
    disablePagination: Boolean,
    statsText: String,
    isStatsLoading: Boolean,
    height: String,
    bulkActionConfigs: Array,
    isItemSelectable: {
      type: Function,
      default: () => true,
    },
    value: Array,
    headers: Array,
  },
  data() {
    return {
      options: {
        itemsPerPage: this.disablePagination ? -1 : 20,
        pageCount: 0,
        page: 1,
      },
    };
  },
  //you can use v-model on this component, if you dont, it
  //will use the dataTableBulkActionMixin_selectedItems logic
  model: {
    prop: "value",
    event: "input",
  },
  computed: {
    selectedItems: {
      get() {
        return this.value ? this.value : this.dataTableBulkActionMixin_selectedItems;
      },
      set(value) {
        this.onSelect(value);
      },
    },
    selectableItems() {
      return this.$attrs.items.filter((item) => this.isItemSelectable(item));
    },
    isAllSelectableItemsSelected() {
      return (
        this.selectableItems.length > 0 &&
        this.selectableItems.every((item) => this.isItemSelected(item))
      );
    },
    isIndeterminate() {
      return this.selectedItems.length > 0 && !this.isAllSelectableItemsSelected;
    },
    _visibleHeaders() {
      const headers = this.headers;
      // Only filter if headers have access property
      if (headers.some((header) => "access" in header)) {
        return headers.filter((header) => header.access);
      }
      return headers;
    },
  },
  methods: {
    onSelect(newValue) {
      if (this.value !== undefined) {
        this.$emit("input", newValue);
      } else {
        this.dataTableBulkActionMixin_selectedItems = newValue;
        this.$emit("input", newValue);
      }
    },
    isItemSelected(item) {
      return this.selectedItems.includes(item);
    },
    handleToggleSelectAll(value) {
      if (value) {
        this.selectedItems = [...this.selectableItems];
      } else {
        this.selectedItems = [];
      }
    },
    calcDataTableMaxHeight() {
      if (this.height) return;
      const pixelsFromTopOfPageToTable =
        this.$refs.dataTable.$el.getBoundingClientRect().top;
      const tableFooterHeight = this.$refs.dataTableFooter
        ? this.$refs.dataTableFooter.offsetHeight
        : 0;

      const extraPixelsToAccountFor = 30;
      const heightToRemove =
        pixelsFromTopOfPageToTable + tableFooterHeight + extraPixelsToAccountFor;

      const maxHeightStyle = `calc(100vh - ${heightToRemove}px)`;
      return maxHeightStyle;
    },
    updateDataTableHeight() {
      //This is the wrapper we have to set the max-height on
      const dataTableWrapper = this.$refs.dataTable.$el.querySelector(
        ".v-data-table__wrapper",
      );

      const dataTableMaxHeight = this.maxHeight
        ? `${this.maxHeight}px`
        : this.calcDataTableMaxHeight();

      dataTableWrapper.style.maxHeight = dataTableMaxHeight;
    },
  },
  mounted() {
    if (this.height) return;
    this.updateDataTableHeight();
  },
  updated() {
    if (this.height) return;
    this.updateDataTableHeight();
  },
};
</script>

<style>
.pagination-items-remove-box-shadow > .v-pagination > li > .v-pagination__item,
.v-pagination__navigation {
  box-shadow: none;
}
</style>

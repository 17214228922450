<template>
  <div class="file_folders_node">
    <v-hover v-slot="{ hover }">
      <div
        class="file_folders_node__root"
        @click="onClick(item)"
      >
        <div class="file_folders_node__prepend">
          <slot
            name="prepend"
            :item="item"
            :hover="hover"
            :selected="isSelected"
          ></slot>
        </div>
        <div class="file_folders_node__label">
          <slot
            name="label"
            :item="item"
            :hover="hover"
            :selected="isSelected"
          >
            {{ item.name }}
          </slot>
        </div>
        <div class="file_folders_node__append">
          <slot
            name="append"
            :item="item"
            :hover="hover"
            :selected="isSelected"
          ></slot>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "AppFolder",
  props: {
    item: Object,
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler(newVal, oldVal) {
        const isSelected = newVal.find((v) => v === this.item.id);
        this.isSelected = isSelected ? true : false;
      },
    },
  },
  methods: {
    onClick(item) {
      this.$emit("folder:click", item);
    },
  },
};
</script>

<template>
  <v-menu
    v-model="localMenu"
    transition="fade-transition"
    :position-x="position.x"
    :position-y="position.y"
    absolute
  >
    <v-card
      class="d-flex flex-column align-center py-1 px-1 overflow-auto"
      :disabled="isUpdating"
      :width="size.width"
      :max-height="size.height"
    >
      <div
        v-for="(event, index) in events"
        :key="index"
        style="width: 95%"
      >
        <div
          :class="`white--text rounded text-center cursor-pointer overflow-hidden`"
          style="height: 20px; margin-bottom: 1px"
          :style="`background-color: ${event.color}; color: ${textColor(event.color)}`"
          v-ripple
          @click="onEventClick(event)"
          @mousedown="onStartDrag(event)"
          @mouseup="onEndDrag(event)"
        >
          <AppCalendarEvent :event="event" />
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { getContrastYIQ } from "@/helpers/util";

export default {
  data() {
    return {
      dragEventId: null,
    };
  },
  props: {
    menu: Boolean,
    events: Array,
    position: Object,
    size: Object,
    isUpdating: Boolean,
  },
  model: {
    prop: "menu",
    event: "menu:change",
  },
  computed: {
    localMenu: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("menu:change", value);
      },
    },
  },
  methods: {
    textColor(color) {
      return getContrastYIQ(color);
    },
    onEventClick(e) {
      this.$emit("event:click", e);
    },
    getDataDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    onStartDrag(event) {
      this.$emit("startDrag", { event });
      setTimeout(() => {
        this.dragEventId = event.item.id;
      }, 100);
    },
    onEndDrag(event) {
      this.$emit("endDrag", { event });
      this.dragEventId = null;
    },
  },
};
</script>

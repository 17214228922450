<template>
  <v-menu
    @input="$emit('contextMenu:change', $event)"
    offset-y
    :offset-x="offsetX"
    :top="top"
    :left="left"
    :right="right"
    :transition="transition"
    origin="top right"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on"
        :class="activatorClass"
      >
        <v-btn
          :class="activatorClass"
          depressed
          :icon="!text"
          v-bind="attrs"
          v-on="on"
          :loading="loading"
          :disabled="disabled"
          :color="color ? color : text ? 'background' : ''"
          :small="small"
        >
          <i
            v-if="emoji"
            :class="['mr-3', 'em', emoji]"
          ></i>
          <div
            :class="icon ? 'mr-2' : ''"
            v-if="text"
          >
            {{ text }}
          </div>
          <v-icon :small="small">{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <div
      class="pa-2 ui-background d-flex flex-column"
      style="min-width: 70px"
    >
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    transition: {
      default: "slide-y-transition",
      type: String,
    },
    icon: {
      type: String,
      default: function () {
        return this.$icons.REGULAR.ACTION.MENU;
      },
    },
    value: Boolean,
    text: String,
    color: String,
    loading: Boolean,
    disabled: Boolean,
    top: Boolean,
    left: Boolean,
    right: Boolean,
    emoji: String,
    activatorClass: String,
    small: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    offsetX() {
      return this.right;
    },
  },
};
</script>

<template>
  <header class="signup-page__left-side-header">
    <img
      class="signup-page__left-side-logo"
      src="https://cdn.gripr.io/assets/gripr-logo.svg"
      alt="Gripr"
    />
    <v-spacer />
    <div class="mr-3">
      <AppToggleMenuBtn
        :text="changeLanguageText"
        icon=""
        :emoji="selectedLanguageFlag"
        bottom
        offset-x
        transition="slide-y-transition"
        activatorClass="text-transform-none"
      >
        <AppMenuItem
          :emoji="language.emoji"
          v-for="(language, index) in languages"
          :key="index"
          :text="language.text + isSelectedText(language.code)"
          @click="onChangeLanguage(language.code)"
        />
      </AppToggleMenuBtn>
    </div>
    <AppToggleProfileMenuBtn
      v-if="!hideProfile"
      :profile="profile"
    >
      <AppMenuItem
        :icon="$icons.REGULAR.COMMON.USER_CIRCLE"
        :text="$t('signUp.myProfile')"
        @click="dialog.profile = true"
      />
      <AppMenuItem
        :icon="$icons.REGULAR.ACTION.LOG_OUT"
        :text="$t('common.logout')"
        @click="logout"
        color="error"
      />
    </AppToggleProfileMenuBtn>

    <AppProfileDialog
      v-model="dialog.profile"
      :profile="profile"
      @profile:update="onProfileUpdate"
      @avatar:upload="getProfile"
      @avatar:delete="getProfile"
    />
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { updateProfile } from "@/services/signup/signupService";

export default {
  props: {
    hideProfile: Boolean,
  },
  name: "AppLogoAppBar",
  data() {
    return {
      languages: [
        { code: "en", text: "English", emoji: "em em-gb" },
        { code: "nb", text: "Norsk", emoji: "em em-flag-no" },
      ],
      dialog: {
        profile: false,
      },
    };
  },

  computed: {
    ...mapState("profile", {
      profile: (state) => state.profile,
    }),
    ...mapGetters("profile", {
      metaValueByField: "metaValueByField",
    }),
    selectedLanguage() {
      return this.metaValueByField("LANGUAGE") || this.$i18n.locale;
    },
    changeLanguageText() {
      return this.selectedLanguage
        ? this.languages.find((l) => l.code === this.selectedLanguage)?.text
        : this.languages.find((l) => l.code === "en").text;
    },
    selectedLanguageFlag() {
      if (!this.selectedLanguage) return "";
      return this.languages.find((l) => l.code === this.selectedLanguage).emoji;
    },
    appBarColor() {
      return this.$vuetify.breakpoint.width > 1050 ? "transparent" : "background";
    },
  },

  methods: {
    onProfileUpdate(data) {
      for (let [key, value] of Object.entries(data)) {
        if (key === "phone") {
          const profilePhone = this.metaValueByField("PHONE");
          if (profilePhone === data[key]) return;
        }
        if (this.profile[key] === data[key]) return;
      }
      updateProfile(data).then(() => {
        this.getProfile();
      });
    },
    isSelectedText(code) {
      return this.isCurrentLanguage(code) ? ` (${this.$t("common.current")})` : "";
    },
    logout() {
      this.$auth.logout();
    },
    getProfile() {
      this.$store.dispatch("profile/getProfile");
    },
    isCurrentLanguage(code) {
      return code === this.selectedLanguage;
    },
    async onChangeLanguage(code) {
      if (this.isCurrentLanguage(code)) return;

      this.$i18n.locale = code;

      if (this.hideProfile) return;

      await this.$store.dispatch("profile/updateProfile", { language: code });
      this.$store.commit("auth/updateUserLanguage", this.selectedLanguage);
    },
  },

  mounted() {
    if (this.hideProfile) return;
    this.getProfile();
  },
};
</script>

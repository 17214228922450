<template>
  <div>
    <template v-for="(field, index) in fields">
      <div
        v-if="field"
        :key="index"
      >
        <ModuleInfoRow
          :dataKey="field.dataKey"
          :pathToModule="field.pathToModule"
          :search="search"
          :item="item"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ModuleInfoRow from "./ModuleInfoRow";

export default {
  components: {
    ModuleInfoRow,
  },
  props: {
    item: Object,
    fields: Array,
    search: String,
    isSelected: Boolean,
  },
};
</script>

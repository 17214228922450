<template>
  <v-menu
    v-model="localMenu"
    :offsetX="offsetX"
    :offsetY="offsetY"
    :left="left"
    :right="right"
    :top="top"
    :bottom="bottom"
    open-on-hover
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <AppMenuActionBtn
        v-bind="$attrs"
        v-on="on"
        angle-right
      />
    </template>
    <div
      class="pa-2 ui-background d-flex flex-column"
      style="min-width: 70px"
    >
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    menu: Boolean,
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
    offsetX: Boolean,
    offsetY: Boolean,
    angleDown: Boolean,
  },
  model: {
    prop: "menu",
    event: "menu:change",
  },
  computed: {
    localMenu: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("menu:change", value);
      },
    },
  },
};
</script>

<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title>{{ $t("common.industry") }}</v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div
      class="pa-5 pb-0"
      v-if="!isLoading"
    >
      <AppDefaultAutocomplete
        :label="$t('common.industries')"
        :value="tags"
        :items="selectableTags"
        :loading="selectableTagsIsLoading"
        @change="debounceTags"
        item-text="name"
        item-value="name"
        multiple
        select-all
        clearable
      ></AppDefaultAutocomplete>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    tags: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      tagsDebounce: null,
    };
  },
  computed: {
    ...mapState("cmsTags", {
      selectableTags: (state) => state.tags,
      selectableTagsIsLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    updateTags(tags) {
      this.$emit("tags:update", { tags });
    },
    getSelectableTags() {
      this.$store.dispatch("cmsTags/getTags", { type: "INDUSTRY" });
    },
    debounceTags(tags) {
      clearTimeout(this.tagsDebounce);
      this.tagsDebounce = setTimeout(() => {
        this.updateTags(tags);
      }, 1000);
    },
  },
  mounted() {
    this.getSelectableTags();
  },
};
</script>

<template>
  <div
    class="d-flex flex-row pt-1"
    v-if="displayData.text"
  >
    <div
      :style="{
        width: highestIconSize + 'px',
      }"
      class="d-flex justify-center"
    >
      <img
        v-if="isImageIcon"
        :src="displayData.icon"
        :style="{
          width: displayData.iconSize + 'px',
          height: displayData.iconSize + 'px',
          marginRight: '4px',
        }"
      />
      <v-icon
        v-else
        :size="displayData.iconSize"
      >
        {{ displayData.icon }}
      </v-icon>
    </div>
    <span
      :style="{
        color: 'disabled--text text-truncate',
        marginLeft: '6px',
        fontSize: '12px',
      }"
      v-html="highlightedText"
    ></span>
  </div>
</template>

<script>
import { getDisplayData } from "@/helpers/util/moduleItem";

export default {
  name: "ModuleInfoRow",
  props: {
    item: Object,
    dataKey: String,
    pathToModule: String,
    search: String,
    isSelected: Boolean,
  },
  computed: {
    highlightedText() {
      return this.$textHelpers.highlightText(
        this.displayData.text,
        this.search,
        this.isSelected,
      );
    },
    highestIconSize() {
      const allIconSizes = [...this.$constant.MODULE_ITEM_CONFIG.values()].flatMap(
        (data) => data.iconSize,
      );

      return Math.max(...allIconSizes) * 1.25;
    },
    displayData() {
      return getDisplayData(this.item, {
        dataKey: this.dataKey,
        pathToModule: this.pathToModule,
      });
    },
    isImageIcon() {
      return typeof this.displayData.icon === "number";
    },
  },
};
</script>

<template>
  <AppWidgetTemplate
    @delete:click="onDelete"
    v-bind="templateProps"
    v-ripple="!isDisabled"
  >
    <div
      :class="`${cursorPointer} overflow-hidden h-100 d-flex align-center`"
      @click="onClick"
    >
      <v-row class="ma-0 w-100">
        <v-col
          class="text-center pb-0 pt-0"
          cols="12"
        >
          <v-icon x-large>{{ icon }}</v-icon>
        </v-col>
        <v-col
          class="text-center pb-0"
          cols="12"
        >
          <span class="font-weight-bold">{{ title }}</span>
        </v-col>
        <v-col
          class="text-center pb-0 text-body-2"
          cols="12"
        >
          <span>
            {{ description }}
          </span>
        </v-col>
      </v-row>
    </div>
  </AppWidgetTemplate>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    icon: String,
    templateProps: Object,
  },
  methods: {
    onClick() {
      if (this.isDisabled) return;
      this.$emit("click");
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
  computed: {
    isDisabled() {
      return (
        this.templateProps.isEditMode ||
        this.templateProps.showStar ||
        !this.templateProps.hasRequiredRoles
      );
    },
    cursorPointer() {
      return !this.templateProps.showStar ? "cursor-pointer" : "";
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column mw-700px">
    <div class="text-h4 pb-2 text-center font-weight-bold">
      {{ $t(`integration.${importType}.${state}.title`) }}
    </div>
    <div class="text--disabled pb-10 text-center">
      {{ $t(`integration.${importType}.${state}.subTitle`) }}
    </div>
    <div class="pb-10">
      <div
        class="d-flex flex-wrap justify-center"
        style="gap: 1rem"
      >
        <div
          v-for="(integration, index) of integrations"
          :key="index"
        >
          <AppImportCard
            @importCard:click="onActivateIntegrationClick($event)"
            :integration="integration"
            :isSelected="isSelected(integration.name)"
          />
        </div>
      </div>
      <v-card
        v-if="importType === $constant.IMPORT_TYPES.PROJECT"
        ref="excludeCard"
        class="mt-10 pa-5 border-a"
        style="min-width: 500px"
        flat
        :disabled="
          $constant.EXCLUDE_CLOSED_PROJECTS_NOT_ALLOWED_INTEGRATIONS.includes(
            selectedIntegrationName,
          )
        "
        @click="toggleExcludeClosed(!excludeClosed)"
      >
        <div class="d-flex align-center">
          <div>
            <div class="text-h6">
              {{ $t(`integration.${importType}.${state}.reduceDataClutter`) }}
            </div>
            <div>
              {{ $t(`integration.${importType}.${state}.excludeClosed`) }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <AppDefaultSwitch
            class="pa-0 ma-0"
            :input-value="excludeClosed"
            @click.stop="triggerCardClick"
          />
        </div>
      </v-card>
    </div>
    <div class="text-center">
      <v-btn
        class="mr-2"
        text
        @click="close"
      >
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        depressed
        :disabled="!selectedIntegrationName"
        @click="onImport"
      >
        {{ $t(`integration.common.import`) }}
      </v-btn>
    </div>
    <IntegrationInstanceConfigDialog
      v-model="instanceConfigDialog.active"
      :integrationName="instanceConfigDialog.integrationName"
      @dialog:change="onInstanceConfigDialogChange"
      @complete="onIntegrationConfigComplete"
    />
    <IntegrationInstanceSettingsDialog
      v-model="instanceSettingsDialog.active"
      :integration="instanceSettingsDialog.integration"
      @complete="onUpdateSynchronizationStatus"
    />
  </div>
</template>

<script>
import { EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS } from "@/helpers/constants";
import IntegrationInstanceConfigDialog from "@/pages/SettingsPage/AccountPage/IntegrationsPage/dialogs/config/IntegrationInstanceConfigDialog.vue";
import IntegrationInstanceSettingsDialog from "@/pages/SettingsPage/AccountPage/IntegrationsPage/dialogs/settings/IntegrationInstanceSettingsDialog.vue";
import { openOauth2Window } from "@/services/integration/integrationSetup/oAuth2SetupService";
import { mapState } from "vuex";

export default {
  props: {
    integrations: Array,
    state: String,
    importType: String,
    excludeClosed: Boolean,
  },
  data() {
    return {
      selectedIntegrationBeforeUpgrade: null,
      selectedIntegrationName: "",
      instanceConfigDialog: {
        active: false,
        integrationName: null,
      },
      instanceSettingsDialog: {
        active: false,
        integration: null,
      },
    };
  },
  computed: {
    ...mapState("integrations", {
      actualIntegrations: (state) => state.integrations,
    }),
  },
  watch: {
    integrations: {
      handler(integrations) {
        if (integrations.length === 1 && !this.selectedIntegrationName) {
          this.onActivateIntegrationClick({ integration: integrations[0] });
        } else if (integrations.length > 1) {
          this.selectedIntegrationName = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    triggerCardClick() {
      this.$refs.excludeCard.$listeners.click();
    },
    toggleExcludeClosed(excludeClosed) {
      this.$emit("excludeClosed:toggle", excludeClosed);
    },
    onActivateIntegrationClick({ integration }) {
      if (!integration.canIntegrate) {
        return this.openUpgradePlanDialog(integration);
      }
      if (!integration.isEnabled) {
        return this.onInstanceConfig(integration);
      }

      if (integration.requireExternalAccountId) {
        return this.onExternalAccountingRequired(integration.actualIntegration);
      }

      this.toggleSelectedIntegrationName(integration.name);
    },
    onInstanceConfig(integration) {
      const { name: integrationName } = integration;

      if (this.isOauth2Integration(integrationName)) {
        this.handleOauth2Integration(integration);
      } else {
        this.openInstanceConfigDialog(integrationName);
      }
    },
    async getIntegrations(load) {
      await Promise.all([
        this.$store.dispatch("integrations/getIntegrations", load),
        // this.getActiveIntegrations(),
      ]);
    },
    async getActiveIntegrations() {
      await this.$store.dispatch("activeIntegrations/getActiveIntegrations");
    },
    isOauth2Integration(integrationName) {
      return this.$constant.O_AUTH2_INTEGRATIONS.includes(integrationName);
    },
    shouldShowSettingsButton(name) {
      return this.$constant.ACCOUNTING_INTEGRATIONS.includes(name);
    },
    getActualIntegration(integrationName) {
      const integration = this.actualIntegrations.find((integration) => {
        return (
          integration.integrationInstance?.active && integration.name === integrationName
        );
      });
      return integration;
    },
    async afterInstanceConfig(integrationName) {
      await this.$store.dispatch("auth/getUser");
      this.getIntegrations().then(() => {
        if (!this.shouldShowSettingsButton(integrationName)) return;

        const integration = this.getActualIntegration(integrationName);

        if (integration) {
          this.onInstanceSettings(integration);
        }
      });
    },
    handleOauth2Integration(integration) {
      const { name: integrationName } = integration;
      openOauth2Window({ integrationName }, async () => {
        this.afterInstanceConfig(integrationName);
      });
    },
    requiresExternalAccount(integration) {
      return EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS.includes(integration.name);
    },
    openInstanceConfigDialog(integrationName) {
      this.instanceConfigDialog.integrationName = integrationName;
      this.instanceConfigDialog.active = true;
    },
    openUpgradePlanDialog(integration) {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: integration.requiredPlanCode,
        onAfterUpgrade: () => this.onInstanceConfig(integration),
      });
    },
    toggleSelectedIntegrationName(name) {
      if (
        this.$constant.EXCLUDE_CLOSED_PROJECTS_NOT_ALLOWED_INTEGRATIONS.includes(
          this.selectedIntegrationName || name,
        )
      ) {
        this.$emit("excludeClosed:toggle", false);
      }
      if (this.selectedIntegrationName === name) {
        this.selectedIntegrationName = null;
      } else {
        this.selectedIntegrationName = name;
      }
    },
    onExternalAccountingRequired(actualIntegration) {
      if (actualIntegration) {
        this.onInstanceSettings(actualIntegration);
      }
    },
    onUpdateSynchronizationStatus() {
      this.getActiveIntegrations();
    },
    onInstanceSettings(integration) {
      this.instanceSettingsDialog.integration = integration;
      this.instanceSettingsDialog.active = true;
    },
    onInstanceConfigDialogChange(active) {
      if (!active) this.instanceConfigDialog.integrationName = null;
    },
    onIntegrationConfigComplete(integrationName) {
      this.selectedIntegrationName = integrationName;
      this.afterInstanceConfig(integrationName);
    },
    isSelected(cardName) {
      return cardName === this.selectedIntegrationName;
    },
    close() {
      this.$emit("close:click");
      this.selectedIntegrationName = null;
    },
    onImport() {
      this.$emit("import:click", this.selectedIntegrationName);
    },
  },
  components: {
    IntegrationInstanceConfigDialog,
    IntegrationInstanceSettingsDialog,
  },
};
</script>

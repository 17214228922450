<template>
  <div>
    <div class="mb-4">
      <v-simple-table
        class="border-a"
        fixed-header
        height="400px"
      >
        <template v-slot:default>
          <tbody class="border-radius-0">
            <template v-if="isLoading">
              <v-progress-linear
                indeterminate
                rounded
                height="6"
              />
            </template>
            <template v-else-if="hasError">
              Something went wrong, please try again
            </template>
            <template v-else>
              <tr
                v-for="(property, i) in properties"
                :key="i"
                class="cursor-pointer list_tr"
                :class="
                  property.boligmappaNumber
                    ? property.boligmappaNumber === selectedPropertyBoligmappaNumber
                      ? 'list_tr--selected'
                      : ''
                    : ''
                "
                @click="selectProperty(property)"
              >
                <td class="border-radius-0">
                  {{ property.formatted }}
                </td>
              </tr>

              <div
                class="pa-4 d-flex align-center justify-center"
                v-if="cursor"
              >
                <v-btn
                  block
                  outlined
                  color="primary"
                  :loading="isLoadingMore"
                  @click="loadMore"
                >
                  {{ $t("common.loadMore") }}
                </v-btn>
              </div>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-btn
      color="primary"
      @click="onNextStep"
      :disabled="!selectedProperty"
    >
      {{ $t("integration.boligmappa.plantWizard.nextStep") }}
    </v-btn>
    <v-btn
      text
      @click="goBack"
    >
      {{ $t("common.back") }}
    </v-btn>
    <v-btn
      text
      @click="onCancel"
    >
      {{ $t("common.cancel") }}
    </v-btn>
  </div>
</template>

<script>
import { getProperties } from "../../../../../../services/integration/boligmappa/BoligmappaPropertyWizardService";

export default {
  props: {
    addressId: String,
  },
  data() {
    return {
      properties: [],
      isLoading: false,
      isLoadingMore: false,
      hasError: false,
      cursor: null,

      selectedProperty: null,
      createPlantDialog: false,
    };
  },
  watch: {
    addressId: {
      handler(addressId) {
        this.selectedProperty = null;
        if (addressId) {
          this.getProperties(addressId);
        }
      },
    },
    immediate: true,
  },
  computed: {
    selectedPropertyBoligmappaNumber() {
      return this.selectedProperty ? this.selectedProperty.boligmappaNumber : null;
    },
  },
  methods: {
    getProperties(id, cursor = null) {
      this.hasError = false;
      if (!cursor) {
        this.properties = [];
        this.isLoading = true;
      } else {
        this.isLoadingMore = true;
      }

      getProperties(id, cursor)
        .then(({ response, meta }) => {
          this.properties = [...this.properties, ...response];
          this.cursor = meta?.cursor?.next || null;
        })
        .catch(() => (this.hasError = true))
        .finally(() => {
          this.isLoading = false;
          this.isLoadingMore = false;
        });
    },
    loadMore() {
      if (!this.cursor) return;
      if (this.isLoadingMore) return;
      this.getProperties(this.addressId, this.cursor);
    },
    selectProperty(property) {
      this.selectedProperty = property;
    },
    onNextStep() {
      this.$emit("select", { property: this.selectedProperty });
    },
    goBack() {
      this.$emit("back");
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
  created() {},
};
</script>

<template>
  <div class="empty-state d-flex align-center justify-center">
    <div v-if="loading">
      <v-icon
        size="26"
        class="mr-2"
      >
        {{ $icons.SOLID.ACTION.LOADING }}
      </v-icon>
    </div>
    <div
      v-else
      class="d-flex justify-center"
    >
      <v-icon class="mr-2">{{ $icons.LIGHT.FILE.FILE }}</v-icon>
      <span>{{ $t("common.dropFilesHere") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>

<style scoped>
.empty-state {
  height: 100%;
  width: 100%;
  font-size: 14px;
}
</style>

<template>
  <AppColorChip
    :text="sentText"
    :color="sentColor"
  />
</template>

<script>
export default {
  props: {
    productUsage: Object,
  },
  computed: {
    isSent() {
      const isSent = this.productUsage.status === "SENT";
      const isNotSent = this.productUsage.status === "NOT_SENT";
      if (isSent) return true;
      else if (isNotSent) return false;
      return null;
    },
    sentText() {
      return this.isSent ? this.$t("common.sent") : this.$t("common.notSent");
    },
    sentColor() {
      return this.isSent ? "success--text" : "error--text";
    },
  },
};
</script>

<style lang="scss" scoped></style>

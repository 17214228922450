<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="h-100 overflow-y-auto">
      <v-toolbar
        class="border-b"
        dense
        flat
        fixed
        height="80"
      >
        <v-toolbar-title class="font-weight-medium">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mr-2"
          @click="onSubmit"
          depressed
          color="primary"
          :loading="isCreatingOrEditingProducts"
          :disabled="!hasSelectedProducts"
        >
          {{ confirmText }}
        </v-btn>
        <v-btn
          depressed
          text
          color="primary"
          @click="onCancel"
        >
          {{ $t("common.cancel") }}
        </v-btn>
      </v-toolbar>

      <div
        class="tertiary-height overflow-y-auto"
        ref="scrollContainer"
      >
        <template v-if="isUnitsLoading || isUserSettingsLoading">
          <div class="h-100 w-100 d-flex align-center justify-center">
            <AppCircularLoader small />
          </div>
        </template>
        <div
          class="page-container"
          ref="pageContainer"
        >
          <div>
            <div class="text-h6">
              {{ $t("projectView.products.title") }}
            </div>
            <div class="d-flex flex-column pt-8">
              <v-row
                no-gutters
                class="font-weight-bold"
                v-if="hasSelectedProducts"
              >
                <v-col cols="4">
                  {{ $t("projectView.products.product") }}
                </v-col>
                <v-col>
                  {{ $t("common.productFields.addedDate") }}
                </v-col>
                <v-col>
                  {{ $t("common.productFields.amount") }}
                </v-col>
                <v-col>
                  {{ $t("common.productFields.unitPrice") }}
                </v-col>
                <v-col>
                  {{ $t("common.productFields.total") }}
                </v-col>
                <v-col
                  v-if="!isEditMode"
                  cols="1"
                />
              </v-row>
              <template>
                <AppAddProductItem
                  v-for="(product, index) of selectedProducts"
                  :key="index"
                  :product="product"
                  :units="units"
                  :isEditMode="isEditMode"
                  :hidePrices="hidePrices"
                  @product:change="onProductChange($event, index)"
                  @delete:click="onDelete(index)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <v-toolbar
        absolute
        bottom
        class="border-t w-100 remove-toolbar-content-padding"
        flat
        height="80"
      >
        <div class="page-container">
          <div class="d-flex align-center">
            <div v-if="!isEditMode">
              <v-btn
                outlined
                color="primary"
                depressed
                @click="openfindProductsDialog"
                class="mr-1"
              >
                {{ $t("projectView.products.addProductsDialog.findAndSelectProducts") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                depressed
                @click="addFreeTextProduct"
              >
                {{ $t("projectView.products.addProductsDialog.addFreeTextRow") }}
              </v-btn>
            </div>
            <v-spacer />
            <div class="font-weight-bold d-flex pr-4">
              <div class="pr-10">
                {{ $t("projectView.products.addProductsDialog.sum") }}
              </div>
              <AppCurrencyText :value="sum" />
            </div>
            <v-col
              v-if="!isEditMode"
              cols="1"
            />
          </div>
        </div>
      </v-toolbar>
    </v-card>

    <AppSelectProductsDialog
      v-model="findProductsDialog"
      @submit:click="addProducts"
      :projectId="projectId"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment";
import Vue from "vue";

export default {
  props: {
    dialog: Boolean,
    model: String,
    modelId: String,
    projectId: String,
    editProducts: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isValid: null,
      searchDebounce: null,
      findProductsDialog: false,
      selectedProducts: [],
      isCreatingOrEditingProducts: false,
    };
  },
  watch: {
    editProducts: {
      handler(val) {
        if (val) {
          this.selectedProducts = [...val];
        }
      },
    },
    dialog: {
      handler(val) {
        if (val) {
          this.getUserSettings();
          this.getUnits();
        }
      },
    },
  },
  computed: {
    hasSelectedProducts() {
      return this.selectedProducts?.length > 0;
    },
    isEditMode() {
      return !!this.editProducts && this.editProducts?.length > 0;
    },
    sum() {
      const sum = this.selectedProducts.reduce((accumulator, product) => {
        return accumulator + product.amount * product.unitPrice;
      }, 0);
      return sum;
    },
    title() {
      return this.$tc(
        `projectView.products.addProductsDialog.${
          this.isEditMode ? "editProducts" : "addProducts"
        }`,
        this.editProducts?.length,
      );
    },
    confirmText() {
      return this.$t(
        this.isEditMode
          ? "common.confirmChanges"
          : "projectView.products.addProductsDialog.addProducts",
      );
    },
    hidePrices() {
      return this.userSettingsResult?.hideProductPrice;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    ...mapState("userSettings", {
      userSettingsResult: (state) => state.result,
      isUserSettingsLoading: (state) => state.isLoading,
    }),
  },

  methods: {
    onProductChange(event, index) {
      Vue.set(this.selectedProducts, index, event);
    },
    addFreeTextProduct() {
      const today = moment(new Date()).format("YYYY-MM-DD");

      this.selectedProducts.push({
        unit: null,
        addedAt: today,
        name: null,
        amount: null,
        unitPrice: null,
        comment: null,
      });
      this.scrollToBottom();
    },
    addProducts(selectedProducts) {
      this.selectedProducts.push(...selectedProducts);
    },
    scrollToBottom() {
      const pageHeight = this.$refs.pageContainer.clientHeight;
      this.$vuetify.goTo(pageHeight, {
        container: this.$refs.scrollContainer,
      });
    },
    onDelete(index) {
      this.selectedProducts.splice(index, 1);
    },
    openfindProductsDialog() {
      this.findProductsDialog = true;
    },
    closeDialog() {
      this.localDialog = false;
    },
    onSubmit() {
      this.isCreatingOrEditingProducts = true;
      const storeType = this.isEditMode
        ? "productUsage/updateProducts"
        : "productUsage/createProducts";

      const mappedProduct = this.selectedProducts.map((product) => {
        return { ...product, unit: product.unit?.value };
      });

      this.$store
        .dispatch(storeType, {
          products: mappedProduct,
          modelId: this.modelId,
          model: this.model,
        })
        .then(() => {
          this.localDialog = false;
          if (!this.isEditMode) {
          }
          setTimeout(() => {
            if (!this.isEditMode) {
              this.selectedProducts = [];
            }
            if (this.isEditMode) {
              // this.$emit("selectedProducts:update", this.selectedProducts);
            }
          }, 100);
        })
        .finally(() => {
          this.isCreatingOrEditingProducts = false;
        });
    },
    onCancel() {
      this.closeDialog();
    },
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
    async getUserSettings() {
      await this.$store.dispatch("userSettings/getSettingsResult", {
        params: {
          model: this.model,
          modelId: this.modelId,
        },
      });
    },
  },
};
</script>

<style>
.remove-toolbar-content-padding > .v-toolbar__content {
  padding: 0px !important;
}
</style>

<template>
  <AppContextMenu
    ref="menu"
    height="440"
    width="300"
    :closeOnContentClick="false"
  >
    <AppProjectAutocomplete
      @change="onSubmit"
      @created="({ project }) => onSubmit(project.id)"
      isReadyToInitialFetch
      autofocus
      activateOnFocus
      resetOnSubmit
      :maxWidthMultiplier="1"
    />
  </AppContextMenu>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onSubmit(projectId) {
      this.$emit("submit", { projectId });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>

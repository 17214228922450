import axios from "axios";
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  properties: null,
  isLoading: false,
});

const getters = {
  sender: (state) => state.properties?.sender,
  signers: (state) => state.properties?.signers || [],
  createdDate: (state) => new Date(state.properties?.createdDate),
  completedDate: (state) => new Date(state.properties?.completedDate),
};

const actions = {
  async getProperties({ commit }, { storageId, load = true }) {
    if (load) {
      commit("setLoading", true);
    }
    return axios
      .get(`${integrationApiUrl}e-sign/signable-document/${storageId}`)
      .then(({ data }) => {
        commit("setProperties", data);
        return data;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const mutations = {
  setProperties(state, properties) {
    state.properties = properties;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const completedDocumentProperties = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

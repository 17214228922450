<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title ? title : $t("fileManagement.fileExplorer.newFolder.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newFolderForm"
          v-model="folder.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div
            class="pb-5 d-flex justify-center"
            v-if="permissionLevel"
          >
            <v-btn-toggle
              v-model="folder.permissionLevel.value"
              mandatory
              color="primary"
            >
              <v-btn
                value="PUBLIC"
                height="150px"
                width="183"
                class="text-transform-none text-body-1"
                :disabled="
                  parentPermissionLevel === 'RESTRICTED' ||
                  parentPermissionLevel === 'PRIVATE'
                "
              >
                <template v-slot>
                  <div class="d-flex flex-column">
                    <v-icon large>
                      {{ storageMixins_getPermissionLevelIconName("PUBLIC") }}
                    </v-icon>
                    <span class="subtitle-1">
                      {{ $t("fileManagement.fileExplorer.permissionLevel.public") }}
                    </span>
                    <span class="subtitle-2">
                      {{ $t("fileManagement.fileExplorer.permissionLevel.publicDesc") }}
                    </span>
                  </div>
                </template>
              </v-btn>
              <v-btn
                value="RESTRICTED"
                style="text-transform: none"
                height="150px"
                width="183"
                :disabled="
                  parentPermissionLevel === 'PUBLIC' ||
                  parentPermissionLevel === 'PRIVATE'
                "
              >
                <template v-slot>
                  <div class="d-flex flex-column">
                    <v-icon large>
                      {{ storageMixins_getPermissionLevelIconName("RESTRICTED") }}
                    </v-icon>
                    <span class="subtitle-1">
                      {{ $t("fileManagement.fileExplorer.permissionLevel.restricted") }}
                    </span>
                    <span class="subtitle-2">
                      {{
                        $t("fileManagement.fileExplorer.permissionLevel.restrictedDesc")
                      }}
                    </span>
                  </div>
                </template>
              </v-btn>

              <v-btn
                value="PRIVATE"
                style="text-transform: none"
                height="150px"
                width="183"
                :disabled="
                  parentPermissionLevel === 'PUBLIC' ||
                  parentPermissionLevel === 'RESTRICTED'
                "
              >
                <template v-slot>
                  <div class="d-flex flex-column">
                    <v-icon large>
                      {{ storageMixins_getPermissionLevelIconName("PRIVATE") }}
                    </v-icon>
                    <span class="subtitle-1">
                      {{ $t("fileManagement.fileExplorer.permissionLevel.private") }}
                    </span>
                    <span class="subtitle-2">
                      {{ $t("fileManagement.fileExplorer.permissionLevel.privateDesc") }}
                    </span>
                  </div>
                </template>
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("fileManagement.fileExplorer.newFolder.nameLabel") }}
            </div>
            <AppDefaultTextField
              ref="newFolderFormName"
              v-model="folder.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
              autofocus
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="onClose"
          :confirmText="$t('fileManagement.fileExplorer.newFolder.create')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  props: {
    dialog: Boolean,
    title: String,
    nameMaxInput: {
      type: Number,
      default: 255,
    },
    permissionLevel: Boolean,
    data: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    data: {
      handler(data) {
        if (data?.parent?.item?.permissionLevel) {
          this.folder.permissionLevel.value = data.parent.item.permissionLevel;
          this.parentPermissionLevel = data?.parent?.item?.permissionLevel;
        } else {
          this.folder.permissionLevel.value = "PUBLIC";
          this.parentPermissionLevel = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      parentPermissionLevel: null,
      folder: {
        valid: false,
        name: {
          value: "",
        },
        permissionLevel: {
          value: "PUBLIC",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.newFolderForm.resetValidation();
    },
    onCreate() {
      const valid = this.$refs.newFolderForm.validate();
      if (valid) {
        const data = {
          name: this.folder.name.value,
        };
        if (this.permissionLevel) {
          data.permissionLevel = this.folder.permissionLevel.value;
        }
        this.$emit("submit:form", data);
        this.closeDialog();
        this.$refs.newFolderForm.reset();
      }
    },
  },
};
</script>

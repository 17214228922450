import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  tags: [],
  isLoading: false,
  isCreating: false,
});

const getters = {};

const actions = {
  async getTags({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);
    return axios
      .get(`${apiUrl}cms/tag`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const tags = data.tags;
        commit("setTags", tags);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setTags(state, tags) {
    state.tags = tags;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  updateTag(state, tag) {
    const index = state.tags.findIndex((x) => x.id === tag.id);
    if (index !== -1) {
      state.tags.splice(index, 1, tag);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const cmsTags = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <a
    @click.stop
    :href="`tel:${phone}`"
    class="underline-on-hover"
  >
    {{ phone }}
  </a>
</template>

<script>
export default {
  props: {
    phone: String,
  },
};
</script>

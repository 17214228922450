<template>
  <div
    v-if="isStatsLoading || statsText"
    class="ml-4 p-absolute"
    style="left: 0"
  >
    <v-skeleton-loader
      v-if="isStatsLoading"
      class="skeleton-loader-text-custom"
      type="text"
      width="150"
      height="16"
    />
    <div
      v-else
      v-html="statsText"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    isStatsLoading: Boolean,
    statsText: String,
  },
};
</script>

<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.overview") }}
      </v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div
      class="pa-5 pb-0 overflow-y-auto secondary-height"
      v-if="!isLoading && template"
    >
      <div class="pb-5">
        <div class="label--small">
          {{ $t("formManagement.signature.enableSignatureByDefault") }}
        </div>
        <AppDefaultSwitch
          hide-details
          inset
          color="success"
          class="mt-0"
          :input-value="template?.hasDefaultSignature"
          @change="updateTemplateField($event, 'hasDefaultSignature')"
        />
      </div>
      <div
        class="pb-5"
        v-if="isCorporateManagementFeatureEnabled && isDomainElevatedUser"
      >
        <div class="label--small">
          {{ $t("common.shareableWithChildDomains") | capitalize }}
        </div>
        <AppDefaultSwitch
          hide-details
          inset
          class="mt-0"
          :input-value="template?.isShareableWithChildDomains"
          @change="updateTemplateField($event, 'isShareableWithChildDomains')"
        />
      </div>

      <div
        class="pb-5"
        v-if="typeof template.isAddToNewWorkspace === 'boolean'"
      >
        <div class="label--small">
          {{ $t("cms.template.form.nav.details.addToNewWorkspaces") | capitalize }}
        </div>
        <AppDefaultSwitch
          hide-details
          inset
          color="success"
          class="mt-0"
          :input-value="template.isAddToNewWorkspace"
          @change="updateTemplateField($event, 'isAddToNewWorkspace')"
        />
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.createdBy") | capitalize }}
        </div>
        <AppUserAvatar
          :user="template.creator"
          show-name
          :truncate="34"
        />
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.creationDate") | capitalize }}
        </div>
        <div>{{ new Date(template.createdAt) | dateFormat("DD.MM.YYYY HH:mm") }}</div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.lastUpdated") | capitalize }}
        </div>
        <div>{{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}</div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.id") | capitalize }}
        </div>
        <div>{{ template.number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    template: Object,
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters("auth", {
      isCorporateManagementFeatureEnabled: "isCorporateManagementFeatureEnabled",
      isDomainElevatedUser: "isDomainElevatedUser",
    }),
  },
  methods: {
    updateTemplateField(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (typeof value !== "undefined" && value !== this.template[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.updateTemplate({ [field]: value });
      }
    },
    updateTemplate(body) {
      this.$emit("template:update", { template: this.template, body });
    },
  },
  mounted() {},
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("contact.addContact.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="p-relative hide-details-compensation">
          <div class="label--small">
            {{ $t("contact.addContact.contacts") }}
          </div>
          <AppClientGroupedUserAndContactAutocomplete
            v-model="outContacts"
            :items="searchContacts"
            :loading="isLoadingContacts"
            :noDataText="$t('common.noContacts')"
            multiple
            hide-details
            clearable
            is-only-contacts
            primaryLabelPath="label"
            itemText="fullName"
            :labelPaths="['label']"
          >
            <template v-slot:append-item>
              <AppSelectAppendBtn @click="onCreateContact">
                {{ $t("contact.common.createContact") }}
              </AppSelectAppendBtn>
            </template>
          </AppClientGroupedUserAndContactAutocomplete>
        </div>
        <div class="p-relative">
          <div class="label--small">
            {{ $t("contact.addContact.clients") }}
          </div>
          <AppClientAutocomplete
            v-model="outData.clientIds"
            multiple
            hide-details
            clearable
            isReadyToInitialFetch
            :queryParams="{
              active: true,
              alwaysIncludeIds: [...outData.clientIds],
            }"
            @created="onClientCreated"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onCancel"
          :loading="isSubmitting"
        />
      </v-card-actions>
    </v-card>
    <AppContactDialog
      v-model="newContactDialog.active"
      :title="$t('contact.contactPerson.newContact.title')"
      :confirmText="$t('common.create')"
      @confirm="afterContactCreation"
    />
  </v-dialog>
</template>

<script>
import { getSearchContacts } from "@/services/search/contact";

export default {
  props: {
    dialog: Boolean,
    inData: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isSubmitting: false,
      searchContacts: [],
      isLoadingContacts: false,
      outData: {
        clientIds: [],
        contactIds: [],
      },
      newContactDialog: {
        active: false,
      },
    };
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData();
          this.getData();
        }
      },
    },
  },
  computed: {
    outContacts: {
      get() {
        return this.outData.contactIds;
      },
      set(newContactIds) {
        if (!newContactIds || newContactIds.length === 0) {
          this.outData.contactIds = [];
          return;
        }

        const addedContacts = newContactIds
          .map((id) => this.searchContacts.find((c) => c.id === id))
          .filter(Boolean);
        const addedClientIds = new Set(
          addedContacts.map((contact) => contact.client?.id).filter(Boolean),
        );

        this.outData.contactIds = newContactIds;
        this.outData.clientIds = [
          ...new Set([...this.outData.clientIds, ...addedClientIds]),
        ];
      },
    },

    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    fillFormWithData() {
      const inData = this.inData;
      this.outData.clientIds = inData?.clientIds || null;
      this.outData.contactIds = inData?.contactIds || null;
    },
    onSubmit() {
      this.isSubmitting = true;
      this.$emit("submit", {
        data: this.outData,
        afterCreation: () => this.afterCreation(),
      });
    },
    afterCreation() {
      this.isSubmitting = false;
      this.outData.clientIds = [];
      this.outData.contactIds = [];
      this.close();
    },
    onCancel() {
      this.close();
    },
    close() {
      this.localDialog = false;
    },
    onCreateContact() {
      this.newContactDialog.active = true;
    },
    getData() {
      this.getContacts();
    },
    getContacts() {
      this.isLoadingContacts = true;
      const params = {
        isActive: true,
        alwaysIncludeIds: [...this.outContacts],
      };
      getSearchContacts({ params })
        .then((contacts) => {
          this.searchContacts = contacts;
        })
        .finally(() => (this.isLoadingContacts = false));
    },
    onClientCreated({ client }) {
      this.outData.clientIds.push(client.id);
    },
    afterContactCreation({ contact }) {
      this.outData.contactIds.push(contact.id);

      this.getContacts();
    },
  },
};
</script>

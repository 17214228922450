<template>
  <ProjectHeader
    :activeProject="project"
    @project:change="onProjectChange"
  />
</template>

<script>
import { mapState } from "vuex";
import ProjectHeader from "./ProjectHeader.vue";

export default {
  computed: {
    ...mapState("project", {
      project: (state) => state.project,
      isProjectLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    async getProject(id) {
      await this.$store.dispatch("project/getProject", id);
    },
    async onProjectChange({ project }) {
      if (!project) return;
      const to = {
        params: { projectId: project.id },
      };
      this.$router.replace(to);
    },
  },
  components: { ProjectHeader },
};
</script>

<template>
  <div
    class="information_drawer"
    id="storageContainer"
  >
    <AppDrawerFileManager
      :items="items"
      :folderId="folderId"
      :prependPath="prependPath"
      :truncatedPath="truncatedPath"
      :parent="parent.item"
      :parentIsValid="parentIsValid"
      :parentIsRoot="parent.isRoot"
      :searchRootId="rootFolderId"
      :isLoading="isLoading"
      :isDownloading="isDownloading"
      :isFileUploading="isFileUploading"
      :isFolderUploading="isFolderUploading"
      :canUpload="canUpload"
      :acceptedTypes="acceptedFileTypes"
      :title="$t('fileManagement.fileExplorer.mainToolbar.attachments')"
      show-download
      show-delete
      show-e-sign
      can-delete
      can-download
      action-left
      @item:click="storageDrawerMixins_onItemClick"
      @searchItem:click="storageDrawerMixins_onSearchItemClick"
      @path:click="storageDrawerMixins_onPathItemClick"
      @item:edit="storageDrawerMixins_onItemEdit"
      @item:create="storageDrawerMixins_onItemCreate"
      @item:download="storageDrawerMixins_onItemDownload"
      @item:delete="storageDrawerMixins_onItemDelete"
      @item:selectToggle="storageDrawerMixins_onItemSelectToggle"
      @item:selectAllToggle="storageDrawerMixins_onSelectAllToggle"
      @item:sendToSign="storageDrawerMixins_onSendToSign"
    />
    <AppFilePreviewDialog
      v-model="activeFileId"
      :folderId="folderId"
      :initialActiveMenu="dialog.initialActiveMenu"
      @dialog:close="storageDrawerMixins_onPreviewDialogClose"
    />
    <AppLoaderDialog
      v-model="dialog.creatingSignableDocument.active"
      :title="$t('fileManagement.creatingSignableDocumentDialog.title')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { storageDrawerMixins } from "@/helpers/mixins";

export default {
  props: {
    rootFolderId: String,
  },
  data() {
    return {
      storeKey: "storage",
      folderId: null,
      activeFileId: null,
      initTimeout: null,
      dialog: {
        preview: {
          active: false,
          initialActiveMenu: null,
        },
        creatingSignableDocument: {
          active: false,
        },
      },
    };
  },
  computed: {
    ...mapState("storage", {
      items: (state) => state.items,
      parent: (state) => state.parent,
      prependPath: (state) => state.prependPath,
      truncatedPath: (state) => state.truncatedPath,
      isDownloading: (state) => state.isDownloading,
      isFileUploading: (state) => state.file.isUploading,
      isFolderUploading: (state) => state.folder.isUploading,
    }),
    ...mapGetters("storage", {
      isLoading: "isLoading",
      canUpload: "canUpload",
      parentIsValid: "parentIsValid",
    }),
    ...mapGetters("acceptedFileTypes", {
      acceptedFileTypes: "acceptedFileTypes",
    }),
  },
  watch: {
    rootFolderId: {
      handler(rootFolderId) {
        this.storageDrawerMixins_getStorage(rootFolderId);
      },
    },
    folderId: {
      handler(folderId) {
        this.storageDrawerMixins_getStorage(folderId);
      },
    },
  },
  methods: {},
  async mounted() {
    await this.$store.dispatch("storage/resetState");
    this.storageDrawerMixins_storageInit();
  },
  destroyed() {
    clearTimeout(this.initTimeout);
  },
  mixins: [storageDrawerMixins],
};
</script>

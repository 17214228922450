<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto"
  >
    <div class="d-flex flex-column pa-4">
      <div class="mb-4">
        <div class="mb-1 d-flex">
          <div class="font-weight-bold body-2">
            {{ $t("formManagement.signatureSession.navMenu.IN_PROGRESS.title") }}
          </div>
          <v-spacer />

          <AppColorChip
            style="max-height: 24px"
            :text="statusChipText()"
            color="warning--text"
          />
        </div>
        <div>
          {{ createdDate }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{ $t("formManagement.signatureSession.navMenu.IN_PROGRESS.sentBy") }}
        </div>
        <AppUserAvatar
          :user="sender"
          :truncate="30"
          show-name
        />
      </div>

      <div class="font-weight-bold body-2">
        {{ $t("formManagement.signatureSession.navMenu.IN_PROGRESS.signBy") }}
      </div>

      <div>
        <v-divider class="my-2" />
        <div
          v-for="(signer, index) of signers"
          :key="index"
        >
          <div class="d-flex flex-column">
            <div class="mb-1">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.name") }}</span>
                <v-spacer />
                <AppColorChip
                  :text="statusText(signer)"
                  :color="statusColor(signer.status)"
                />
              </div>
              <AppTextTruncateValue
                :disabled="signer.name.length <= truncateLength"
                :text="signer.name"
                :truncate="truncateLength"
              />
              <v-spacer />
            </div>
            <div class="d-flex align-end">
              <div>
                <div>
                  <div class="d-flex">
                    <span class="text-caption">{{ $t("common.email") }}</span>
                  </div>
                  <a
                    class="text-decoration-none primary-link--text"
                    :href="`mailto:${signer.email}`"
                    target="_blank"
                  >
                    <AppTextTruncateValue
                      :disabled="signer.email.length <= truncateLength"
                      :text="signer.email"
                      :truncate="truncateLength"
                    />
                  </a>
                </div>

                <div v-if="signer.declineComment">
                  <div class="d-flex mt-1">
                    <span class="text-caption">
                      {{
                        $t(
                          "formManagement.signatureSession.navMenu.IN_PROGRESS.declineComment",
                        )
                      }}
                    </span>
                  </div>
                  <span>{{ signer.declineComment }}</span>
                </div>
                <div v-if="signer.comment">
                  <div class="d-flex mt-1">
                    <span class="text-caption">
                      {{ $t("common.comment") | capitalize }}
                    </span>
                  </div>
                  <span>{{ signer.comment }}</span>
                </div>
              </div>
              <v-spacer />
              <v-btn
                depressed
                color="primary"
                small
                v-if="canSignerSignNow(signer)"
                :loading="isLoadingSignLink"
                @click="openSignLink"
              >
                {{ $t("formManagement.signatureSession.navMenu.IN_PROGRESS.signNow") }}
              </v-btn>
            </div>
          </div>

          <v-divider class="my-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSignLink } from "@/services/form/formSignSession";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      truncateLength: 35,
      signLink: null,
      isLoadingSignLink: false,
    };
  },
  props: {
    signatureSession: Object,
  },
  computed: {
    canAnySignerSignNow() {
      return this.signers.some(
        (signer) =>
          signer.status === "IN_PROGRESS" && signer.email === this.currentUserEmail,
      );
    },
    sender() {
      return this.signatureSession?.user || null;
    },
    signers() {
      return this.signatureSession?.signers || [];
    },
    createdDate() {
      if (!this.signatureSession.createdAt) return "";
      return moment(this.signatureSession.createdAt).format("DD.MM.YYYY HH:mm");
    },
    allSignersThatHasCompleted() {
      return this.signers.filter((signer) => signer.status === "COMPLETED");
    },
    completedCount() {
      return this.allSignersThatHasCompleted.length;
    },
    ...mapGetters("auth", {
      currentUserEmail: "currentUserEmail",
    }),
  },
  methods: {
    openSignLink() {
      window.open(this.signLink, "_blank");
    },
    canSignerSignNow(signer) {
      return signer.status === "IN_PROGRESS" && signer.email === this.currentUserEmail;
    },
    handleGetSignLink() {
      if (this.canAnySignerSignNow) {
        this.getSignLink();
      }
    },
    getSignLink() {
      this.isLoadingSignLink = true;
      getSignLink({ params: { formId: this.signatureSession.formId } })
        .then((data) => {
          this.signLink = data.signLink;
        })
        .finally(() => {
          this.isLoadingSignLink = false;
        });
    },
    showReminder(signer) {
      if (signer.status === "COMPLETED") return false;
      return true;
    },
    sendReminder(signer) {
      if (!this.showReminder(signer)) return;
      this.$store.dispatch("inProgressDocumentProperties/sendReminder", {
        storageId: this.storageId,
        email: signer.email,
      });
    },
    statusChipText() {
      const count = `${this.completedCount}/${this.signers.length}`;
      const translationText = this.$t("formManagement.signatureSession.status.COMPLETED");
      const inProgressText = `${count} ${translationText}`;

      return inProgressText;
    },
    statusColor(status) {
      let color = "primary";
      if (status === "IN_PROGRESS") {
        color = "warning";
      } else if (status === "COMPLETED") {
        color = "success";
      } else if (status === "DECLINED") {
        color = "error";
      }
      return `${color}--text`;
    },
    statusText(signer) {
      let date = "";
      if (signer.status === "COMPLETED") {
        date = moment(signer.signedAt).format("DD.MM.YYYY HH:mm");
      } else if (signer.status === "DECLINED") {
        date = moment(signer.declinedAt).format("DD.MM.YYYY HH:mm");
      }
      return `${this.$t(
        `formManagement.signatureSession.status.${signer.status}`,
      )} ${date}`;
    },
  },
  mounted() {
    this.handleGetSignLink();
  },
};
</script>

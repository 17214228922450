<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("users.editUserDialog.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editForm"
          v-model="form.valid"
          v-on:submit.prevent
          @submit="onConfirm"
        >
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.firstName") }}
            </div>
            <AppDefaultTextField
              v-model="form.firstName.value"
              :rules="form.firstName.rules"
              autofocus
            />
            <div class="label--small">
              {{ $t("common.lastName") }}
            </div>
            <AppDefaultTextField
              v-model="form.lastName.value"
              :rules="form.lastName.rules"
              autofocus
            />
            <v-form
              ref="phone"
              v-model="form.phone.valid"
              v-on:submit.prevent
            >
              <div class="p-relative">
                <div class="label--small">
                  {{ $t("settings.account.details.phoneNumber") }}
                </div>
                <AppPhoneInput
                  v-model="form.phone.value"
                  hide-label
                />
              </div>
            </v-form>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="onCancel"
          :loading="isCompleting"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { updateUser } from "@/services/user/userService";

export default {
  props: {
    dialog: Boolean,
    item: Object,
  },
  data() {
    return {
      isCompleting: false,
      form: {
        valid: false,
        firstName: {
          value: null,
          rules: [],
        },
        lastName: {
          value: null,
          rules: [],
        },
        phone: {
          value: null,
        },
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fillFormWithData();
      }
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("dialog:change", val);
      },
    },
    currentPhone() {
      return this.item.meta.find((meta) => {
        return meta.field === "PHONE";
      })?.value;
    },
  },
  methods: {
    fillFormWithData() {
      if (this.item) {
        this.form.firstName.value = this.item.firstName;
        this.form.lastName.value = this.item.lastName;
        this.form.phone.value = this.currentPhone;
      }
    },

    closeDialog() {
      this.$emit("dialog:change", false);
    },
    resetDialog() {
      if (this.$refs.editForm) {
        this.$refs.editForm.reset();
      }
    },
    resetValidation() {
      if (this.$refs.editForm) {
        this.$refs.editForm.resetValidation();
      }
    },
    isValid() {
      const isValid = this.$refs?.editForm?.validate() || false;
      return isValid;
    },
    onCancel() {
      this.closeDialog();
      this.resetValidation();
    },
    onConfirm() {
      const isValid = this.isValid();
      if (isValid && this.item.id) {
        this.isCompleting = true;
        const data = {
          firstName: this.form.firstName.value,
          lastName: this.form.lastName.value,
          phone: this.form.phone.value,
        };
        updateUser({ data, id: this.item.id })
          .then((res) => {
            this.closeDialog();
            this.resetDialog();
            this.$emit("afterEdit:completed");
          })
          .finally(() => {
            this.isCompleting = false;
          });
      }
    },
  },
};
</script>

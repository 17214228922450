<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    hide-overlay
    no-click-animation
  >
    <v-card color="background">
      <div
        class="dialog__close-btn"
        style="z-index: 1"
      >
        <v-btn
          icon
          @click="close"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-container class="mw-1000px">
            <div class="d-flex pt-12">
              <span class="text-h4 font-weight-black">
                {{ $t("fileManagement.merge.step1.title") }}
              </span>
              <v-spacer />
              <v-btn
                depressed
                color="primary"
                @click="toStep2"
              >
                {{ $t("fileManagement.merge.step1.selectWhereToSave") }}
              </v-btn>
            </div>
            <div class="pb-11">
              <span>
                {{
                  `${$tc(
                    "fileManagement.merge.documentsOrImagesAdded",
                    items.length,
                  )} ${$t("fileManagement.merge.closePageToSelectMore")}`
                }}
              </span>
            </div>
            <v-form
              ref="nameForm"
              v-model="name.isValid"
            >
              <div class="p-relative">
                <div class="font-weight-bold pb-1 required-asterisk">
                  {{ $t("fileManagement.merge.step1.typeFilename") }}
                </div>
                <AppDefaultTextField
                  v-model="name.value"
                  :rules="$rules.NAME_RULES"
                  background-color="ui-background"
                  :placeholder="$t('fileManagement.merge.step1.fileNamePlaceholder')"
                  autofocus
                  :dense="false"
                />
              </div>
            </v-form>
            <div class="d-flex justify-center">
              <Draggable
                class="d-flex flex-wrap"
                v-model="localItems"
                @start="isDragging = true"
                @end="onDragEnd"
                style="gap: 16px"
                :animation="$constant.DRAG_SETTINGS.ANIMATION_DURATION"
                :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE"
                :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS"
                :forceFallback="true"
                filter=".block-draggable"
              >
                <div
                  v-for="(item, index) in localItems"
                  :key="index"
                >
                  <v-card
                    width="182"
                    height="200"
                    class="pa-5 d-flex flex-column align-center rounded user-select-none border-a"
                    style="cursor: grab"
                    flat
                  >
                    <AppStorageThumbnail
                      class="mb-4"
                      :item="item"
                      large
                      top
                    />
                    <AppTextTruncateValue
                      :disabled="item.name.length < fileNameTruncateLength || isDragging"
                      :text="item.name"
                      :truncate="fileNameTruncateLength"
                      break-word
                    />
                    <v-spacer />

                    <div class="block-draggable">
                      <AppDefaultTooltip
                        top
                        :disabled="isDragging"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            @mousedown.stop
                            @click.stop="removeItem(item)"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("fileManagement.merge.step1.removeFile") }}</span>
                      </AppDefaultTooltip>
                    </div>
                  </v-card>
                </div>
              </Draggable>
            </div>
          </v-container>
        </v-window-item>
        <v-window-item :value="2">
          <v-container class="mw-1000px">
            <div class="d-flex pt-12">
              <span class="text-h4 font-weight-black">
                {{ $t("fileManagement.merge.step2.title") }}
              </span>
              <v-spacer />
              <v-btn
                class="mr-2"
                @click="step = 1"
                outlined
              >
                {{ $t("fileManagement.merge.step2.goBack") }}
              </v-btn>
              <v-btn
                depressed
                color="primary"
                @click="merge"
                :disabled="!canMerge"
              >
                {{ $t("fileManagement.merge.step2.mergeAndSave") }}
              </v-btn>
            </div>
            <div class="pb-11">
              <span>
                {{
                  `${$tc(
                    "fileManagement.merge.documentsOrImagesAdded",
                    items.length,
                  )} ${$t("fileManagement.merge.closePageToSelectMore")}`
                }}
              </span>
            </div>

            <div>
              {{ $t("fileManagement.merge.step2.fileWillBeSavedTo") }}
              <span class="font-weight-black">{{ currentFolderText }}</span>
            </div>
            <v-toolbar
              flat
              class="background"
              height="50"
            >
              <AppFolderPath
                :prependPath="prependPath"
                :truncatedPath="truncatedPath"
                @item:click="onPathItemClick"
              ></AppFolderPath>
              <v-progress-linear
                :active="isFoldersLoading"
                :indeterminate="isFoldersLoading"
                absolute
                bottom
              />
            </v-toolbar>

            <template v-if="isFoldersLoading" />
            <template v-else>
              <v-card
                flat
                class="my-2 pa-4 d-flex align-center border-a rounded"
                v-for="folder in folders"
                :key="folder.id"
                @click="onFolderClick(folder)"
              >
                <v-icon class="pr-2">{{ $icons.LIGHT.FILE.FOLDER }}</v-icon>
                <div class="break-word">
                  {{ folder.name }}
                </div>
                <v-spacer />
                <v-icon>{{ $icons.LIGHT.COMMON.ANGLE_RIGHT }}</v-icon>
              </v-card>
            </template>
          </v-container>
        </v-window-item>
        <v-window-item :value="3">
          <v-container
            class="d-flex flex-column align-center justify-center h-100vh mw-1000px text-center"
          >
            <img
              src="@/assets/plane_merge.svg"
              style="max-width: 100%"
            />
            <div
              class="pt-10 text-h4 font-weight-bold"
              v-html="$t('fileManagement.merge.step3.title')"
            ></div>
            <div
              class="pt-3"
              v-html="$t('fileManagement.merge.step3.subTitle')"
            ></div>
            <div class="pt-13">
              <v-btn
                depressed
                color="primary"
                @click="close"
              >
                {{ $t("fileManagement.merge.step3.closeWindow") }}
              </v-btn>
            </div>
          </v-container>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import Draggable from "vuedraggable";
import { storageMixins } from "@/helpers/mixins";
import { mapState, mapGetters } from "vuex";

export default {
  mixins: [storageMixins],
  props: {
    dialog: Boolean,
    items: [],
    parentId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      step: 1,
      isDragging: false,
      active: true,
      fileNameTruncateLength: 43,
      name: {
        isValid: false,
        value: "",
      },
      oldParentId: null,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (!this.isStepTwo || !this.isNewParent) return;

        this.getFolders(this.parentId ?? this.domainId);
      }
    },
  },
  computed: {
    ...mapGetters("project", {
      projectFolderId: "projectFolderId",
    }),
    rootId() {
      return !!this.$route.params.projectId ? this.projectFolderId : this.domainId;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    localItems: {
      get() {
        return this.items;
      },
      set(values) {
        this.$emit("items:change", values);
      },
    },
    ...mapState("storageMergeToPdf", {
      folders: (state) => state.items,
      currentFolder: (state) => state.currentFolder,
      truncatedPath: (state) => state.truncatedPath,
      prependPath: (state) => state.prependPath,
    }),
    ...mapGetters("storageMergeToPdf", {
      isFoldersLoading: "isLoading",
    }),
    ...mapGetters("auth", {
      domainId: "activeDomainId",
    }),
    canMerge() {
      const hasCurrentFolder = this.currentFolder ? true : false;
      return hasCurrentFolder && !this.isFoldersLoading;
    },
    currentFolderText() {
      if (!this.currentFolder) return "";
      const text =
        this.currentFolder.level === 0 && this.currentFolder.name === "root"
          ? this.$t("common.home")
          : this.currentFolder.name;
      return text;
    },
    isStepOne() {
      return this.step === 1;
    },
    isStepTwo() {
      return this.step === 2;
    },
    isStepThree() {
      return this.step === 3;
    },
    isNewParent() {
      return this.oldParentId !== this.parentId;
    },
  },
  methods: {
    getFolders(id) {
      this.oldParentId = this.parentId;

      const data = {
        id,
        rootId: this.rootId,
      };

      this.$store.dispatch("storageMergeToPdf/getFolders", data);
      this.$store.dispatch("storageMergeToPdf/getPath", data);
    },
    removeItem(item) {
      this.$emit("item:remove", item);
    },
    onFolderClick(item) {
      this.getFolders(item.id);
    },
    onPathItemClick(item) {
      if (this.isFoldersLoading) return;
      this.getFolders(item.id);
    },
    showSnack(text) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text,
        color: "error",
        timeout: 5000,
      });
    },
    toStep2() {
      if (!this.isStep1Valid()) return;
      this.getFolders(this.parentId ?? this.domainId);
      this.step = 2;
    },
    isStep1Valid() {
      const isNameValid = this.$refs.nameForm.validate();
      const isEnoughItems = this.items.length >= 2;
      if (!isEnoughItems)
        this.showSnack(this.$t("fileManagement.merge.step1.step1ValidationErrorSnack"));
      return isNameValid && isEnoughItems;
    },
    merge() {
      this.$store.dispatch("storageMergeToPdf/mergeFiles", {
        name: this.name.value,
        fileIds: this.items.map((i) => i.id),
        folderId: this.currentFolder.id,
      });
      this.step = 3;
    },
    close() {
      if (this.isStepThree) {
        this.resetData();
      }

      this.localDialog = false;
      if (this.$refs.nameForm) {
        this.$refs.nameForm.resetValidation();
      }
    },
    resetData() {
      this.step = 1;
      this.name.value = "";
      this.localItems = [];
    },
    onDragEnd() {
      this.isDragging = false;
    },
  },
  components: {
    Draggable,
  },
};
</script>

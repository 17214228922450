<template>
  <v-btn
    depressed
    color="primary"
    v-bind="$attrs"
    v-on="$listeners"
    text
  >
    {{ $t("dataTable.editHeaders.edit") }}
  </v-btn>
</template>

<script>
export default {};
</script>

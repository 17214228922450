<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ props, on }">
        <div
          class="label--small primary-link--text cursor-pointer"
          v-bind="props"
          v-on="on"
        >
          {{ $t("common.viewDetails") }}
        </div>
      </template>

      <v-card min-width="200">
        <v-card-text class="primary-text--text">
          <div>
            <div
              class="d-flex pt-1 flex-column pb-3"
              v-for="(item, i) in addressItems"
              :key="i"
            >
              <div class="label--small">{{ labelTranslation(item) }}</div>
              <div class="text-body-1">{{ item.value }}</div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    addressItems: Array,
  },

  data() {
    return { menu: false };
  },
  computed: {},
  methods: {
    labelTranslation(item) {
      return this.$t(`addressDetails.${item.key}`);
    },
  },
};
</script>

<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.eventLog") }}
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-center">
        <template v-if="isFeatureActive">
          <span class="pr-1 success--text">{{ $t("common.active") }}</span>
          <v-icon
            color="success"
            x-small
          >
            {{ $icons.LIGHT.COMMON.CIRCLE }}
          </v-icon>
        </template>
        <template v-else>
          <span class="pr-1 error--text">{{ $t("common.inactive") }}</span>
          <v-icon
            color="error"
            x-small
          >
            {{ $icons.LIGHT.COMMON.CIRCLE }}
          </v-icon>
        </template>
      </div>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <template v-if="!isLoading">
      <AppLogList
        class="pt-5"
        :items="items"
        :isLoadingMore="isLoadingMore"
        :canLoadMore="canLoadMore"
        @click:load-more="onLoadMore"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      isFeatureActive: false,
    };
  },
  props: {
    itemId: String,
  },
  computed: {
    ...mapGetters("auth", {
      features: "features",
      isHistoricalLogFeatureEnabled: "isHistoricalLogFeatureEnabled",
    }),
    ...mapState("history", {
      items: (state) => state.items,
      isLoading: (state) => state.isLoading,
      isLoadingMore: (state) => state.isLoadingMore,
      canLoadMore: (state) => state.canLoadMore,
      offset: (state) => state.offset,
    }),
  },
  watch: {
    features: {
      handler(newVal, oldVal) {
        this.featureActive();
      },
      immediate: true,
      deep: true,
    },
    itemId: {
      handler(itemId) {
        if (itemId) {
          this.getHistory(itemId, 0);
        }
      },
    },
  },
  methods: {
    getHistory(id, offset) {
      this.$store.dispatch("history/getHistory", { id, offset });
    },
    onLoadMore() {
      this.getHistory(this.itemId, this.offset);
    },
    featureActive() {
      if (this.isHistoricalLogFeatureEnabled) {
        this.isFeatureActive = true;
      } else {
        this.isFeatureActive = false;
      }
    },
  },
  mounted() {
    this.getHistory(this.itemId, 0);
  },
};
</script>

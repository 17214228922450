<template>
  <div class="d-flex flex-column mw-650px">
    <div class="text-h4 pb-2 text-center font-weight-bold">
      {{ $t(`integration.${importType}.${state}.title`) }}
    </div>
    <div class="text--disabled pb-10 text-center">
      {{ $t(`integration.${importType}.${state}.subTitle`) }}
      <AppLinkText
        @click="onDownloadSampleClick"
        underline-on-hover
      >
        {{ $t(`integration.${importType}.${state}.downloadSampleButton`) }}
      </AppLinkText>
    </div>
    <file-pond
      name="logo"
      ref="pond"
      acceptedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      instantUpload="true"
      styleLoadIndicatorPosition="right top"
      styleProgressIndicatorPosition="right top"
      styleButtonRemoveItemPosition="right top"
      styleButtonProcessItemPosition="right top"
      @init="handleFilePondInit"
      :credits="[]"
      @addfile="onAddFile"
      @removefile="onRemoveFile"
      @processfileundo="onRemoveFile"
    />
    <div class="text-center pt-10">
      <v-btn
        class="mr-2"
        text
        @click="onBack"
      >
        {{ $t("common.back") }}
      </v-btn>
      <v-btn
        color="primary"
        depressed
        @click="onStartImport"
        :disabled="!hasFile"
      >
        {{ $t(`integration.${importType}.${state}.next`) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import en from "filepond/locale/en-en";
import nb from "filepond/locale/no_nb";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  components: {
    FilePond,
  },
  data() {
    return {
      file: null,
      hasFile: false,
    };
  },
  props: {
    state: String,
    importType: String,
  },
  methods: {
    onAddFile() {
      this.hasFile = true;
    },
    onRemoveFile() {
      this.hasFile = false;
    },
    onStartImport() {
      const { file } = this.$refs.pond.getFile();
      const formData = new FormData();
      formData.append("file", file, file.name);
      this.$emit("import:start", formData);
    },
    onBack() {
      this.$emit("back:click");
    },
    onDownloadSampleClick() {
      location.href = "https://gripr.blob.core.windows.net/assets/import-products.xlsx";
    },
    handleFilePondInit() {},
  },
  async mounted() {
    if (this.$i18n.locale === "en") {
      setOptions(en);
    } else {
      setOptions(nb);
    }
    setOptions({
      server: {
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort,
          transfer,
          options,
        ) => {
          load();
        },
        load: async (source, load, error, progress, abort, headers) => {
          load();
        },
        remove: async (source, load, error) => {
          load();
        },
        revert: async (uniqueFileId, load, error) => {
          load();
        },
      },
    });
  },
};
</script>

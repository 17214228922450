<template>
  <!-- Not frequently used. Can be debated whether to remove use AppTextTruncateValue in 
  the future to prevent unnecessarily complicated code and prevent tooltips when not truncating -->
  <div
    class="d-flex w-100"
    style="max-width: 100%"
  >
    <AppDefaultTooltip
      top
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="text-truncate w-100"
          v-bind="attrs"
          v-on="on"
        >
          {{ text }}
        </div>
      </template>
      <span class="break-word">{{ text }}</span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <v-expansion-panel-header
    class="remove-v-expansion-panel-header-min-height"
    style="
      padding: unset !important;
      min-height: unset !important;
      background-color: inherit !important;
    "
  >
    <template v-slot:actions="props">
      <v-btn
        icon
        :class="buttonClass"
      >
        <v-icon
          class="toggleUpDown"
          :class="{ rotate: props?.open }"
        >
          {{ "$expand" }}
        </v-icon>
      </v-btn>
    </template>
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="item"
    >
      <slot
        :name="slot"
        v-bind="item"
      ></slot>
    </template>
  </v-expansion-panel-header>
</template>

<script>
export default {
  props: {
    buttonClass: String,
  },
};
</script>

<style>
.toggleUpDown {
  transition: transform 0s !important;
}

.toggleUpDown.rotate {
  transform: rotate(180deg);
}
</style>

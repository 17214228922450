<template>
  <div>
    <AppTimeEntryDialog
      v-model="dialog.updateTimeEntry.active"
      :title="$t('timeRegistration.newTimeEntry.editTitle')"
      :data="dialog.updateTimeEntry.data"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteTimeEntry.active"
      :title="
        $tc('timeRegistration.deleteTimeEntry.title', dialog.deleteTimeEntry.item?.length)
      "
      :validator="$t('timeRegistration.delete').toLowerCase()"
      :validatorText="
        $t('timeRegistration.deleteTimeEntry.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      :item="dialog.deleteTimeEntry.item"
      @delete="deleteTimeEntries"
    />
    <AppConfirmationTextareaDialog
      v-model="dialog.giveDeclineReason.active"
      :title="$t('timeRegistration.giveDeclineReason.title')"
      :textareaLabel="$t('timeRegistration.giveDeclineReason.label')"
      :data="dialog.giveDeclineReason.ids"
      @confirm="
        updateTimeEntries({
          body: {
            declineComment: $event.comment,
            status: $constant.TIME_ENTRY_STATUS.DECLINED,
          },
          ids: $event.data,
        })
      "
    />
    <AppTimeEntryDeclineReasonDialog
      v-model="dialog.seeDeclineReason.active"
      :timeEntry="dialog.seeDeclineReason.timeEntry"
    />
    <AppDataTableServerSidePagination
      ref="dataTable"
      :loading="isLoading"
      :defaultHeaders="defaultHeaders"
      :items="timeEntries"
      :noDataText="$t('common.noTimeEntries')"
      :search="search"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      :bulkActionConfigs="bulkActionConfigs"
      :getAllItems="getAllItems"
      removeMutationType="timeEntries/removeTimeEntry"
      updateMutationType="timeEntries/updateTimeEntry"
      show-select
    >
      <template
        v-slot:[`group.header`]="{
          toggle,
          group,
          items,
          isOpen,
          headers,
          isGroupIndeterminate,
          isGroupSelected,
          toggleGroup,
        }"
      >
        <td
          @click.stop="toggleGroup"
          class="cell-interactable d-flex align-center justify-center"
          v-ripple
        >
          <AppDefaultCheckboxIcon
            :isIndeterminate="isGroupIndeterminate"
            :isSelected="isGroupSelected"
          />
        </td>
        <td :colspan="headers.length - 9">
          <div class="d-flex align-center">
            <v-btn
              class="mr-4"
              icon
              @click.stop="toggle"
            >
              <v-icon>
                {{
                  isOpen
                    ? $icons.LIGHT.COMMON.CHEVRON_DOWN
                    : $icons.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div
              class="group-header__title"
              :class="groupByMixins_headerTitleColor(group)"
            >
              <v-icon
                v-if="groupByMixins_isGroupSelected(filtersMixins_dataFields.STATUS)"
                :color="headerIconColor(group)"
                class="pr-3"
              >
                {{ $icons.SOLID.ACTION.STATUS }}
              </v-icon>
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>

        <td colspan="4"></td>
        <td>
          <div class="text-end subtitle--text text-no-wrap">
            <span class="font-weight-bold">
              {{ $t("timeRegistration.common.total") | capitalize }}
            </span>
          </div>
        </td>

        <td>
          <div class="text-end subtitle--text text-no-wrap">
            {{
              timeMixins_formatHoursShort(
                timeMixins_aggregateDuration(
                  items?.filter((item) => !item.isExtra),
                  filtersMixins_dataFields.DURATION,
                ),
              )
            }}
          </div>
        </td>
        <td>
          <div class="text-end subtitle--text text-no-wrap">
            {{
              timeMixins_formatHoursShort(
                timeMixins_aggregateDuration(
                  items,
                  filtersMixins_dataFields.BREAK_DURATION,
                ),
              )
            }}
          </div>
        </td>
        <td></td>
      </template>
      <template v-slot:item="{ item, isSelected, select, headers, index }">
        <AppTimeEntryItem
          :timeEntry="item"
          :select="select"
          :isSelected="isSelected"
          :isContextMenuActiveItem="isContextMenuActiveItem(item)"
          :headers="headers"
          :tableModel="tableModel"
          @menu:click="openContextMenu"
          @cell:click="handleInteractableClick"
          @imagePreview:click="onImagePreviewClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppDataTableContextMenu
      ref="contextMenu"
      :actionConfigs="actionConfigs"
      :items="[contextMenuActiveItem]"
      @input="onContextMenuChange"
    />
    <AppFilePreviewDialog
      v-model="dialog.filePreview.fileId"
      :fileIds="dialog.filePreview.fileIds"
      @dialog:close="onImagePreviewDialogClose"
      :canEditFile="false"
    />
    <AppDateRangePickerDialog
      v-model="dialog.copyTimeEntryDateRangePicker.active"
      :selectedItems="dialog.copyTimeEntryDateRangePicker.items"
      @confirm="onCopyTimeEntryByDateRange"
    />
    <AppContextMenu ref="statusMenu">
      <AppChangeStatusMenuItems
        :status="itemsToEdit.length === 1 ? itemsToEdit[0].status : null"
        :statuses="statusMixins_timeEntriesStatusItems"
        :itemsDisabled="
          timeEntryActionMixins_isChangeStatusesItemsDisabled(
            itemsToEdit,
            itemsToEdit.length > 1,
          )
        "
        @change="(e) => onStatusesChange({ status: e, items: itemsToEdit })"
      />
    </AppContextMenu>
    <AppContextMenu ref="copyMenu">
      <AppMenuActionBtn
        v-for="action in copyActionConfigs"
        :key="action.key"
        v-bind="action.props"
        v-on="action.on"
        :selectedItems="itemsToEdit"
      />
    </AppContextMenu>
  </div>
</template>

<script>
import {
  timeMixins,
  planRestrictionsMixins,
  statusMixins,
  groupByMixins,
} from "@/helpers/mixins";
import moment from "moment";
import { mapGetters } from "vuex";
import { timeEntryActionMixins } from "@/helpers/mixins/tableActionMixins/timeEntryActionMixins";
import { createTableHeaders } from "@/helpers/util";

export default {
  mixins: [
    timeMixins,
    planRestrictionsMixins,
    statusMixins,
    groupByMixins,
    timeEntryActionMixins,
  ],
  props: {
    timeEntries: Array,
    isLoading: Boolean,
    search: String,
    serverItemsLength: Number,
    tableModel: String,
    getAllItems: Function,
  },
  data() {
    return {
      dialog: {
        updateTimeEntry: {
          data: null,
          active: false,
        },
        deleteTimeEntry: {
          active: false,
          item: [],
        },
        giveDeclineReason: {
          active: false,
          ids: null,
        },
        seeDeclineReason: {
          active: false,
          timeEntry: null,
        },
        upgradePlan: {
          active: false,
          planCode: null,
        },
        filePreview: {
          fileId: null,
          fileIds: [],
        },
        copyTimeEntryDateRangePicker: {
          active: false,
          items: [],
        },
      },
      contextMenuActiveItem: null,
      itemsToEdit: [],
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    ...mapGetters("timeEntries", {
      timeEntryById: "timeEntryById",
    }),
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isDomainHrAdmin: "isDomainHrAdmin",
      isDomainSupportUser: "isDomainSupportUser",
    }),
    updatedContextMenuActiveItem() {
      return (
        this.timeEntries.find((item) => item?.id === this.contextMenuActiveItem?.id) ||
        this.contextMenuActiveItem
      );
    },
    projectId() {
      return this.$route.params.projectId;
    },
    bulkActionConfigs() {
      return this.$constant.getBulkActionConfigs(this.actionConfigs);
    },
    copyActionConfigs() {
      return this.$constant.getSubmenuActionConfigsByType(
        this.actionConfigs,
        this.$constant.ACTION_KEYS.COPY,
      );
    },
    actionConfigs() {
      const {
        EDIT,
        EDIT_STATUS,
        SEND_TO_ACCOUNTING,
        DELETE_FROM_ACCOUNTING,
        COPY_TO_NEXT_DAY,
        COPY_TO_NEXT_WEEK,
        COPY_TO_REST_OF_WEEK,
        COPY_TO_DATE_OR_PERIOD,
        DELETE,
        DECLINE_REASON,
        COPY,
      } = this.$constant.ACTION_KEYS;

      const _openEditMenu = (menuRef) => (e) => {
        this.openEditMenu({
          menuRef,
          direction: e.hasSubMenu ? "right" : "top",
          parentMenuRef: e.hasSubMenu ? this.$refs.contextMenu : null,
          ...e,
        });
      };

      const customActions = [
        {
          key: EDIT,
          props: {
            disabled: ({ items }) => this.timeEntryActionMixins_isEditDisabled({ items }),
          },
          on: {
            click: this.onTimeEntryEdit,
          },
        },
        {
          key: COPY,
          props: {
            labelKey: "timeRegistration.copy.copyTo",
            hasSubMenu: true,
          },
          on: {
            click: _openEditMenu("copyMenu"),
          },
        },
        {
          key: EDIT_STATUS,
          props: {
            disabled: ({ items }) =>
              this.timeEntryActionMixins_isChangeStatusesDisabled(
                items,
                items?.length > 1,
              ),
          },
          on: {
            click: _openEditMenu("statusMenu"),
          },
        },
        {
          key: SEND_TO_ACCOUNTING,
          props: {
            hidden: !this.timeEntryActionMixins_showSendToAccounting,
            planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
            showStar: () => !this.planRestrictionsMixins_canIntegrateAccounting,
            disabled: ({ items }) =>
              this.timeEntryActionMixins_isEveryItemInAccounting({ items }),
          },
          on: {
            click: this.onSendToAccounting,
          },
        },
        {
          key: COPY_TO_NEXT_DAY,
          on: {
            click: ({ items }) => {
              this.onCopyClick({ actionKey: COPY_TO_NEXT_DAY, items });
            },
          },
        },
        {
          key: COPY_TO_NEXT_WEEK,
          on: {
            click: ({ items }) => {
              this.onCopyClick({ actionKey: COPY_TO_NEXT_WEEK, items });
            },
          },
        },
        {
          key: COPY_TO_REST_OF_WEEK,
          on: {
            click: ({ items }) => {
              this.onCopyClick({ actionKey: COPY_TO_REST_OF_WEEK, items });
            },
          },
        },
        {
          key: COPY_TO_DATE_OR_PERIOD,
          on: {
            click: ({ items }) => {
              this.onCopyClick({ actionKey: COPY_TO_DATE_OR_PERIOD, items });
            },
          },
        },
        {
          key: DELETE_FROM_ACCOUNTING,
          props: {
            hidden: !this.timeEntryActionMixins_showSendToAccounting,
            planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
            showStar: () => !this.planRestrictionsMixins_canIntegrateAccounting,
            disabled: ({ items }) =>
              this.timeEntryActionMixins_isNoItemsInAccounting({ items }),
          },
          on: {
            click: this.deleteInAccounting,
          },
        },
        {
          key: DELETE,
          props: {
            disabled: ({ items }) =>
              this.timeEntryActionMixins_isDeleteDisabled({ items }),
          },
          on: {
            click: ({ items }) => this.onTimeEntriesDelete({ items }),
          },
        },
        {
          key: DECLINE_REASON,
          props: {
            hidden: ({ items }) =>
              !this.timeEntryActionMixins_isDeclined({
                item: items[0],
              }),
          },
          on: {
            click: this.onShowDeclinedReason,
          },
        },
      ];

      const config = this.$constant.generateActionConfig({
        primaryActions: customActions,
        secondaryActions: this.$constant.TIME_ENTRY_ACTIONS,
      });

      return config;
    },
    defaultHeaders() {
      return createTableHeaders([
        {
          preset: "SELECT",
        },
        {
          preset: "STATUS",
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          menuRef: "statusMenu",
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.date"),
          ),
          value: this.filtersMixins_dataFields.DATE,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.user"),
          ),
          value: this.filtersMixins_dataFields.USER,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.comment"),
          ),
          value: this.filtersMixins_dataFields.COMMENT,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.images"),
          ),
          value: this.filtersMixins_dataFields.IMAGE_IDS,
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.project"),
          ),
          value: this.filtersMixins_dataFields.PROJECT,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.task"),
          ),
          value: this.filtersMixins_dataFields.TASK,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.accounting")),
          value: this.filtersMixins_dataFields.TRANSFERRED,
          access: this.hasTimeEntriesIntegrationEnabled,
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hourType"),
          ),
          value: this.filtersMixins_dataFields.HOUR_TYPE,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.time"),
          ),
          value: this.filtersMixins_dataFields.TIME,
          align: "end",
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hours"),
          ),
          value: this.filtersMixins_dataFields.DURATION,
          align: "end",
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.break"),
          ),
          value: this.filtersMixins_dataFields.BREAK_DURATION,
          align: "end",
        },
        {
          preset: "MENU",
          value: this.filtersMixins_dataFields.MENU,
        },
      ]);
    },
  },
  methods: {
    openEditMenu(e) {
      const { items, menuRef } = e;
      this.$refs[menuRef].open(e);
      this.itemsToEdit = items;
    },
    openEditHeadersDialog() {
      this.$refs.dataTable.openEditHeadersDialog();
    },
    handleInteractableClick({ e, header }) {
      this.openEditMenu({ ...e, menuRef: header.menuRef, direction: "bottom" });
    },
    isContextMenuActiveItem(item) {
      return this.contextMenuActiveItem?.id === item?.id;
    },
    onCopyClick({ actionKey, items }) {
      const {
        COPY_TO_NEXT_DAY,
        COPY_TO_NEXT_WEEK,
        COPY_TO_REST_OF_WEEK,
        COPY_TO_DATE_OR_PERIOD,
      } = this.$constant.ACTION_KEYS;

      const { id, date } = items[0];
      let dateFrom, dateTo, weekDays;

      switch (actionKey) {
        case COPY_TO_NEXT_DAY:
          dateFrom = moment(date).add(1, "day").format("YYYY-MM-DD");
          dateTo = dateFrom;
          weekDays = this.dateMixins_weekdayValues;
          break;
        case COPY_TO_NEXT_WEEK:
          dateFrom = moment(date).add(1, "week").format("YYYY-MM-DD");
          dateTo = moment(date).add(1, "week").format("YYYY-MM-DD");
          weekDays = this.dateMixins_weekdayValues;
          break;
        case COPY_TO_REST_OF_WEEK:
          dateFrom = moment(date).add(1, "day").format("YYYY-MM-DD");
          dateTo = moment(date).endOf("week").format("YYYY-MM-DD");
          weekDays = this.dateMixins_weekdayValues.filter(
            (day) => day !== "SATURDAY" && day !== "SUNDAY",
          );
          break;
        case COPY_TO_DATE_OR_PERIOD:
          this.dialog.copyTimeEntryDateRangePicker.active = true;
          this.dialog.copyTimeEntryDateRangePicker.items = items;
          return;
        default:
          return;
      }

      const body = {
        ids: [id],
        dateFrom,
        dateTo,
        weekDays,
      };

      this.onTimeEntriesCopy(body);
    },
    onCopyTimeEntryByDateRange({ dates, days, items }) {
      const dateFrom = dates[0];
      const dateTo = dates[1] || dates[0];

      const body = {
        ids: items.map((item) => item.id),
        dateFrom,
        dateTo,
        weekDays: days,
      };

      this.onTimeEntriesCopy(body);
    },
    onTimeEntriesCopy(body) {
      this.$store
        .dispatch("timeEntries/copyTimeEntries", {
          body,
        })
        .then(() => {
          this.$emit("refresh");
        });
    },
    headerIconColor(group) {
      return this.statusMixins_statusColor(
        group,
        false,
        this.statusMixins_timeEntriesStatusItems,
      );
    },
    onSendToAccounting({ items }) {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }

      if (!this.timeEntryActionMixins_hasMappedHourTypes({ items })) {
        this.onHasUnmappedHourTypes();
        return;
      }

      if (!this.timeEntryActionMixins_isAllItemsApproved({ items })) {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.onlyApprovedTimeEntries",
          "warning",
        );
        return;
      }

      this.$store.dispatch("timeEntries/sendToAccounting", {
        ids: items.filter((te) => !te.transferred).map((te) => te.id),
      });
    },
    onHasUnmappedHourTypes() {
      const canEnterSettingsPage =
        this.isDomainAdmin || this.isDomainOwner || this.isDomainSupportUser;

      if (canEnterSettingsPage) {
        const route = this.$router.resolve({
          name: this.$routeNames.SETTINGS.SYSTEM_SETTINGS,
        });
        window.open(route.href, "_blank");
      } else {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.hrAdminCantConfigure",
          "error",
        );
      }
    },
    showSnackbar(messageKey, color) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t(messageKey),
        color,
      });
    },
    deleteInAccounting({ items }) {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }
      this.$store.dispatch("timeEntries/deleteInAccounting", {
        ids: items.filter((item) => item.transferred).map((item) => item.id),
      });
    },
    onStatusChange({ status, items }) {
      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = items[0];
        return;
      }
      this.updateTimeEntries({ body: { status }, ids: items.map((item) => item.id) });
    },
    onStatusesChange({ status, items }) {
      const ids = items.filter((item) => item.status !== status).map((te) => te.id);

      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = ids;
        return;
      }
      this.updateTimeEntries({ body: { status }, ids });
    },
    updateTimeEntries({ body, ids }) {
      this.$store.dispatch("timeEntries/updateTimeEntries", {
        ids,
        body,
      });
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.STATUS))
        return this.$t(`statuses.timeEntry.${group}`);
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.TRANSFERRED))
        return this.$t(
          `timeRegistration.accounting.${group ? "inAccounting" : "notInAccounting"}`,
        );
      return group;
    },
    openContextMenu({ from, timeEntry, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = timeEntry;
      });
    },
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    onTimeEntriesDelete({ items }) {
      this.dialog.deleteTimeEntry.active = true;
      this.dialog.deleteTimeEntry.item = items;
    },
    deleteTimeEntries({ item, event }) {
      this.$store.dispatch("timeEntries/deleteTimeEntries", {
        ids: item.map((item) => item.id),
      });
    },
    onTimeEntryEdit() {
      this.dialog.updateTimeEntry.active = true;
      this.dialog.updateTimeEntry.data = this.contextMenuActiveItem;
    },
    onShowDeclinedReason() {
      if (!this.contextMenuActiveItem) return;

      this.dialog.seeDeclineReason.active = true;
      this.dialog.seeDeclineReason.timeEntry = this.contextMenuActiveItem;
    },
    onImagePreviewClick({ timeEntry }) {
      const fileIds = timeEntry.imageIds;
      const fileId = fileIds[0];
      if (!fileId) return;
      this.dialog.filePreview.fileId = fileId;
      this.dialog.filePreview.fileIds = fileIds.map((id) => ({ id }));
    },
    onImagePreviewDialogClose() {
      this.dialog.filePreview.fileId = null;
      this.dialog.filePreview.fileIds = [];
    },
    actionConfigByKey(key) {
      return this.actionConfigs.find((action) => action.key === key) || {};
    },
  },
};
</script>

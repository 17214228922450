<template>
  <AppContextMenu
    ref="menu"
    :closeOnContentClick="false"
    hide-default-padding
  >
    <v-date-picker
      ref="picker"
      v-model="date"
      @input="onSubmit"
      color="primary"
      show-week
      no-title
      locale-first-day-of-year="4"
    />
  </AppContextMenu>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },
  methods: {
    onSubmit(date) {
      this.$emit("submit", { date });
      this.date = null;
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
    },
  },
};
</script>

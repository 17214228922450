<template>
  <div
    class="p-relative"
    :class="{ 'hide-details-compensation': hideDetails }"
  >
    <div class="label--small d-flex">
      {{ $t(labelKey || defaultLabelKey) | capitalize }}
      <v-spacer></v-spacer>
      <AppAutocompleteOpenInNewTabBtn
        v-if="!multiple"
        :id="$attrs.value"
        :type="type"
      />
    </div>
    <AppProjectAutocomplete
      ref="autocomplete"
      v-bind="params"
      v-on="listeners"
    />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    hideDetails: Boolean,
    multiple: Boolean,
    type: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    defaultLabelKey() {
      if (this.type === "mainProject") {
        return "projectView.mainProject.title";
      }
      if (this.type === "project") {
        return "common.project";
      }
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    getProjects() {
      this.$refs.autocomplete.getProjects();
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>

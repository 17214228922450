import axios from "axios";
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getDocumentProperties(storageId) {
  return axios
    .get(`${integrationApiUrl}e-sign/signable-document/${storageId}`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

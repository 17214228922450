<template>
  <span :class="class1">
    <AppLinkText
      v-if="tos"
      link="https://www.gripr.no/avtalevilkar"
      underline-on-hover
    >
      {{ $t("common.tos") }}
    </AppLinkText>
    <span v-if="tos && pp">{{ $t("common.and") }}&nbsp;</span>
    <AppLinkText
      v-if="pp"
      link="https://www.gripr.no/personvernerklering"
      underline-on-hover
    >
      {{ $t("common.pp") }}
    </AppLinkText>
  </span>
</template>

<script>
export default {
  props: {
    required: Boolean,
    tos: {
      type: Boolean,
      default: true,
    },
    pp: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    class1() {
      return this.required ? "required-asterisk" : "";
    },
  },
};
</script>

<template>
  <span
    v-if="id"
    class="d-flex"
  >
    <v-list-item
      style="min-height: 20px !important"
      :to="{ name: newTabInfo.route, params: newTabInfo.params }"
      target="_blank"
      color="primary-link"
      :input-value="true"
    >
      <v-icon
        x-small
        color="primary-link"
        class="mr-2"
        style="margin-bottom: 2px"
      >
        {{ $icons.LIGHT.ACTION.POP_OUT }}
      </v-icon>
      <v-list-item-content style="padding: 0px">
        <v-list-item-title
          class="label--small"
          style="padding-bottom: 0px !important"
        >
          {{ $t(newTabInfo.text) }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </span>
</template>

<script>
import { getOpenInNewTabInfo } from "@/helpers/util/router";

export default {
  props: {
    type: String,
    id: String,
  },
  computed: {
    newTabInfo() {
      return getOpenInNewTabInfo(this.type, this.id);
    },
  },
};
</script>

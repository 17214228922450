<template>
  <div class="d-flex align-center freeze-divider-wrapper">
    <v-divider
      class="my-3"
      style="border-width: 2px; border-color: var(--v-primary-border-base)"
    ></v-divider>
    <v-chip
      small
      class="ma-2 user-select-none"
      color="primary"
      outlined
    >
      {{ $tc("dataTable.editHeaders.frozenColumnsAbove", count) }}
    </v-chip>
    <v-divider
      class="my-3"
      style="border-width: 2px; border-color: var(--v-primary-border-base)"
    ></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    count: Number,
  },
};
</script>

<style scoped>
.freeze-divider-wrapper {
  position: relative;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, top, position;
}
</style>

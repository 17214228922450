<template>
  <div v-if="user">
    <AppDefaultTooltip
      :left="left"
      :right="right"
      :top="top"
      :bottom="bottom"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          color="primary"
          :size="size"
        >
          <template v-if="avatar">
            <v-img :src="avatar" />
          </template>
          <template v-else>
            <span
              class="white--text"
              style="font-size: 0.7rem"
            >
              {{ user.initials }}
            </span>
          </template>
        </v-avatar>
      </template>
      <span>{{ user.name }}</span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
    size: {
      type: String,
      default: "26",
    },
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
  },
  computed: {
    avatar() {
      const avatar = this.user?.meta?.find((m) => m.field === "AVATAR");
      return avatar ? avatar.value : null;
    },
  },
};
</script>

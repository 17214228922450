<template>
  <v-dialog
    v-model="localDialog"
    max-width="700px"
    scrollable
  >
    <v-card
      class="pa-10 d-flex flex-column"
      height="790"
      width="700"
    >
      <img
        class="user-select-none"
        style="position: absolute; bottom: 0; right: 0; pointer-events: none"
        src="@/assets/gripr_hand_peace.svg"
      />
      <div class="dialog__close-btn">
        <v-btn
          icon
          dark
          @click="onClose"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <template v-if="isLoading || !plan">
        <div class="d-flex align-center justify-center h-100">
          <AppCircularLoader />
        </div>
      </template>
      <template v-else>
        <v-card-title>
          <div>
            <AppSubscriptionPlanStar
              large
              :planCode="planCode"
            />
            <div class="text-h6 pt-5">
              {{ title }}
            </div>
          </div>
        </v-card-title>
        <v-card-text class="primary-text--text">
          <v-alert
            v-if="activePlanCode === this.$constant.PLAN_CODE.GRIPR_TRIAL"
            dense
            type="info"
            text
            class="mb-2"
          >
            {{ $t("settings.billing.subscription.planCard.trialWillBeCancelled") }}
          </v-alert>
          <div>
            {{ subTitle }}
          </div>
          <AppPlanInfoItems
            class="pt-4"
            :limits="limits"
          />
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <div class="w-100">
            <AppAcceptTermsCheckbox
              v-if="canChangeSubscription"
              v-model="hasAcceptedTerms"
              style="margin-left: -8px"
              hide-details
              class="mb-2"
            />
            <AppDialogActionBtnPair
              @confirm="onConfirm"
              @cancel="onClose"
              :confirmText="$t('common.upgrade')"
              :loading="isUpgrading"
              :disabled="!canChangeSubscription || !hasAcceptedTerms"
            />
            <div class="text-caption pt-6">
              <span v-html="bottomText"></span>
              <AppLinkText
                @click="goToBillingPage"
                underline-on-hover
              >
                {{ $t("upgradePlanDialog.goToBillingPage") | capitalize }}
              </AppLinkText>
            </div>
          </div>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { billingMixins } from "@/helpers/mixins";

export default {
  mixins: [billingMixins],
  props: {
    dialog: Boolean,
    isLoading: Boolean,
    plan: Object,
    currency: String,
    intervalType: String,
    usedSeats: [String, Number],
    canChangeSubscription: Boolean,
    platformFeeSubscription: Object,
    activePlanCode: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      hasAcceptedTerms: false,
      isUpgrading: false,
    };
  },
  computed: {
    showPlatformFee() {
      return this.plan?.hasPlatformFee && !!this.platformFeeSubscription;
    },
    title() {
      return this.$t("upgradePlanDialog.title", {
        planName: this.planName,
        planPrice: this.calculatedPlanPrice,
        intervalType: this.$t(
          `settings.billing.subscription.planCard.intervals.${this.intervalType}`,
        ),
      });
    },
    subTitle() {
      return this.$t("upgradePlanDialog.subTitle", { planName: this.planName });
    },
    calculatedPlanPrice() {
      return this.billingMixins_priceFormat(this.planPrice, this.currency);
    },
    calculatedTotalPrice() {
      return this.billingMixins_priceFormat(
        this.planPrice * this.usedSeats,
        this.currency,
      );
    },
    calculatedPlatformFeePrice() {
      return this.billingMixins_priceFormat(
        this.platformFeeSubscription?.totalPrice,
        this.currency,
      );
    },
    bottomText() {
      if (this.canChangeSubscription) {
        const platformFeeText = this.showPlatformFee
          ? this.$t("upgradePlanDialog.platformFeeText", {
              platformFeePrice: this.calculatedPlatformFeePrice,
            })
          : "";

        return this.$t("upgradePlanDialog.termsAndConditions", {
          usedSeats: this.usedSeats,
          totalPrice: this.calculatedTotalPrice,
          intervalType: this.$t(
            `settings.billing.subscription.planCard.intervals.${this.intervalType}`,
          ),
          platformFeeText,
        });
      } else {
        return `
        ${this.$t("upgradePlanDialog.requiresUpgrade", {
          planName: this.planName,
        })} 
        </br>
        </br>
        ${this.$t("upgradePlanDialog.contactDomainOwner")}
        `;
      }
    },
    planName() {
      return this.plan?.name;
    },
    planPrice() {
      return this.plan?.price;
    },
    limits() {
      return this.plan?.limits;
    },
    planCode() {
      return this.plan?.code;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
    },
    onConfirm() {
      if (!this.hasAcceptedTerms) return;
      this.isUpgrading = true;
      this.$emit("complete", {
        onUpgradeComplete: () => this.onUpgradeComplete(),
        onUpgradeError: () => (this.isUpgrading = false),
        quantity: this.usedSeats,
        planCode: this.planCode,
      });
    },
    onUpgradeComplete() {
      this.closeDialog();
      this.isUpgrading = false;
    },
    goToBillingPage() {
      if (this.$route.name !== this.$routeNames.SETTINGS.BILLING_SUBSCRIPTION) {
        this.$router.replace({ name: this.$routeNames.SETTINGS.BILLING_SUBSCRIPTION });
      }

      this.localDialog = false;
    },
  },
};
</script>

<template>
  <v-menu
    offset-x
    right
    transition="slide-y-transition"
    :closeOnContentClick="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <div
          class="d-flex align-center pa-3"
          v-bind="attrs"
          v-on="on"
        >
          <AppDrawerMenu
            v-bind="attrs"
            v-on="on"
            :title="currentDomain ? currentDomain.name : ''"
            :subTitle="$t('common.workspace') | capitalize"
            :disableSubTitleTooltip="true"
          >
            <template slot="prepend">
              <img
                src="../../../../assets/gripr_logo_rect.svg"
                height="40px"
                :class="isMini ? '' : 'pr-4'"
              />
            </template>
          </AppDrawerMenu>
        </div>
      </div>
    </template>
    <v-card
      width="300"
      style="border-radius: 0"
    >
      <div class="pa-1">
        <AppDefaultTextField
          autofocus
          v-model="filter"
          :label="$t('common.search')"
          :append-icon="$icons.LIGHT.ACTION.SEARCH"
          hide-details
          :outlined="false"
          :dense="false"
          clearable
        />
      </div>
      <v-virtual-scroll
        :bench="benched"
        :items="filteredDomains"
        max-height="340"
        item-height="46"
        class="v-list"
      >
        <template v-slot:default="{ item: domain }">
          <v-list-item
            :key="domain.id"
            @click="onDomainChange(domain)"
            :disabled="isChangingDomain"
            :class="rowStateClasses(domain)"
          >
            <v-list-item-content>
              <div class="d-flex w-100">
                <AppTextTruncateBlocking
                  class="text-subtitle-2 font-weight-medium"
                  :text="domain.name"
                ></AppTextTruncateBlocking>
                <v-icon
                  style="position: absolute; right: 15px"
                  v-if="isChangingDomain && domain.id === changingDomainId"
                  color="primary"
                >
                  {{ $icons.SOLID.ACTION.LOADING }}
                </v-icon>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
      <v-divider class="primary-border" />
      <v-list>
        <v-list-item
          :disabled="isChangingDomain"
          @click="newWorkspaceDialog = true"
        >
          <v-list-item-title>
            <div class="d-flex align-center">
              <v-icon
                class="mr-4"
                :disabled="isChangingDomain"
              >
                {{ $icons.SOLID.ACTION.PLUS }}
              </v-icon>
              <div class="font-weight-bold">
                {{ $t("drawer.userMenu.workspace.createNew") }}
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
    <AppCreateUserWorkspaceDialog
      v-model="newWorkspaceDialog"
      @workspace:created="onWorkspaceCreate"
    />
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    miniVariant: Boolean,
  },
  computed: {
    ...mapGetters("auth", {
      currentDomain: "currentDomain",
      domains: "domains",
    }),
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    filteredDomains() {
      const filter = this.filter?.toLowerCase() || "";
      const filteredDomains = this.domains.filter((domain) => {
        const nameMatch = domain.name.toLowerCase().includes(filter);
        return nameMatch;
      });

      const active = this.currentDomain ? [this.currentDomain] : [];
      const mergedDomains = [...active, ...filteredDomains];
      const uniqueDomains = Array.from(
        new Set(mergedDomains.map((domain) => domain.id)),
      ).map((id) => mergedDomains.find((domain) => domain.id === id));

      return uniqueDomains;
    },
  },
  data() {
    return {
      newWorkspaceDialog: false,
      filter: "",
      benched: 10,
      isChangingDomain: false,
      changingDomainId: false,
    };
  },
  methods: {
    rowStateClasses(domain) {
      const isActiveDomain =
        domain.id === this.changingDomainId ||
        (domain?.id === this.currentDomain?.id && !this.isChangingDomain);

      return {
        list_tr: true,
        "list_tr--success": isActiveDomain,
        "list_tr--selected": isActiveDomain,
      };
    },
    onWorkspaceCreate() {
      location.reload();
    },
    async onDomainChange(domain) {
      this.changingDomainId = domain.id;
      this.isChangingDomain = true;
      await this.$store.dispatch("auth/changeActiveDomain", domain.id);
      location.href = "/";
    },
  },
};
</script>

<template>
  <div class="c-log-list">
    <AppLogListNode
      v-for="item of items"
      :key="item.id"
      :node="item"
    />
    <div class="c-log-list_action">
      <v-btn
        block
        outlined
        color="primary"
        :disabled="!canLoadMore"
        :loading="isLoadingMore"
        @click="onLoadMore"
      >
        {{ $t("common.loadMore") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    isLoadingMore: Boolean,
    canLoadMore: Boolean,
  },
  methods: {
    onLoadMore() {
      this.$emit("click:load-more");
    },
  },
};
</script>

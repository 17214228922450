<template>
  <div
    class="information_drawer"
    ref="sideNav"
  >
    <v-toolbar
      dense
      flat
      fixed
      height="68"
      :class="isLoading ? 'border-b' : ''"
    >
      <template v-if="!search.active">
        <v-toolbar-title class="font-weight-bold">
          {{ $t("common.products") | capitalize }}
        </v-toolbar-title>
        <v-spacer />
        <AppDefaultTooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openProductDialog({ products: [] })"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ $icons.LIGHT.ACTION.CIRCLE_PLUS }}</v-icon>
            </v-btn>
          </template>

          <span class="break-word">
            {{ $t("product.addProducts") }}
          </span>
        </AppDefaultTooltip>
        <v-btn
          icon
          @click="openSearch"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.SEARCH }}</v-icon>
        </v-btn>
        <NumSelectedBulk
          bottom
          :selectedCount="selectedProducts.length"
          @click="onClearSelection"
        />
      </template>

      <template v-if="search.active">
        <AppDefaultTextField
          v-model="search.value"
          :autofocus="true"
          :label="$t('common.search')"
          :append-icon="$icons.LIGHT.ACTION.SEARCH"
          hide-details
          :outlined="false"
          :dense="false"
          clearable
        />
        <v-btn
          class="ml-2"
          icon
          @click="closeSearch"
        >
          <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div
      class="overflow-y-auto secondary-height"
      v-if="!isLoading"
    >
      <AppSectionList
        v-model="selectedProducts"
        :sections="categorizedProductUsages"
        :isSectionsExpanded="isSectionsExpanded"
        :contextMenuActiveItem="contextMenuActiveItem"
      >
        <template v-slot:item="{ item, isSelected, select }">
          <AppProductUsageDrawerItem
            :item="item"
            :isSelected="isSelected"
            :select="select"
            @menu:click="openContextMenu"
          />
        </template>
      </AppSectionList>
    </div>
    <div
      class="p-absolute elevation-1 ui-background-black d-flex flex-column pa-2 rounded"
      style="top: 130px; left: -70px"
      v-if="showActionBar"
    >
      <AppDefaultTooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="text-capitalize"
            color="dark-background-text"
            text
            icon
            @click="openProductDialog({ products: selectedProducts })"
          >
            <v-icon>{{ $icons.LIGHT.ACTION.EDIT }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.edit") }}</span>
      </AppDefaultTooltip>
      <AppDefaultTooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="hasProductIntegrationEnabled"
            :disabled="!selectedProducts.every((product) => product.projectId)"
            v-bind="attrs"
            v-on="on"
            class="text-capitalize"
            color="dark-background-text"
            text
            icon
            @click="sendToAccounting({ products: selectedProducts })"
          >
            <v-icon>{{ $icons.LIGHT.ACTION.SEND_TO_ACCOUNTING }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.sendToAccounting") }}</span>
      </AppDefaultTooltip>
      <AppDefaultTooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="text-capitalize"
            color="dark-background-error"
            text
            icon
            @click="triggerBulkDeleteDialog({ products: selectedProducts })"
          >
            <v-icon>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.delete") }}</span>
      </AppDefaultTooltip>
    </div>
    <AppProductUsageDialog
      v-model="dialog.products.active"
      :model="model"
      :modelId="modelId"
      :projectId="computedProjectId"
      :editProducts="dialog.products.data"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.bulkDelete.active"
      :item="dialog.bulkDelete.data"
      :title="
        $tc('settings.products.bulkDeleteProducts.title', dialog.bulkDelete.data?.length)
      "
      @delete="onBulkDelete"
      skip-validation
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.delete.active"
      :item="dialog.delete.data"
      :title="$t('settings.products.deleteProducts.title')"
      @delete="deleteProducts"
      skip-validation
    />
    <AppSendProductsToAccountingDialog
      ref="sendToAccountingDialog"
      v-model="dialog.sendToAccounting.active"
      :productUsages="dialog.sendToAccounting.data"
      :projectId="computedProjectId"
    />
    <AppContextMenu
      ref="contextMenu"
      @input="onContextMenuChange"
    >
      <AppMenuEditBtn @click="openProductDialog({ products: [contextMenuActiveItem] })" />
      <AppMenuDeleteBtn @click="onProductDelete({ products: [contextMenuActiveItem] })" />
    </AppContextMenu>
  </div>
</template>

<script>
import NumSelectedBulk from "@/components/common/menus/bulkActionMenu/NumSelectedBulk.vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    NumSelectedBulk,
  },
  props: {
    model: String,
    modelId: String,
    projectId: String,
  },
  data() {
    return {
      dialog: {
        products: {
          active: false,
          data: null,
        },
        delete: {
          active: false,
          data: null,
        },
        bulkDelete: {
          active: false,
          data: null,
        },
        sendToAccounting: {
          active: false,
          data: [],
        },
      },
      search: {
        active: false,
        value: null,
      },
      isSectionsExpanded: [true, false],
      selectedProducts: [],
      contextMenuActiveItem: null,
    };
  },
  computed: {
    ...mapState("productUsage", {
      products: (state) => state.products,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    ...mapGetters("activeIntegrations", {
      hasProductIntegrationEnabled: "hasProductIntegrationEnabled",
    }),
    computedProjectId() {
      return this.projectId || this.selectedProducts?.[0]?.projectId;
    },
    showActionBar() {
      return this.selectedProducts.length > 0;
    },
    filterProducts() {
      const search = this.search.value?.toLowerCase() || "";
      const filteredDomains = this.products.filter((product) => {
        const nameSearch = product.name?.toLowerCase().includes(search.toLowerCase());
        const userNameSearch = product.user.fullName
          ?.toLowerCase()
          .includes(search.toLowerCase());
        const unitSearch = product.unit?.label
          ?.toLowerCase()
          .includes(search.toLowerCase());
        return nameSearch || userNameSearch || unitSearch;
      });
      return filteredDomains;
    },
    categorizedProductUsages() {
      if (!this.products) return;

      const categorizedProductUsages = [
        {
          title: this.$t("product.myProducts"),
          data: this.myProductUsages,
        },
        {
          title: this.$t("product.otherProducts"),
          data: this.otherProductUsages,
        },
      ];

      return categorizedProductUsages;
    },
    myProductUsages() {
      return this.filterProducts
        .filter((productUsage) => productUsage.userId === this.currentUserId)
        .map((productUsage) => ({ ...productUsage, section: "MY_PRODUCTS" }));
    },
    otherProductUsages() {
      return this.filterProducts
        .filter((productUsage) => productUsage.userId !== this.currentUserId)
        .map((productUsage) => ({ ...productUsage, section: "OTHER_PRODUCTS" }));
    },
  },
  watch: {
    modelId: {
      handler(val) {
        this.getProducts();
      },
    },
  },
  methods: {
    onContextMenuChange(isOpen) {
      if (!isOpen) {
        this.contextMenuActiveItem = null;
      }
    },
    openContextMenu({ from, productUsage, direction }) {
      this.$refs.contextMenu.open({ from, direction });
      this.$nextTick(() => {
        this.contextMenuActiveItem = productUsage;
      });
    },
    onClearSelection() {
      this.selectedProducts = [];
    },
    openSearch() {
      this.search.active = true;
    },
    closeSearch() {
      this.search.active = false;
      this.search.value = null;
    },

    async getProducts() {
      await this.$store.dispatch("productUsage/getProducts", {
        filter: {},
        modelId: this.modelId,
        model: this.model,
      });
    },
    onProductUpdate({ products }) {
      this.$store.dispatch("productUsage/updateProducts", { products });
    },
    sendToAccounting({ products }) {
      this.dialog.sendToAccounting.data = products;
      this.dialog.sendToAccounting.active = true;
    },
    triggerBulkDeleteDialog({ products }) {
      this.dialog.bulkDelete.data = products;
      this.dialog.bulkDelete.active = true;
    },
    onProductDelete({ products }) {
      this.dialog.delete.data = products;
      this.dialog.delete.active = true;
    },
    async onBulkDelete(e) {
      this.deleteProducts(e);
      this.onClearSelection();
    },
    async deleteProducts({ item }) {
      const ids = item.map((item) => item.id);
      await this.$store.dispatch("productUsage/deleteProducts", {
        ids,
      });
    },
    openProductDialog({ products }) {
      this.dialog.products.active = true;
      this.dialog.products.data = products;
    },
  },
  async mounted() {
    await this.$store.dispatch("productUsage/resetState");
    this.getProducts();
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background">
      <v-toolbar
        class="border-b"
        dense
        flat
        fixed
        height="80"
      >
        <div class="d-flex justify-center align-center w-100">
          <div class="font-weight-medium text-h6">
            {{ templateName || $t("common.loading") + "..." }}
          </div>
          <div
            class="p-absolute"
            style="right: 20px"
          >
            <v-btn
              icon
              dark
              @click="localDialog = false"
            >
              <v-icon>{{ $icons.LIGHT.ACTION.CLOSE }}</v-icon>
            </v-btn>
          </div>
        </div>
      </v-toolbar>
      <div class="d-flex main-height">
        <div class="overflow-y-auto w-100">
          <div
            class="page-container"
            style="max-width: 800px !important"
          >
            <AppFormTemplatePreview
              style="min-width: 650px"
              :template="template"
              :templateItems="templateItems"
              :isLoading="isLoading"
            />
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getFormLibraryTemplate,
  getFormLibraryTemplateItems,
} from "../../../../services/template/formLibrary/previewFormTemplate";

export default {
  props: {
    dialog: Boolean,
    templateId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      template: null,
      isTemplateLoading: false,
      templateItems: [],
      isItemsLoading: false,
    };
  },
  computed: {
    isLoading() {
      return this.isTemplateLoading || this.isItemsLoading;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    templateName() {
      return this.template?.name;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getTemplate();
        }
      },
    },
  },
  methods: {
    getTemplate() {
      this.isTemplateLoading = true;
      this.isItemsLoading = true;
      getFormLibraryTemplate({ id: this.templateId })
        .then((template) => {
          this.template = template;
        })
        .finally(() => (this.isTemplateLoading = false));

      getFormLibraryTemplateItems({ id: this.templateId })
        .then((items) => {
          this.templateItems = items;
        })
        .finally(() => (this.isItemsLoading = false));
    },
  },
};
</script>

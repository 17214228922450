<template>
  <v-snackbar
    :value="show"
    :timeout="-1"
  >
    <div class="d-flex align-center justify-space-between">
      <span class="text-body-1">{{ text }}</span>
      <AppCircularLoader
        color="white"
        small
      />
    </div>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    text: String,
    show: Boolean,
  },
};
</script>

<template>
  <div>
    <v-divider class="primary-border" />
    <v-menu
      offset-x
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="pa-4 d-flex cursor-pointer"
          :class="isDomainSupportUser ? 'cmsAdmin' : ''"
        >
          <AppDrawerMenu
            :title="user.name"
            :subTitle="user.email"
            :avatarItem="user"
          />
        </div>
      </template>

      <v-list>
        <v-list-item
          v-if="isOwnerOrAdmin"
          :to="{ name: $routeNames.SETTINGS.INTEGRATIONS }"
          :replace="isUnderSettings"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("drawer.userMenu.integrations") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isCmsAdmin"
          :to="{ name: $routeNames.CMS.ROOT }"
        >
          <v-list-item-content>
            <v-list-item-title>CMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-list-item
          @click="logout"
          class="mt-2"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t("common.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      isCmsAdmin: "isCmsAdmin",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isDomainSupportUser: "isDomainSupportUser",
    }),
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    isOwnerOrAdmin() {
      return this.isDomainOwner || this.isDomainAdmin;
    },
    isUnderSettings() {
      return this.isUnderRoute(this.$routeNames.SETTINGS.ROOT);
    },
  },
  methods: {
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title ? title : $t("contact.client.newClient.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newClientForm"
          v-model="client.valid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("contact.client.list.clientName") }}
            </div>
            <AppOrganizationLookupCombobox
              ref="organizationLookup"
              :placeholder="$t('integration.brreg.lookup.placeholderClient')"
              v-model="organization"
              :rules="client.companyName.rules"
              dense
              autofocus
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.orgnr") }}
            </div>
            <AppDefaultTextField v-model="client.orgnr.value" />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("contact.client.list.email") }}
            </div>
            <AppDefaultTextField
              v-model="client.email.value"
              :rules="client.email.rules"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("contact.client.list.phoneCreateNew") }}
            </div>
            <AppPhoneInput
              v-model="client.phone.value"
              :placeholder="$t('common.phoneNumber')"
              hide-label
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("contact.client.list.responsible") }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="client.owner.value"
              :items="assignableUsers"
              :loading="isAssignableUsersLoading"
              :rules="$rules.REQUIRED"
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
              user-avatar
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("contact.client.newClient.selectClientType") }}
            </div>
            <AppDefaultSelect
              v-model="client.type.value"
              :items="clientTypes"
              :clearable="false"
              :rules="client.type.rules"
              :disabled="disableTypeOnEdit"
            >
              <template v-slot:item="{ item }">
                {{ item.text }}
                <v-spacer></v-spacer>
                <v-icon
                  :color="item.iconColor"
                  right
                >
                  {{ item.icon }}
                </v-icon>
              </template>
            </AppDefaultSelect>
          </div>
          <v-expand-transition>
            <div v-if="showCustomerAndSupplierNotReccomendedAlert">
              <AppWarningAlert class="hide-details-compensation">
                <div class="d-flex">
                  <div>
                    {{ $t("contact.client.newClient.bothTypesNotRecomendedTitle") }}
                  </div>
                  <AppDefaultTooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="pr-1 cursor-pointer"
                      >
                        {{ $icons.LIGHT.COMMON.CIRCLE_QUESTION }}
                      </v-icon>
                    </template>
                    {{ $t("contact.client.newClient.bothTypesNotRecomendedDescription") }}
                  </AppDefaultTooltip>
                </div>
              </AppWarningAlert>
            </div>
          </v-expand-transition>

          <div class="p-relative">
            <v-checkbox
              class="mt-0"
              v-model="client.isPrivate.value"
              :label="$t('contact.client.newClient.privatePerson')"
            />
          </div>

          <div class="p-relative">
            <v-checkbox
              class="mt-0"
              v-model="client.active.value"
              :label="$t('common.active')"
            />
          </div>

          <AppErrorAlert v-if="errorMessage">
            {{ errorMessage }}
          </AppErrorAlert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onCreate"
          @cancel="close"
          :loading="isSubmitting"
          :confirmText="confirmText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    dialog: Boolean,
    title: String,
    data: Object,
    confirmText: String,
    type: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      tableModel: this.$constant.CLIENTS,
      isSubmitting: false,
      assignableUsers: [],
      isAssignableUsersLoading: false,
      errorMessage: "",
      client: {
        valid: false,
        companyName: {
          value: "",
          rules: [...this.$rules.REQUIRED],
        },
        orgnr: {
          value: "",
        },
        email: {
          value: "",
          rules: this.$rules.EMAIL_NOT_REQUIRED_RULES,
        },
        phone: {
          value: "",
        },
        owner: {
          value: null,
        },
        type: {
          value: "",
          rules: [(v) => !!v || this.$t("common.required")],
        },
        isPrivate: {
          value: false,
        },
        active: {
          value: true,
        },
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    isEditMode() {
      return this.data ? true : false;
    },
    isCustomerOrSupplierDisabled() {
      return this.client.isPrivate.value === true;
    },
    disableTypeOnEdit() {
      return (
        this.isEditMode && this.hasActiveCustomerAndSupplierSeparateTablesIntegration
      );
    },
    showCustomerAndSupplierNotReccomendedAlert() {
      return (
        this.hasActiveCustomerAndSupplierSeparateTablesIntegration &&
        this.client.type.value === this.$constant.BOTH &&
        !this.isEditMode
      );
    },
    ...mapGetters("activeIntegrations", {
      hasActiveCustomerAndSupplierSeparateTablesIntegration:
        "hasActiveCustomerAndSupplierSeparateTablesIntegration",
    }),
    clientTypes() {
      return [
        {
          value: this.$constant.CUSTOMER,
          text: this.$t("contact.client.type.customer"),
          disabled: false,
        },
        {
          value: this.$constant.SUPPLIER,
          text: this.$t("contact.client.type.supplier"),
          disabled: false,
        },
        {
          value: this.$constant.BOTH,
          text: this.$t("contact.client.type.both"),
          icon: this.hasActiveCustomerAndSupplierSeparateTablesIntegration
            ? this.$icons.SOLID.ACTION.WARNING
            : null,
          iconColor: "warning",
          disabled: false,
        },
      ];
    },
    organization: {
      get() {
        return {
          name: this.client.companyName.value,
          orgnr: this.client.orgnr.value,
        };
      },
      set(item) {
        if (!item) return;
        if (typeof item === "string") {
          this.client.companyName.value = item;
        } else {
          this.client.companyName.value = item.name;
          this.client.orgnr.value = item.orgnr;
        }
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData(this.data);
          this.getAssignableUsers();
        }
      },
    },
  },
  methods: {
    fillFormWithData(data) {
      this.client.companyName.value = data?.name || "";
      this.client.orgnr.value = data?.organizationNumber || "";
      this.client.owner.value = data?.ownerId || this.currentUserId || null;
      this.client.isPrivate.value = data?.isPrivate || false;
      this.client.email.value = data?.email || "";
      this.client.phone.value = data?.phone || "";
      this.client.active.value = data?.active !== undefined ? data.active : true;
      this.client.type.value = data?.type || this.type || this.$constant.CUSTOMER;
    },
    resetForm() {
      this.$refs.newClientForm.resetValidation();
      this.client.companyName.value = "";
      this.client.orgnr.value = "";
      this.client.owner.value = this.currentUserId || null;
      this.client.isPrivate.value = false;
      this.client.email.value = "";
      this.client.phone.value = "";
      this.client.active.value = true;
      this.client.type.value = this.$constant.CUSTOMER;
    },
    getAssignableUsers() {
      this.isAssignableUsersLoading = true;
      const params = {
        alwaysIncludeIds: [this.client.owner.value],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableUsers = users;
        })
        .finally(() => (this.isAssignableUsersLoading = false));
    },
    createOrUpdateClient(data) {
      const storeType = this.isEditMode ? "clients/updateClient" : "clients/createClient";
      this.isSubmitting = true;
      this.errorMessage = "";
      this.$store
        .dispatch(storeType, data)
        .then((client) => {
          this.afterSubmit(client);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    afterSubmit(client) {
      this.$emit("confirm", { client });
      this.localDialog = false;
      this.resetForm();
    },
    onCreate() {
      this.$refs.organizationLookup.blur();
      this.$nextTick(() => {
        const valid = this.$refs.newClientForm.validate();
        if (valid) {
          const data = {
            id: this.data?.id,
            body: {
              name: this.client.companyName.value,
              organizationNumber: this.client.orgnr.value,
              ownerId: this.client.owner.value,
              email: this.client.email.value || null,
              phone: this.client.phone.value || null,
              type: this.client.type.value,
              isPrivate:
                typeof this.client.isPrivate.value === "boolean"
                  ? this.client.isPrivate.value
                  : false,
              active:
                typeof this.client.active.value === "boolean"
                  ? this.client.active.value
                  : true,
            },
            filters: this.filtersMixins_filters,
            dataTableOptions: this.filtersMixins_dataTableOptions,
          };
          this.createOrUpdateClient(data);
        }
      });
    },
    close() {
      this.localDialog = false;
      this.$refs.newClientForm.resetValidation();
    },
  },
};
</script>

<template>
  <v-card
    class="border-a d-flex rounded input-background--background flex-column"
    flat
  >
    <div
      class="d-flex align-center w-100 pa-3 pr-0 cursor-text my-3"
      style="height: 40px"
    >
      <div
        v-if="!hasSearched"
        :class="`cursor-grab d-flex pr-4`"
        @click.stop
      >
        <v-icon>
          {{ $icons.SOLID.ACTION.DRAG }}
        </v-icon>
      </div>
      <div class="w-100">
        <AppDefaultTextField
          ref="nameInput"
          v-model="editName.value"
          @blur="onNameChangeComplete"
          class="block-draggable"
          backgroundColor="ui-background"
          hide-details
        />
      </div>

      <v-spacer />
      <span class="block-draggable mr-3 ml-2">
        <AppToggleMenuBtn>
          <!-- kept because i wasnt sure if we wanted this, can probably remove comments next time this is seen -->
          <!-- <AppMenuEditBtn
            :text="$t('answerTemplates.editName')"
            @click="onNameChangeStart"
          /> -->
          <AppMenuItem
            :text="$t('common.changeColor')"
            :icon="$icons.REGULAR.ACTION.COLOR"
            @click="openColorPicker"
          />
          <!-- <AppMenuEditBtn
            :text="$t('answerTemplates.editLabel')"
            @click="onLabelNameChangeStart"
          />
          <AppMenuEditBtn
            :text="$t('answerTemplates.editAnswer')"
            @click="onAnswerChangeStart"
          /> -->
          <AppMenuDeleteBtn @click="onDeleteClick" />
        </AppToggleMenuBtn>
      </span>
    </div>
    <v-expand-transition>
      <div v-show="showItemContent">
        <div class="mx-3">
          <div
            class="d-flex align-center label--small font-weight-medium"
            style="font-size: 14px"
          >
            {{ $t("defaultAnswer.label") }}
          </div>
          <div
            @click="onLabelNameChangeStart"
            class="d-flex align-center"
            style="height: 40px"
          >
            <v-menu
              ref="menu"
              v-model="colorPicker.active"
              @input="onColorPickerMenuToggle"
              offset-x
              nudge-left="5px"
              nudge-top="5px"
              max-width="325"
              :elevation="9"
              :closeOnContentClick="false"
            >
              <template v-slot:activator="{ attrs, on }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex justify-center block-draggable pa-2 mr-2"
                >
                  <v-icon :color="labelColor">
                    {{ $icons.SOLID.ACTION.STATUS }}
                  </v-icon>
                </div>
              </template>
              <v-card
                class="pa-3 ui-background d-flex flex-column"
                style="min-width: 70px"
                elevation="9"
              >
                <div class="d-flex align-center">
                  <div
                    style="font-size: 14px"
                    class="user-select-none"
                  >
                    <AppTextTruncateValue
                      :text="labelName"
                      :truncate="33"
                    />
                  </div>
                  <v-spacer />
                  <v-btn
                    small
                    icon
                    @click="openCustomColorPicker"
                    class="mr-1"
                  >
                    <v-icon>{{ $icons.REGULAR.ACTION.CUSTOM_COLOR }}</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    @click="closeColorPicker"
                  >
                    <v-icon>{{ $icons.REGULAR.ACTION.CLOSE }}</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-wrap">
                  <v-color-picker
                    class="mt-1 mb-1"
                    v-if="colorPicker.showCustomColorPicker"
                    v-model="colorPicker.value"
                  />
                  <div
                    v-for="color of predefinedColors"
                    @click="selectColor(color)"
                    class="cursor-pointer"
                    :class="
                      color === colorPicker.value
                        ? 'outline-a rounded input-background--background'
                        : ''
                    "
                  >
                    <v-icon
                      :color="color"
                      style="padding: 5px"
                    >
                      {{ $icons.SOLID.ACTION.STATUS }}
                    </v-icon>
                  </div>
                  <div
                    v-if="hasSelectedNoPredefinedColors"
                    class="cursor-pointer"
                    :class="
                      hasSelectedNoPredefinedColors
                        ? 'outline-a rounded input-background--background'
                        : ''
                    "
                  >
                    <v-icon
                      :color="colorPicker.value"
                      style="padding: 5px"
                    >
                      {{ $icons.SOLID.ACTION.STATUS }}
                    </v-icon>
                  </div>
                </div>
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="onColorSave"
                  class="mt-2"
                  :disabled="isColorSaved"
                >
                  {{ isColorSaved ? $t("common.saved") : $t("common.save") }}
                </v-btn>
              </v-card>
            </v-menu>
            <AppDefaultTextField
              ref="labelNameInput"
              v-model="editLabelName.value"
              @blur="onLabelNameChangeComplete"
              class="block-draggable"
              backgroundColor="ui-background"
              style="font-size: inherit"
              hide-details
              :maxLength="MAX_CHARACTERS_LABEL_NAME"
            />
          </div>
          <div class="mb-3">
            <div
              class="d-flex align-center label--small font-weight-medium pt-2"
              style="font-size: 14px"
            >
              {{ $t("common.answer") }}
            </div>
            <AppDefaultTextarea
              ref="valueInput"
              v-model="editValue.value"
              @blur="onValueChangeBlur"
              class="block-draggable"
              style="font-size: inherit"
              backgroundColor="ui-background"
              hide-details
              :rows="2"
            />
          </div>
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    name: String,
    labelName: String,
    labelColor: String,
    value: String,
    id: String,
    predefinedColors: Array,
    hasSearched: Boolean,
    showItemContent: Boolean,
  },
  data() {
    return {
      editName: {
        active: false,
        rules: [(v) => !!v || "Name is required"],
        value: this.name,
      },
      editValue: {
        value: this.value,
      },
      editLabelName: {
        active: false,
        value: this.labelName,
      },
      colorPicker: {
        active: false,
        showCustomColorPicker: false,
        value: this.labelColor,
      },
      MAX_CHARACTERS_LABEL_NAME: 35,
    };
  },
  watch: {
    name(newVal) {
      this.editName.value = newVal;
    },
    value(newVal) {
      this.editValue.value = newVal;
    },
    labelName(newVal) {
      this.editLabelName.value = newVal;
    },
  },
  computed: {
    hasSelectedNoPredefinedColors() {
      return !this.predefinedColors.some((color) => color === this.colorPicker.value);
    },
    isColorSaved() {
      return this.labelColor === this.colorPicker.value;
    },
  },
  methods: {
    focusLabelInput() {
      this.$refs.labelNameInput.focus();
    },
    focusValueInput() {
      this.$refs.valueInput.focus();
    },
    onColorPickerMenuToggle(isOpen) {
      if (isOpen) {
        this.colorPicker.value = this.labelColor;
      } else {
        this.colorPicker.value = "";
      }
    },
    openCustomColorPicker() {
      this.colorPicker.showCustomColorPicker = !this.colorPicker.showCustomColorPicker;
      this.updateDimensions();
    },
    openColorPicker() {
      this.showContent();
      this.colorPicker.active = true;
    },
    closeColorPicker() {
      this.colorPicker.active = false;
    },
    selectColor(color) {
      this.colorPicker.value = color;
    },
    onValueChangeBlur() {
      const isNewValue = this.value !== this.editValue.value;

      if (isNewValue) {
        this.updateData({ value: this.editValue.value });
      }
    },
    onNameChangeComplete() {
      const isNewName = this.name !== this.editName.value;
      const isEmpty = !this.editName.value.trim();

      const isValidName = isNewName && !isEmpty;

      if (isValidName) {
        this.updateData({ name: this.editName.value });
      } else {
        this.editName.value = this.name;
      }
    },
    onLabelNameChangeStart() {
      this.showContent();
    },
    showContent() {
      if (!this.showItemContent) {
        this.$emit("showItemContent:change", true);
      }
    },
    onLabelNameChangeComplete() {
      const isNewLabelName = this.labelName !== this.editLabelName.value;
      const isValidLabelName = isNewLabelName;

      if (isValidLabelName) {
        this.updateData({ labelName: this.editLabelName.value });
      }
    },
    onAnswerChangeStart() {
      this.showContent();
      this.$nextTick(() => {
        this.focusValueInput();
      });
    },
    onColorSave() {
      this.updateData({ labelColor: this.colorPicker.value });
    },
    onDeleteClick() {
      this.$emit("delete:click", { id: this.id });
    },
    updateDimensions() {
      this.$refs.menu.updateDimensions();
    },
    updateData(updatedField) {
      this.$emit("data:update", updatedField);
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="500"
    scrollable
  >
    <v-card class="adjust-subscription-quantity">
      <v-card-title class="headline font-weight-bold">
        {{ title }}
      </v-card-title>
      <v-card-text class="font-weight-medium primary-text--text pb-10">
        <div class="pb-10">{{ subTitle }}</div>
        <v-row style="height: 150px">
          <v-col>
            <div class="mr-4">
              <div class="mb-3 text-center">
                {{
                  $t(
                    `settings.billing.subscription.dialogs.adjustSubscriptionQuantity.${planCode}.quantityLabel`,
                  )
                }}
              </div>
              <div class="d-flex">
                <v-btn
                  class="rounded border-a"
                  color="input-background--background"
                  icon
                  tile
                  depressed
                  v-ripple
                  :ripple="false"
                  @click="setQuantity(localQuantity - 1)"
                >
                  <v-icon small>{{ $icons.SOLID.ACTION.MINUS }}</v-icon>
                </v-btn>
                <div class="mx-4">
                  <AppDefaultTextField
                    :value="localQuantity"
                    type="number"
                    class="text-centered-input"
                    @input="setQuantity($event)"
                    hide-details
                    readonly
                  />
                </div>
                <v-btn
                  class="rounded border-a"
                  color="input-background--background"
                  icon
                  tile
                  depressed
                  v-ripple
                  :ripple="false"
                  @click="setQuantity(localQuantity + 1)"
                >
                  <v-icon small>{{ $icons.SOLID.ACTION.PLUS }}</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-divider
            vertical
            class="my-2"
          />
          <v-col>
            <div class="text-center">
              <i18n
                path="settings.billing.subscription.dialogs.adjustSubscriptionQuantity.intervalPayment"
                tag="div"
                class="mb-4 text-body-1"
              >
                <template v-slot:intervalType>
                  {{
                    $t(`settings.billing.subscription.interval.${intervalType}`)
                      | capitalize
                  }}
                </template>
              </i18n>

              <div class="mb-4 text-body-1 font-weight-bold">
                {{ calculatedPrice() }}
              </div>

              <i18n
                :path="`settings.billing.subscription.dialogs.adjustSubscriptionQuantity.${planCode}.breakdown`"
                tag="div"
                class="text-caption font-weight-medium"
              >
                <template v-slot:quantity>
                  {{ localQuantity }}
                </template>
                <template v-slot:pricePerQuantity>
                  {{ pricePrQuantity }}
                </template>
                <template v-slot:intervalType>
                  {{ $t(`settings.billing.subscription.interval.${intervalType}`) }}
                </template>
              </i18n>
              <i18n
                v-if="platformFeeSubscription && planHasPlatformFee"
                :path="`settings.billing.subscription.dialogs.adjustSubscriptionQuantity.${planCode}.trialBreakdown`"
                tag="div"
                class="text-caption font-weight-medium pb-1"
              >
                <template v-slot:pricePerQuantity>
                  {{ platformFeePriceFormat }}
                </template>
                <template v-slot:intervalType>
                  {{ $t(`settings.billing.subscription.interval.${intervalType}`) }}
                </template>
              </i18n>
              <span class="text-caption font-weight-medium font-italic">
                {{ $t("settings.billing.subscription.common.exVat") }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <AppAcceptTermsCheckbox
        v-model="hasAcceptedTerms"
        class="mx-3"
        :pp="false"
        hide-details
      />
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onConfirm"
          @cancel="localDialog = false"
          :confirmText="buttonText"
          :disabled="!hasAcceptedTerms"
          confirmColor="success"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { billingMixins } from "@/helpers/mixins";
export default {
  mixins: [billingMixins],
  props: {
    dialog: Boolean,
    currency: String,
    usedSeats: Number,
    quantity: Number,
    activePlanSubscriptionCode: String,
    pricePerQuantity: Number,
    intervalType: String,
    planCode: String,
    platformFeeSubscription: Object,
    plans: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      localQuantity: 0,
      hasAcceptedTerms: false,
    };
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          if (this.isMainPlan && this.quantity < this.minQuantity) {
            this.localQuantity = this.minQuantity;
          } else {
            this.localQuantity = this.quantity;
          }
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isNotActiveAndIsMainPlan() {
      const isActivePlan = this.activePlanSubscriptionCode === this.planCode;
      return !isActivePlan && this.isMainPlan;
    },
    isMainPlan() {
      return this.$constant.MAIN_PLAN_CODES.includes(this.planCode);
    },
    minQuantity() {
      return this.isMainPlan ? this.usedSeats : this.$constant.ADDON_PLAN_MIN_QUANTITY;
    },
    maxQuantity() {
      return this.isMainPlan
        ? this.$constant.MAIN_PLAN_MAX_QUANTITY
        : this.$constant.ADDON_PLAN_MAX_QUANTITY;
    },
    title() {
      const isChangePlan = this.isNotActiveAndIsMainPlan;
      if (isChangePlan) {
        return this.$t(
          `settings.billing.subscription.dialogs.adjustSubscriptionQuantity.changePlanTitle`,
        );
      } else
        return this.$t(
          `settings.billing.subscription.dialogs.adjustSubscriptionQuantity.${this.planCode}.title`,
        );
    },
    subTitle() {
      const params = {
        price: this.billingMixins_priceFormat(this.pricePerQuantity, this.currency),
        intervalType: this.$t(
          `settings.billing.subscription.interval.${this.intervalType}`,
        ),
      };
      const isChangePlan = this.isNotActiveAndIsMainPlan;
      let append = `${this.planCode}.subTitle`;
      if (isChangePlan) {
        append = "changePlanSubTitle";
        return this.$t(
          `settings.billing.subscription.dialogs.adjustSubscriptionQuantity.changePlanSubTitle`,
          params,
        );
      }
      return this.$t(
        `settings.billing.subscription.dialogs.adjustSubscriptionQuantity.${append}`,
        params,
      );
    },
    buttonText() {
      const isChangePlan = this.isNotActiveAndIsMainPlan;
      if (isChangePlan)
        return this.$t(
          "settings.billing.subscription.dialogs.adjustSubscriptionQuantity.changePlanButton",
        );

      return this.$t("common.confirm");
    },
    quantityText() {
      return "quantityText";
    },
    platformFeePrice() {
      if (!this.planHasPlatformFee) return 0;
      return this.platformFeeSubscription?.totalPrice || 0;
    },
    platformFeePriceFormat() {
      return this.billingMixins_priceFormat(
        this.platformFeeSubscription?.totalPrice,
        this.currency,
      );
    },
    planHasPlatformFee() {
      return this.plans.find((plan) => plan.code === this.planCode)?.hasPlatformFee;
    },
    pricePrQuantity() {
      return this.billingMixins_priceFormat(this.pricePerQuantity, this.currency);
    },
  },
  methods: {
    calculatedPrice() {
      const price = this.pricePerQuantity * this.localQuantity + this.platformFeePrice;
      return this.billingMixins_priceFormat(price, this.currency);
    },

    isQuantityInvalid(quantity) {
      return !quantity || isNaN(quantity);
    },
    isQuantityBelowMin(quantity) {
      return quantity < this.minQuantity;
    },
    isQuantityAboveMax(quantity) {
      return quantity > this.maxQuantity;
    },
    setQuantity(quantity) {
      quantity = parseInt(quantity);
      if (this.isQuantityInvalid(quantity) || this.isQuantityBelowMin(quantity)) {
        quantity = this.minQuantity;
      }
      if (this.isQuantityAboveMax(quantity)) {
        quantity = this.maxQuantity;
      }
      this.localQuantity = quantity;
    },
    onConfirm() {
      if (!this.hasAcceptedTerms) return;
      this.$emit("confirm", { quantity: this.localQuantity, planCode: this.planCode });
      this.localDialog = false;
    },
  },
};
</script>

<style lang="scss">
.adjust-subscription-quantity {
  .input-background--background {
    background-color: var(--v-input-background-base) !important;
  }
}
</style>

<template>
  <v-breadcrumbs
    :items="truncatedPath"
    class="pa-0 mw-0 w-100 flex-nowrap c-folder-path"
  >
    <template v-slot:divider>
      <v-icon
        x-small
        class="px-2"
      >
        {{ $icons.LIGHT.COMMON.CHEVRON_RIGHT }}
      </v-icon>
    </template>
    <template v-slot:item="{ item }">
      <li
        class="pa-0 mw-0"
        v-if="item.level === 0"
      >
        <template v-if="prependPath.length < 2">
          <AppDefaultTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                class="v-breadcrumbs__item text-truncate"
                @click="onClick(item)"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.text }}
              </a>
            </template>
            <span class="break-word">
              {{ item.text }}
            </span>
          </AppDefaultTooltip>
        </template>

        <template v-else>
          <div class="text-center">
            <v-menu
              offset-y
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                >
                  <v-icon>{{ $icons.LIGHT.ACTION.SAVE }}</v-icon>
                </v-btn>
              </template>
              <v-list
                ref="pathDropdownList"
                class="c-folder-path__path_list"
              >
                <v-list-item
                  v-for="path in prependPath"
                  :key="path.id"
                  @click="onClick(path)"
                >
                  {{ path.text }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </li>
      <template v-else-if="item.isLastLevel || item.isPenultimatePath">
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <li
              class="mw-0 pa-0"
              v-bind="attrs"
              v-on="on"
            >
              <a
                class="v-breadcrumbs__item text-truncate"
                @click="onClick(item)"
              >
                {{ item.text }}
              </a>
            </li>
          </template>
          <span class="break-word">
            {{ item.text }}
          </span>
        </AppDefaultTooltip>
      </template>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    prependPath: Array,
    truncatedPath: Array,
  },
  methods: {
    onClick(item) {
      this.$emit("item:click", item);
    },
  },
};
</script>

<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto d-flex justify-center align-center"
  >
    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'100%'"
    />
    <template v-if="!isLoading">
      <div class="text-center pa-2">
        <img
          class="mb-7"
          :src="getEmptyStateIcon()"
        />
        <div class="text-h5 font-weight-bold mb-2">
          {{ $t("fileManagement.preview.navMenu.e-signature.NOT_STARTED.title") }}
        </div>
        <div class="text-body-1 mb-7">
          {{ $t("fileManagement.preview.navMenu.e-signature.NOT_STARTED.subTitle") }}
        </div>
        <v-btn
          depressed
          color="primary"
          @click="openDialog"
        >
          {{ $t("fileManagement.preview.navMenu.e-signature.NOT_STARTED.button") }}
        </v-btn>
      </div>
    </template>
    <AppSignDocumentAddRecipientsDialog
      :storageId="storageId"
      @submit="onPrepDocumentSubmit"
      v-model="dialog.addRecipientsToESign"
    />
    <AppESignAddMoreDialog
      v-model="dialog.addMoreESign"
      @confirm="afterESignPurchase"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import emptyStateImage from "../../../../../assets/empty_state_signature.svg";
export default {
  data() {
    return {
      dialog: {
        addRecipientsToESign: false,
        addMoreESign: false,
      },
      hasStartedSigning: false,
    };
  },
  props: {
    storageId: String,
  },
  computed: {
    ...mapState("notStartedDocumentProperties", {
      properties: (state) => state.properties,
      isPropertiesLoading: (state) => state.isLoading,
    }),
    ...mapGetters("notStartedDocumentProperties", {
      hasMoreESign: "hasMoreESign",
    }),
    isLoading() {
      return this.isPropertiesLoading || !this.properties;
    },
  },
  methods: {
    async getDocumentProperties(storageId, load = true) {
      await this.$store.dispatch("notStartedDocumentProperties/getProperties", {
        storageId,
        load,
      });

      this.autoOpenDialog();
    },
    getEmptyStateIcon() {
      return emptyStateImage;
    },
    openDialog() {
      if (this.hasMoreESign) {
        this.dialog.addRecipientsToESign = true;
      } else {
        this.dialog.addMoreESign = true;
      }
    },
    onPrepDocumentSubmit(e) {
      this.$emit("afterSubmit", e);
    },
    afterESignPurchase() {
      this.getDocumentProperties(this.storageId);
    },
    autoOpenDialog() {
      const shouldAutoOpenDialog =
        this.$route.query?.openAddRecipients === "true" ||
        this.$route.query?.openAddRecipients === true;

      if (shouldAutoOpenDialog) {
        this.openDialog();
      }
    },
  },
  mounted() {
    this.getDocumentProperties(this.storageId);
  },
};
</script>

<template>
  <div class="comment_item_node">
    <v-hover v-slot="{ hover }">
      <div class="comment_item_node__root">
        <v-card
          v-if="hover && isItemCreator"
          class="px-2 primary-box-shadow"
          style="position: absolute; right: 0; top: -15px"
        >
          <v-btn
            icon
            small
            @click="onCommentEdit"
          >
            <v-icon small>{{ $icons.LIGHT.ACTION.EDIT }}</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="onCommentDelete"
          >
            <v-icon small>{{ $icons.LIGHT.ACTION.DELETE }}</v-icon>
          </v-btn>
        </v-card>

        <div class="comment_item_node__prepend">
          <slot
            name="prepend"
            :item="item"
            :state="{ hover }"
          >
            <AppUserAvatar :user="item.creator" />
          </slot>
        </div>
        <div class="comment_item_node__label">
          <slot
            name="label"
            :item="item"
            :state="{ hover }"
          >
            <div class="w-100 d-flex">
              <template v-if="item.creator">
                <span class="font-weight-medium">
                  <AppTextTruncateValue
                    :text="item.creator.name"
                    :truncate="27"
                  />
                </span>
                &nbsp;
                <span class="pl-2 text--secondary">
                  {{ new Date(item.createdAt) | dateFormat("HH:mm") }}
                </span>
              </template>
            </div>
            <div class="flex-basis-100 d-flex w-100">
              <div class="pr-2 d-flex align-center w-100">
                <template v-if="editMode">
                  <div class="d-flex flex-column w-100">
                    <AppQuill
                      :content="item.content"
                      @submit="onCommentUpdate"
                      @cancel="onCommentDiscard"
                      show-submit
                      :submit-icon="$icons.LIGHT.ACTION.ACCEPT"
                      show-cancel
                    />
                  </div>
                </template>
                <template v-else>
                  <div
                    class="quill-editor reset-mp"
                    v-html="item.content"
                  ></div>
                </template>
              </div>
            </div>
          </slot>
        </div>
        <div class="comment_item_node__append">
          <slot
            name="append"
            :item="item"
            :state="{ hover }"
          ></slot>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentUserId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    isItemCreator() {
      if (!this.item || !this.currentUserId) return false;
      return this.item.creatorId === this.currentUserId;
    },
  },
  methods: {
    onCommentEdit() {
      this.editMode = true;
    },
    onCommentDiscard() {
      this.editMode = false;
    },
    onCommentUpdate({ content }) {
      this.$emit("item:update", { item: this.item, content });
      this.editMode = false;
    },
    onCommentDelete() {
      this.$emit("item:delete", { item: this.item });
    },
  },
  mounted() {},
};
</script>

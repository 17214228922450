<template>
  <div
    class="w-100 h-100 overflow-hidden"
    id="map"
  ></div>
</template>

<script>
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    markers: {
      handler(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.initMap();
        }
      },
    },
  },
  data() {
    return {
      map: null,
    };
  },
  methods: {
    async initMap() {
      let position = { lat: 59.9, lng: 10.68 };
      const { Map } = await google.maps.importLibrary("maps");

      this.map = new Map(document.getElementById("map"), {
        zoom: 10,
        center: position,
        mapId: "map",
      });

      this.onMarkersChange();
    },
    onMarkersChange() {
      this.centralize();
      this.addMarkers();
    },
    async addMarkers() {
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      const markers = this.markers.map((marker) => {
        const infowindow = new google.maps.InfoWindow({
          content: marker.infoWindowContent || `<h3>${marker.title}</h3>`,
        });

        const markerElement = new AdvancedMarkerElement({
          map: this.map,
          position: marker.position,
          content: marker.icon,
        });

        markerElement.addListener("click", () => {
          infowindow.open({
            anchor: markerElement,
            map,
          });
        });

        this.addLabel({ title: marker.title, markerElement });

        return markerElement;
      });

      new MarkerClusterer({
        markers: markers,
        map: this.map,
      });
    },
    addLabel({ title, markerElement }) {
      const domElement = markerElement.element;

      const labelElement = document.createElement("div");
      labelElement.textContent = title;
      labelElement.style.padding = "2px";
      labelElement.classList.add("border-a");
      labelElement.classList.add("ui-background--background");
      labelElement.classList.add("rounded");
      labelElement.classList.add("text--primary--text");

      labelElement.style.width = `80px`;

      labelElement.style.transform = `translateX(-33%)`;

      labelElement.style.position = "absolute";
      labelElement.style.top = "45px";

      labelElement.style.textAlign = "center";

      domElement.appendChild(labelElement);
    },
    centralize() {
      if (!this.markers?.length) return;
      const bounds = new google.maps.LatLngBounds();
      for (const marker of this.markers) {
        bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
      }
      this.map.fitBounds(bounds);
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>

<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="310"
    min-width="auto"
    :disabled="disabled"
    @input="onInput"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
      >
        <AppDefaultTextField
          v-model="localDateFormatted"
          name="date"
          readonly
          :disabled="disabled"
          :class="disabled ? '' : 'date-field-cursor-pointer'"
          :append-icon="$icons.LIGHT.COMMON.CALENDAR_DAY"
          :label="placeholder"
          :clearable="clearable"
          :hide-details="hideDetails"
          :rules="required ? $rules.REQUIRED : []"
        >
          <template
            v-slot:prepend-inner
            v-if="isRepeatToggled"
          >
            <AppRepeatIcon
              class="pr-2"
              small
            />
          </template>
        </AppDefaultTextField>
      </div>
    </template>
    <div id="dateCardWrapper">
      <v-card :disabled="isUpdating">
        <v-date-picker
          v-model="localDate"
          no-title
          color="primary"
          class="repeatable-date-picker-date-picker-table"
          event-color="secondary"
          show-week
          locale-first-day-of-year="4"
          :events="highlightedDays"
          :first-day-of-week="dateMixins_firstDayOfWeek"
          :picker-date.sync="localDefaultPickerDate"
          :min="min"
          :max="max"
          @update:picker-date="triggerResizeWatch"
          @update:active-picker="triggerResizeWatch"
        >
          <div
            class="d-flex w-100 flex-column"
            v-if="!notRepeatable"
          >
            <div v-if="isRepeatToggled">
              <v-divider />
              <div
                class="py-4"
                :disabled="true"
              >
                <div
                  class="text-body-1 pb-1"
                  style="padding-left: 1px"
                >
                  {{ $t("repeatOption.title") }}
                </div>
                <AppDefaultSelect
                  v-model="localRepeatOption.interval"
                  item-text="text"
                  item-value="value"
                  hide-details
                  :items="dateMixins_intervals"
                  :clearable="false"
                />
                <div
                  v-if="isIntervalSelected('WEEKLY')"
                  class="mb-3"
                >
                  <div
                    class="mt-4 mb-1 text-body-2"
                    style="padding-left: 3px"
                  >
                    {{ $t("repeatOption.daily.title") }}
                  </div>
                  <AppDayPicker v-model="localRepeatOption.weeklyOptions.days" />
                </div>
                <div
                  v-else-if="isIntervalSelected('MONTHLY')"
                  class="mb-3"
                >
                  <v-radio-group
                    class="primary-text--text pa-0 ma-0 mt-4"
                    v-model="localRepeatOption.monthlyOptions.selected"
                    hide-details
                  >
                    <v-radio
                      value="onWeekday"
                      class="mb-0"
                    >
                      <template v-slot:label>
                        <span class="primary-text--text text-no-wrap">
                          {{ $t("repeatOption.monthly.onThe") }}
                        </span>
                        <AppMinimalisticSelect
                          v-model="localRepeatOption.monthlyOptions.onWeekday.weekNr"
                          item-value="value"
                          item-text="text"
                          :items="dateMixins_weekNumbers"
                        />
                        <AppMinimalisticSelect
                          v-model="localRepeatOption.monthlyOptions.onWeekday.weekday"
                          item-value="value"
                          item-text="text"
                          :items="dateMixins_localeAwareWeekdays('short')"
                        />
                      </template>
                    </v-radio>
                    <v-radio
                      value="onDate"
                      class="mb-0"
                    >
                      <template v-slot:label>
                        <span class="primary-text--text text-no-wrap">
                          {{ $t("repeatOption.monthly.onDay") }}
                        </span>
                        <AppMinimalisticSelect
                          v-model="localRepeatOption.monthlyOptions.onDate.dayInMonth"
                          item-value="value"
                          item-text="text"
                          :items="dateMixins_daysInMonthNumbers"
                        />
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
                <div
                  v-else-if="isIntervalSelected('YEARLY')"
                  class="pb-4"
                ></div>
                <span class="d-flex w-100 align-center">
                  <v-checkbox
                    style="display: inline-block"
                    class="mt-0 pt-0"
                    color="primary"
                    hide-details
                    :input-value="localRepeatOption.copyContent"
                    @change="localRepeatOption.copyContent = $event"
                  >
                    <template v-slot:label>
                      <span class="primary-text--text">
                        {{ $t("repeatOption.copyContent") }}
                      </span>
                    </template>
                  </v-checkbox>
                  <v-spacer />
                  <div
                    class="primary-link--text underline-on-hover cursor-pointer"
                    @click="onEditSelectedContentClick"
                  >
                    {{ $t("common.configure") }}
                  </div>
                </span>
              </div>
            </div>
            <v-divider />
            <div class="d-flex mt-4">
              <v-btn
                depressed
                style="min-width: 40px !important; width: 40px !important"
                :color="backgroundColor(isRepeatToggled)"
                :loading="isLoadingDefaultRepeatOptions"
                :disabled="!date || isLoadingDefaultRepeatOptions"
                @click="toggleRepeat"
              >
                <v-icon :color="iconColor(isRepeatToggled)">
                  {{ $icons.REGULAR.ACTION.REPEAT }}
                </v-icon>
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="isRepeatToggled"
                depressed
                color="primary"
                :disabled="!isUnsaved"
                :loading="isUpdating"
                @click="save(localRepeatOption)"
              >
                {{ saveText }}
              </v-btn>
            </div>
            <AppSelectContentDialog
              v-model="dialog.content.active"
              :content="content"
              @content:change="localRepeatOption.content = $event"
            />
          </div>
          <div v-else></div>
        </v-date-picker>
      </v-card>
    </div>
  </v-menu>
</template>

<script>
import * as moment from "moment";
import { dateMixins } from "@/helpers/mixins";
import { getDefaultRepeatOptions } from "@/services/repeatOptions/defaultRepeatOptions";
export default {
  mixins: [dateMixins],
  props: {
    repeatOption: Object,
    model: String,
    date: String,
    placeholder: String,
    hideDetails: Boolean,
    required: Boolean,
    isUpdating: Boolean,
    notRepeatable: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    min: String,
    max: String,
    defaultPickerDate: String,
  },
  model: {
    prop: "date",
    event: "date:change",
  },
  data() {
    return {
      dateMenu: false,
      localRepeatOption: null,
      isLoadingDefaultRepeatOptions: false,
      dialog: {
        content: {
          active: false,
        },
      },
      resizeTrigger: false,
      localDefaultPickerDate: null,
    };
  },
  watch: {
    repeatOption: {
      handler(val) {
        this.localRepeatOption =
          typeof val === "undefined" ? val : JSON.parse(JSON.stringify(val));
      },
      deep: true,
      immediate: true,
    },
    resizeTriggers: {
      handler() {
        this.onResize();
      },
    },
  },
  computed: {
    resizeTriggers() {
      return `${this.isRepeatToggled}|${this.localRepeatOption?.interval}|${this.resizeTrigger}`;
    },
    content() {
      return this.localRepeatOption?.content;
    },
    localDate: {
      get() {
        const date = this.date ? moment(this.date).format("YYYY-MM-DD") : null;
        return date;
      },
      set(value) {
        this.$emit("date:change", value);
      },
    },
    localDateFormatted: {
      get() {
        return this.localDate ? moment(this.localDate).format("DD.MM.YYYY") : "";
      },
      set(value) {
        this.$emit("date:change", value);
      },
    },
    isRepeatToggled() {
      return !!this.localRepeatOption?.repeat && !!this.localDate;
    },
    saveText() {
      return this.isUnsaved ? this.$t("common.save") : this.$t("common.saved");
    },
    isUnsaved() {
      if (this.repeatOption?.repeat !== this.localRepeatOption?.repeat) return false;
      return !this.areObjectsEqual(this.repeatOption, this.localRepeatOption);
    },
  },
  methods: {
    onInput(value) {
      if (!value) return;
      this.localDefaultPickerDate = this.date || this.defaultPickerDate;
    },
    triggerResizeWatch() {
      this.resizeTrigger = !this.resizeTrigger;
    },
    onResize() {
      this.$refs.menu.updateDimensions();
    },
    save(data) {
      this.$emit("repeatOption:change", { repeatOption: data });
    },
    areObjectsEqual(objA, objB) {
      if (objA === objB) return true;

      if (objA == null || objB == null) return false; // Check for null or undefined

      if (typeof objA !== "object" || typeof objB !== "object") return false;

      if (Array.isArray(objA) && Array.isArray(objB)) {
        if (objA.length !== objB.length) return false;

        // Check if the arrays have the same elements regardless of order
        const sortedA = [...objA].sort();
        const sortedB = [...objB].sort();

        return sortedA.every((value, index) =>
          this.areObjectsEqual(value, sortedB[index]),
        );
      }

      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) return false;

      for (const key of keysA) {
        if (!keysB.includes(key) || !this.areObjectsEqual(objA[key], objB[key])) {
          return false;
        }
      }
      if (objA.repeat !== objB.repeat) return false;

      return true;
    },
    highlightedDays(event) {
      if (!this.localRepeatOption || !event || !this.isRepeatToggled) return false;
      if (!this.localRepeatOption.repeat) return false;

      const dateToCheck = moment(event, "YYYY-MM-DD").clone().locale("en");
      const dueDate = moment(this.localDate, "YYYY-MM-DD").clone().locale("en");

      if (dateToCheck.isSameOrBefore(dueDate, "day")) {
        return false;
      }

      const repeatOption = this.localRepeatOption;

      if (repeatOption.interval === "WEEKLY") {
        return this.isWeeklyEvent(dateToCheck);
      } else if (repeatOption.interval === "MONTHLY") {
        return this.isMonthlyEvent(dateToCheck);
      } else if (repeatOption.interval === "YEARLY") {
        return this.isYearlyEvent(dateToCheck, dueDate);
      }

      return false;
    },
    isWeeklyEvent(dateToCheck) {
      const repeatOption = this.localRepeatOption;
      const selectedDays = repeatOption.weeklyOptions.days;

      const weekdayMapping = {
        SUNDAY: 7,
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
      };

      const selectedWeekdays = selectedDays.map((day) => {
        return moment().day(weekdayMapping[day]).isoWeekday();
      });

      return selectedWeekdays.includes(dateToCheck.isoWeekday());
    },
    isMonthlyEvent(dateToCheck) {
      const repeatOption = this.localRepeatOption;
      const { selected } = repeatOption.monthlyOptions;

      if (selected === "onWeekday") {
        return this.isMonthlyOnWeekdayEvent(dateToCheck);
      } else if (selected === "onDate") {
        return this.isMonthlyOnDateEvent(dateToCheck);
      }
      return false;
    },
    isMonthlyOnWeekdayEvent(dateToCheck) {
      const { weekNr, weekday } = this.localRepeatOption.monthlyOptions.onWeekday;

      if (dateToCheck.format("dddd").toUpperCase() !== weekday) {
        return false;
      }

      const currentWeek = Math.ceil(dateToCheck.date() / 7);
      const lastWeekOfMonth = Math.ceil(dateToCheck.clone().endOf("month").date() / 7);

      let count = 0;
      const currentDate = dateToCheck.clone().startOf("month");
      while (currentDate.month() === dateToCheck.month()) {
        if (currentDate.format("dddd").toUpperCase() === weekday) {
          count++;
        }
        currentDate.add(1, "day");
      }

      if (weekNr === "LAST") {
        if (
          (count === 4 && currentWeek === lastWeekOfMonth - 1) ||
          (count === 5 && currentWeek === lastWeekOfMonth)
        ) {
          return true;
        }
      } else if (parseInt(currentWeek) === parseInt(weekNr)) {
        return true;
      }

      return false;
    },
    isMonthlyOnDateEvent(dateToCheck) {
      const { dayInMonth } = this.localRepeatOption.monthlyOptions.onDate;
      if (dayInMonth === "LAST") {
        return dateToCheck.date() === dateToCheck.clone().endOf("month").date();
      } else {
        const day = parseInt(dayInMonth);
        return dateToCheck.date() === day;
      }
    },
    isYearlyEvent(dateToCheck, dueDate) {
      if (
        dateToCheck.month() === dueDate.month() &&
        dateToCheck.date() === dueDate.date()
      ) {
        return true;
      }
      const isLeapYear = (year) =>
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

      if (!(dueDate.month() === 1 && dueDate.date() === 29)) {
        return false;
      }
      if (
        isLeapYear(dateToCheck.year()) &&
        dateToCheck.month() === 1 &&
        dateToCheck.date() === 29
      ) {
        return true;
      } else if (
        !isLeapYear(dateToCheck.year()) &&
        dateToCheck.month() === 1 &&
        dateToCheck.date() === 28
      ) {
        return true;
      }
      return false;
    },

    isOnSameDayOfYear(date, targetDate) {
      return date.month() === targetDate.month() && date.date() === targetDate.date();
    },
    onEditSelectedContentClick() {
      this.dialog.content.active = true;
    },
    isIntervalSelected(value) {
      return this.localRepeatOption?.interval === value;
    },
    async toggleRepeat() {
      if (!this.isRepeatToggled && !this.localRepeatOption) {
        this.isLoadingDefaultRepeatOptions = true;
        await getDefaultRepeatOptions({
          model: this.model,
        })
          .then((res) => {
            this.save(res);
          })
          .finally(() => {
            this.isLoadingDefaultRepeatOptions = false;
          });
      } else {
        this.localRepeatOption.repeat = !this.localRepeatOption.repeat;
        this.save({ ...this.repeatOption, repeat: this.localRepeatOption.repeat });
      }
    },
    //style helpers
    iconColor(isToggled) {
      if (isToggled) return "white";
      return "";
    },
    backgroundColor(isToggled) {
      if (isToggled) return "primary--background";
      return "";
    },
    dayShortStyle(index) {
      return { "padding-left": index === 0 ? "4.5px" : "7.5px" };
    },
  },
};
</script>

<template>
  <AppContextMenu
    ref="contextMenu"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(action, index) in singleActionConfigs">
      <v-divider
        v-if="action.divider && !isFirstVisible(index)"
        :key="`divider-${index}`"
        class="my-2"
      />
      <AppMenuActionBtn
        :key="action.key"
        v-bind="action.props"
        v-on="action.on"
        :selectedItems="items"
      />
    </template>
  </AppContextMenu>
</template>

<script>
export default {
  props: {
    actionConfigs: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    singleActionConfigs() {
      return this.$constant.getSingleActionConfigs(this.actionConfigs);
    },
  },
  methods: {
    open(e) {
      this.$refs.contextMenu.open(e);
    },
    close(e) {
      this.$refs.contextMenu.close(e);
    },
    isFirstVisible(index) {
      const firstVisibleIndex = this.singleActionConfigs.findIndex(
        (action) => !action.props?.hidden?.({ items: this.items }),
      );
      return index === firstVisibleIndex;
    },
  },
};
</script>

<style lang="scss" scoped></style>

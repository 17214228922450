<template>
  <div class="information_drawer">
    <v-toolbar
      class="border-b"
      dense
      flat
      fixed
      height="68"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ $t("fileManagement.preview.navMenu.e-signature.title") }}
      </v-toolbar-title>
      <v-spacer />
      <AppToggleMenuBtn>
        <AppMenuItem
          :text="$t('fileManagement.preview.navMenu.e-signature.sendReminder')"
          :icon="$icons.REGULAR.ACTION.NOTIFICATION"
          :disabled="!canRemind"
          @click="onRemind"
        />
        <AppMenuItem
          :text="$t('fileManagement.preview.navMenu.e-signature.revokeSignatureRequest')"
          :icon="$icons.REGULAR.COMMON.SIGNATURE_SLASH"
          :disabled="!canRevoke"
          color="error"
          @click="onDocumentRevoke"
        />
      </AppToggleMenuBtn>
    </v-toolbar>

    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    ></AppCenterLoader>

    <template v-if="!isLoading">
      <template v-if="status === $constant.E_SIGN_STATUS.NOT_STARTED">
        <NotStartedDrawer
          :storageId="fileId"
          @afterSubmit="openEmbeddedRequestDialog"
        />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.DRAFT">
        <DraftDrawer
          @openEmbeddedRequestDialog="openEmbeddedRequestDialog"
          :isSendingToSign="draftToInProgress.isLoading"
        />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.IN_PROGRESS">
        <InProgressDrawer
          :storageId="fileId"
          :isRevokingDocument="inProgressToRevoked.isLoading"
        />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.DECLINED">
        <DeclinedDrawer :storageId="fileId" />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.EXPIRED">
        <ExpiredDrawer />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.REVOKED">
        <RevokedDrawer />
      </template>

      <template v-else-if="status === $constant.E_SIGN_STATUS.COMPLETED">
        <CompletedDrawer :storageId="fileId" />
      </template>

      <template v-else>something went wrong...</template>

      <AppESignEmbeddedRequestDialog
        v-model="dialog.embeddedRequest.active"
        :src="dialog.embeddedRequest.src"
        @afterDocumentSentToSign="afterDocumentSentToSign"
      />
      <AppMessageDialog
        v-model="dialog.documentRevoke.active"
        :title="$t('fileManagement.preview.navMenu.e-signature.revokeDialog.title')"
        :messageLabel="
          $t('fileManagement.preview.navMenu.e-signature.revokeDialog.messageLabel')
        "
        required
        @submit="revokeDocument"
      />
      <AppESignRemindDialog
        v-model="dialog.remind.active"
        :storageId="fileId"
        @submit="remind"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NotStartedDrawer from "./status/NotStartedDrawer.vue";
import DraftDrawer from "./status/DraftDrawer.vue";
import InProgressDrawer from "./status/InProgressDrawer.vue";
import RevokedDrawer from "./status/RevokedDrawer.vue";
import DeclinedDrawer from "./status/DeclinedDrawer.vue";
import ExpiredDrawer from "./status/ExpiredDrawer.vue";
import CompletedDrawer from "./status/CompletedDrawer.vue";

export default {
  data() {
    return {
      dialog: {
        embeddedRequest: {
          active: false,
          src: "",
        },
        documentRevoke: {
          active: false,
        },
        remind: {
          active: false,
        },
      },
      draftToInProgress: {
        runs: 0,
        interval: null,
        isLoading: false,
      },
      inProgressToRevoked: {
        runs: 0,
        interval: null,
        isLoading: false,
      },
    };
  },
  props: {
    fileId: String,
  },
  computed: {
    ...mapState("storageESign", {
      storage: (state) => state.storage,
      isStorageLoading: (state) => state.isLoading,
    }),
    ...mapGetters("storageESign", {
      status: "status",
      canRevoke: "canRevoke",
      canRemind: "canRemind",
    }),
    isLoading() {
      return this.isStorageLoading || !this.storage;
    },
  },
  watch: {
    fileId: {
      handler(fileId) {
        if (fileId) {
          this.getStorage(fileId);
        }
      },
    },
    status: {
      handler(newVal, oldVal) {
        if (!oldVal) return;
        if (newVal !== oldVal) {
          this.$emit("preview:update", { fileId: this.fileId });
          this.clearDraftToInProgressInterval();
          this.clearInProgressToRevokedInterval();
        }
      },
    },
  },
  methods: {
    getStorage(id, load = true) {
      return this.$store.dispatch("storageESign/getStorage", { id, load });
    },
    openEmbeddedRequestDialog(e) {
      this.dialog.embeddedRequest.src = e;
      this.dialog.embeddedRequest.active = true;
      this.getStorage(this.fileId, false);
    },
    afterDocumentSentToSign() {
      this.draftToInProgress.isLoading = true;
      this.draftToInProgress.interval = setInterval(() => {
        this.draftToInProgress.runs++;
        if (this.draftToInProgress.runs >= 50) this.clearDraftToInProgressInterval();
        this.getStorage(this.fileId, false);
      }, 1000);
    },
    afterDocumentRevoked() {
      this.inProgressToRevoked.isLoading = true;
      this.inProgressToRevoked.interval = setInterval(() => {
        this.inProgressToRevoked.runs++;
        if (this.inProgressToRevoked.runs >= 50) this.clearInProgressToRevokedInterval();
        this.getStorage(this.fileId, false);
      }, 1000);
    },
    clearDraftToInProgressInterval() {
      clearInterval(this.draftToInProgress.interval);
      this.draftToInProgress.isLoading = false;
    },
    clearInProgressToRevokedInterval() {
      clearInterval(this.inProgressToRevoked.interval);
      this.inProgressToRevoked.isLoading = false;
    },
    onDocumentRevoke() {
      if (!this.canRevoke) return;
      this.dialog.documentRevoke.active = true;
    },
    async revokeDocument(body) {
      if (!this.canRevoke) return;
      await this.$store.dispatch("storageESign/revokeDocument", {
        storageId: this.fileId,
        body,
      });
      this.afterDocumentRevoked();
    },
    onRemind() {
      if (!this.canRemind) return;
      this.dialog.remind.active = true;
    },
    remind(body) {
      if (!this.canRemind) return;
      this.$store.dispatch("storageESign/sendReminder", {
        storageId: this.fileId,
        body,
      });
    },
  },
  mounted() {
    this.getStorage(this.fileId);
  },
  destroyed() {
    this.clearDraftToInProgressInterval();
    this.clearInProgressToRevokedInterval();
  },
  components: {
    NotStartedDrawer,
    DraftDrawer,
    InProgressDrawer,
    RevokedDrawer,
    DeclinedDrawer,
    ExpiredDrawer,
    CompletedDrawer,
  },
};
</script>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span
          v-if="!isLoading"
          class="headline"
        >
          {{ $t("actionRequest.remindDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <template v-if="!isLoading">
          <v-simple-table
            class="border-a overflow-hidden rounded mb-4"
            fixed-header
            height="200px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left input-background"
                    style="height: 30px"
                  >
                    <span class="text--disabled">
                      {{ $t("actionRequest.remindDialog.tableTitle") }} ({{
                        items.length
                      }})
                    </span>
                  </th>
                  <th
                    class="text-left input-background"
                    style="height: 30px"
                  ></th>
                </tr>
              </thead>
              <tbody class="border-radius-0">
                <tr
                  v-for="(item, i) in items"
                  :key="i"
                  class="cursor-pointer list_tr"
                  @click="selectItem(item)"
                  :class="isSelected(item) ? 'list_tr--selected' : ''"
                >
                  <td class="border-radius-0 d-flex align-center">
                    <v-icon
                      class="pr-3"
                      :color="canBeSelected(item) ? '' : 'disabled'"
                    >
                      {{
                        isSelected(item)
                          ? $icons.SOLID.COMMON.SQUARE_CHECK
                          : $icons.LIGHT.COMMON.SQUARE
                      }}
                    </v-icon>
                    <span :class="canBeSelected(item) ? '' : 'text--disabled'">
                      {{ item.name }} ({{ item.email }})
                    </span>
                  </td>
                  <td>
                    <AppColorChip
                      :text="getStatusText(item)"
                      :color="getStatusColor(item)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="label--small">
            {{ $t("actionRequest.remindDialog.messageLabel") }}
          </div>
          <AppDefaultTextarea
            ref="messageTextarea"
            v-model="message"
            label=""
            rows="2"
          />
        </template>
        <v-progress-linear
          v-else
          indeterminate
          rounded
          height="6"
        />
      </v-card-text>
      <v-card-actions v-if="!isLoading">
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="$t('actionRequest.remindDialog.confirmButton')"
          :loading="isSubmitting"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    getStatusText: {
      type: Function,
      required: true,
    },
    getStatusColor: {
      type: Function,
      required: true,
    },
    inProgressStatus: String,
  },
  data() {
    return {
      isSubmitting: false,
      selectedEmails: [],
      message: "",
    };
  },
  model: {
    prop: "dialog",
    event: "update:dialog",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  methods: {
    isSelected(item) {
      return this.selectedEmails.includes(item.email);
    },
    canBeSelected(item) {
      return item.status === this.inProgressStatus;
    },
    selectItem(item) {
      if (!this.canBeSelected(item)) return;
      if (this.isSelected(item)) {
        this.selectedEmails = this.selectedEmails.filter((email) => email !== item.email);
      } else {
        this.selectedEmails.push(item.email);
      }
    },
    onSubmit() {
      if (this.selectedEmails.length === 0) return;
      this.isSubmitting = true;
      this.$emit("submit", {
        emails: this.selectedEmails,
        message: this.message,
      });
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="title"
      class="text-subtitle-1 font-weight-bold mb-3"
    >
      {{ title }}
    </div>
    <div class="details-grid">
      <template v-for="field in fields">
        <div
          :key="`label-${field.key}`"
          class="text-no-wrap"
        >
          {{ t(field.key) }}
        </div>
        <div
          :key="`value-${field.key}`"
          class="value"
        >
          {{ getFieldValue(field, field.value) }}
          <AppCopyTextBtn
            v-if="field.showCopyBtn && getFieldValue(field, field.value) !== '-'"
            :text="getFieldValue(field, field.value)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AppDetailsSection",
  props: {
    title: String,
    fields: Array,
    item: Object,
    translationFn: {
      type: Function,
      default: (key) => key,
    },
  },
  computed: {
    formatters() {
      return {
        statusText: (status) => this.$t(`settings.corporateManagement.status.${status}`),
        date: (date) => {
          if (!date) return null;
          return moment(date).isValid() ? moment(date).format("DD.MM.YYYY") : null;
        },
        industries: (arr) =>
          arr?.map((industry) => this.$t(`industries.${industry}`)).join(", ") ?? null,
      };
    },
  },
  methods: {
    t(key) {
      return this.translationFn(key);
    },
    getFieldValue(field, path) {
      const value = path.split(".").reduce((obj, key) => obj?.[key], this.item);
      if (field.formatter) {
        if (typeof field.formatter === "string") {
          return this.formatters[field.formatter]?.(value) ?? "-";
        }
        return field.formatter(value) ?? "-";
      }
      return value ?? "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.details-grid {
  display: grid;
  grid-template-columns: minmax(170px, 30%) 1fr;
  gap: 8px;
  .value {
    font-weight: 500;
  }
}
</style>

<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(`common.dialogs.copyItems.${type}.title`) }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="copyItemsForm"
          v-model="formValid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div
            v-for="(item, index) in outItems"
            :key="item.id"
          >
            <div class="label--small required-asterisk">
              {{
                `${$t(`common.dialogs.copyItems.${type}.nameLabel`, {
                  index: index + 1,
                })} - ${item.originalName}`
              }}
            </div>
            <div class="d-flex">
              <AppDefaultTextField
                v-model="item.newName"
                :rules="$rules.REQUIRED"
                :counter="nameMaxInput"
              />
              <AppDefaultTooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    icon
                    v-if="showPreview"
                    @click="onPreview(item)"
                  >
                    <v-icon>
                      {{ $icons.LIGHT.COMMON.EYE }}
                    </v-icon>
                  </v-btn>
                </template>
                {{ $t(`common.dialogs.copyItems.${type}.previewTooltip`) }}
              </AppDefaultTooltip>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    inItems: Array,
    type: String,
    nameMaxInput: {
      type: Number,
      default: 255,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      formValid: true,
      outItems: [],
      previewTypes: ["STORAGE"],
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    showPreview() {
      return this.previewTypes.includes(this.type);
    },
  },
  watch: {
    localDialog: {
      handler(newVal) {
        if (!newVal) return;
        this.outItems = this.inItems.map((item) => ({
          ...item,
          originalName: item.name,
          newName: item.name + " - " + this.$t("common.copyNoun"),
        }));
      },
    },
  },
  methods: {
    onClose() {
      this.$refs.copyItemsForm.resetValidation();
      this.localDialog = false;
    },
    onSave() {
      if (this.$refs.copyItemsForm.validate()) {
        const data = this.outItems.map((item) => ({
          id: item.id,
          name: item.newName,
        }));
        this.outItems = [];
        this.$emit("submit", data);
        this.onClose();
      }
    },
    onPreview(item) {
      this.$emit("preview:item", item);
    },
  },
};
</script>

<template>
  <div v-if="bulkActionConfigs.length">
    <custom-slide-y-reverse-transition>
      <div
        v-if="hasSelectedItems"
        id="bulkActionBar"
        flat
        dense
        class="d-flex ui-background-black rounded-lg pa-2 text-no-wrap align-center"
        tabindex="0"
      >
        <NumSelectedBulk
          top
          :selectedCount="selectedItems.length"
          @click="$emit('clear:click')"
        />
        <div
          style="width: 20px"
          v-if="mainMenuVisibleActions.length || deleteAction"
        ></div>
        <transition-group
          name="expand-x"
          tag="div"
          class="d-flex"
        >
          <div
            v-for="actionConfig in mainMenuVisibleActions"
            :key="actionConfig.key"
          >
            <transition name="expand-x">
              <AppMenuActionBtn
                :key="actionConfig.key"
                v-bind="actionConfig.props"
                v-on="actionConfig.on"
                :selectedItems="selectedItems"
                :actionType="$constant.ACTION_TYPES.BULK"
                small
                dark
              />
            </transition>
          </div>
        </transition-group>
        <template v-if="deleteAction">
          <v-divider
            class="mx-1 my-1"
            dark
            vertical
          />
          <AppMenuActionBtn
            v-bind="deleteAction.props"
            v-on="deleteAction.on"
            :selectedItems="selectedItems"
            :actionType="$constant.ACTION_TYPES.BULK"
            small
            dark
          />
          <v-divider
            class="mx-1 my-1"
            dark
            vertical
            v-if="visibleNonDeleteActions.length && deleteAction"
          />
        </template>
        <MoreBulkActionMenu
          v-if="visibleNonDeleteActions.length"
          :bulkActionConfigs="visibleNonDeleteActions"
          :selectedItems="selectedItems"
          @bulk-action:click="emitBulkActionClick"
        />
      </div>
    </custom-slide-y-reverse-transition>
  </div>
</template>

<script>
import NumSelectedBulk from "./NumSelectedBulk.vue";
import MoreBulkActionMenu from "./MoreBulkActionMenu.vue";
import _ from "lodash";

export default {
  components: {
    NumSelectedBulk,
    MoreBulkActionMenu,
  },
  props: {
    bulkActionConfigs: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      breakpoints: {
        OMEGA_LARGE: 1920,
        EXTRA_LARGE: 1440,
        LARGE: 1200,
        MEDIUM: 992,
        SMALL: 768,
        EXTRA_SMALL: 576,
      },
    };
  },
  computed: {
    deleteAction() {
      return this.bulkActionConfigs.find(
        (action) => action.key === this.$constant.ACTION_KEYS.DELETE,
      );
    },
    nonDeleteActions() {
      return this.bulkActionConfigs.filter(
        (action) => action.key !== this.$constant.ACTION_KEYS.DELETE,
      );
    },
    visibleNonDeleteActions() {
      return this.nonDeleteActions.filter((action) => {
        const isHidden =
          typeof action.props.hidden === "function"
            ? action.props.hidden({ items: this.selectedItems })
            : action.props.hidden;

        return !isHidden;
      });
    },
    mainMenuVisibleActions() {
      return this.visibleNonDeleteActions.slice(0, this.mainMenuVisibleCount);
    },
    mainMenuVisibleCount() {
      const isWindowWidth = (breakpoint) =>
        this.windowWidth >= this.breakpoints[breakpoint];

      if (isWindowWidth("OMEGA_LARGE")) return 6;
      if (isWindowWidth("EXTRA_LARGE")) return 5;
      if (isWindowWidth("LARGE")) return 4;
      if (isWindowWidth("MEDIUM")) return 3;
      if (isWindowWidth("SMALL")) return 2;
      if (isWindowWidth("EXTRA_SMALL")) return 1;
      return 0;
    },
    mergedBulkActions() {
      return this.mergeWithDefaultActions(this.bulkActionConfigs);
    },
    hasSelectedItems() {
      return this.selectedItems.length > 0;
    },
  },
  methods: {
    emitBulkActionClick(event) {
      this.$emit("bulk-action:click", event);
    },
    mergeWithDefaultActions(customActions) {
      return customActions.map((action) => {
        const defaultAction =
          this.$constant.DEFAULT_BULK_ACTIONS.find(
            (defaultAction) => defaultAction.key === action.key,
          ) || {};
        return _.merge({}, defaultAction, action);
      });
    },
    handleResize() {
      const widthOfMainWrap = document.querySelector(".v-main__wrap").offsetWidth;
      this.windowWidth = widthOfMainWrap || window.innerWidth;
    },
    handleEscKeyPress() {
      this.$emit("clear:click");
    },
    // handleGlobalEscKeyPress(event) {
    //   if (event.key === "Escape") {
    //     this.handleEscKeyPress();
    //   }
    // },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    // document.addEventListener("keydown", this.handleGlobalEscKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // document.removeEventListener("keydown", this.handleGlobalEscKeyPress);
  },
};
</script>

<style>
.bulk-action-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 4px;
  width: max-content;
}

.expand-x-enter-active,
.expand-x-leave-active {
  transition: all 0.3s ease-in-out;
  max-width: 300px; /* Adjust this value based on your largest button width */
}
.expand-x-enter,
.expand-x-leave-to {
  opacity: 0;
  max-width: 0;
  padding: 0;
  margin: 0;
}
</style>

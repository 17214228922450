<template>
  <AppContextMenu
    ref="menu"
    height="440"
    width="300"
    :closeOnContentClick="false"
  >
    <AppDefaultAutocomplete
      @change="onSubmit"
      autofocus
      activateOnFocus
      resetOnSubmit
      :loading="isCategoriesLoading"
      :items="categories"
      :maxWidthMultiplier="1"
    />
  </AppContextMenu>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("formCategories", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    getCategories() {
      this.$store.dispatch("formCategories/getCategories");
    },
    onSubmit(categoryId) {
      this.$emit("submit", { categoryId });
      this.close();
    },
    close() {
      this.$refs.menu.close();
    },
    open(e) {
      this.$refs.menu.open(e);
      this.getCategories();
    },
  },
};
</script>

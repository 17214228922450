<template>
  <v-row
    align-content="center"
    justify="center"
    :style="`height: ${height};`"
    v-if="show"
  >
    <v-col
      class="subtitle-1 text-center"
      cols="12"
    >
      {{ title }}
    </v-col>
    <v-col cols="6">
      <v-progress-linear
        indeterminate
        rounded
        height="6"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "Loading",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  model: {
    prop: "show",
    event: "show:change",
  },
};
</script>

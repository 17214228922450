<template>
  <v-dialog
    v-model="localDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(translationKeys?.title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editItemForm"
          v-model="form.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t(translationKeys?.nameLabel) }}
            </div>
            <AppDefaultTextField
              ref="editItemFormName"
              v-model="form.name.value"
              :rules="$rules.NAME_RULES"
              :counter="nameMaxInput"
              autofocus
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    item: Object,
    nameMaxInput: {
      type: Number,
      default: 255,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    translationKeys() {
      const map = {
        FILE: {
          title: "fileManagement.fileExplorer.editFile.title",
          nameLabel: "fileManagement.fileExplorer.editFile.nameLabel",
        },
        FOLDER: {
          title: "fileManagement.fileExplorer.editFolder.title",
          nameLabel: "fileManagement.fileExplorer.editFolder.nameLabel",
        },
      };

      return map[this.item?.type];
    },
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.form.name.value = newVal?.name || "";
      },
    },
  },
  data() {
    return {
      form: {
        valid: false,
        name: {
          value: "",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.$refs.editItemForm.resetValidation();
      this.closeDialog();
    },
    onSave() {
      const valid = this.$refs.editItemForm.validate();
      if (valid && this.form) {
        const data = {
          value: {
            name: this.form.name.value,
          },
          item: this.item,
        };
        this.$emit("submit:form", data);
        this.closeDialog();
        this.$refs.editItemForm.reset();
      }
    },
  },
};
</script>

<template>
  <div class="list-sticky-footer">
    <v-divider />
    <v-list-item
      :dense="dense"
      slot="append-item"
      @click="onClick"
    >
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title class="text-body-2">
        <slot></slot>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { LIGHT } from "@/helpers/icons";

export default {
  props: {
    title: String,
    dense: Boolean,
    icon: {
      type: String,
      default: () => LIGHT.ACTION.CIRCLE_PLUS,
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto d-flex justify-center align-center"
  >
    <div class="text-center pa-2">
      <div class="text-h5 font-weight-bold mb-2">
        {{ $t("fileManagement.preview.navMenu.e-signature.REVOKED.title") }}
      </div>
      <div class="text-body-1 mb-7">
        {{ $t("fileManagement.preview.navMenu.e-signature.REVOKED.subTitle") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

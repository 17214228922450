import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let previewCancel;

const state = () => ({
  isLoading: false,
  item: null,
});

const getters = {};

const actions = {
  async getPreview({ commit }, id) {
    commit("loading", true);

    if (previewCancel) previewCancel();
    return axios
      .get(`${apiUrl}cms/document/${id}/preview`, {
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          previewCancel = c;
        }),
      })
      .then(async (res) => {
        if (res.data) {
          const url = URL.createObjectURL(res.data);
          commit("setItem", {
            url,
          });
        }
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
};

const mutations = {
  setItem(state, item) {
    state.item = item;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const cmsTemplate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

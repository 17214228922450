import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  isLoading: false,
  isActivated: false,
  credentials: {
    domain: null,
    secret: null,
  },
});

const getters = {};

const actions = {
  async getCredentials({ commit }) {
    commit("isLoading", true);
    commit("isActivated", false);
    axios
      .get(`${apiUrl}auth`)
      .then((res) => {
        const auth = res.data.auth;
        commit("isActivated", res.data.active);
        commit("setCredentials", {
          domain: auth ? auth.domainId : null,
          secret: auth ? auth.secret : null,
        });
        commit("isLoading", false);
      })
      .catch((error) => {
        commit("isLoading", false);
        commit("isActivated", false);
        throw new Error(error);
      });
  },
  async createCredentials({ commit }) {
    commit("isLoading", true);
    axios
      .post(`${apiUrl}auth`)
      .then((res) => {
        commit("isActivated", true);
        commit("isLoading", false);
        commit("setCredentials", {
          domain: res.data.domainId,
          secret: res.data.secret,
        });
      })
      .catch((error) => {
        commit("isLoading", false);
        commit("isActivated", false);
        throw new Error(error);
      });
  },
  async deleteCredentials({ commit }) {
    commit("isLoading", true);
    axios
      .put(`${apiUrl}auth`)
      .then((res) => {
        commit("isActivated", false);
        commit("isLoading", false);
        commit("setCredentials", {
          domain: null,
          secret: null,
        });
      })
      .catch((error) => {
        commit("isLoading", false);
        commit("isActivated", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setCredentials(state, credentials) {
    state.credentials = credentials;
  },
  isLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  isActivated(state, isActivated) {
    state.isActivated = isActivated;
  },
};

export const accountApi = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
